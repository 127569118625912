import * as types from '../actions/types';
import { enableInitializing } from 'initializable-reducer';
const INITIAL = {
  data: [],
  isLoading: false,
  isError: false
};

const datingDayOfWeekSettingReducer = enableInitializing(
  (state = INITIAL, action) => {
    switch (action.type) {
      case types.GET_DATING_DAY_OF_WEEK_SETTING_REQUESTED:
        return {
          ...state,
          isLoading: true
        };
      case types.GET_DATING_DAY_OF_WEEK_SETTING_SUCCEEDED:
        return {
          ...state,
          data: action.data || {},
          isLoading: false
        };
      case types.GET_DATING_DAY_OF_WEEK_SETTING_FAILED:
        return {
          ...state,
          data: action.data || {},
          isLoading: false
        };
      case types.MIGRATE_DATING_DAY_OF_WEEK_SETTING_REQUESTED:
        return {
          ...state,
          isLoading: true
        };
      case types.MIGRATE_DATING_DAY_OF_WEEK_SETTING_SUCCEEDED:
        return {
          ...state,
          data: action.data || {},
          isLoading: false
        };
      case types.MIGRATE_DATING_DAY_OF_WEEK_SETTING_FAILED:
        return {
          ...state,
          data: null,
          isLoading: false
        };
      default:
        return state;
    }
  }
);

export default datingDayOfWeekSettingReducer;
