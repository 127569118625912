import { all } from 'redux-saga/effects';
import {
  watchCreatePrefecture,
  watchDeletePrefecture,
  watchGetPrefecture,
  watchUpdatePrefecture,
  watchUpdateStatusPrefecture
} from './prefectureSaga';
import { watchLogin, watchLogout } from './authSaga';
import {
  watchCreateArea,
  watchDeleteArea,
  watchGetAllAreas,
  watchUpdateArea,
  watchUpdateStatusArea
} from './areaSaga';
import {
  watchCreateCafe,
  watchDeleteCafe,
  watchGetAllCafes,
  watchUpdateCafe,
  watchGetCafeMapInfo,
  watchChangeApproveCafe,
  watchImportCafeDatingCsv,
  watchChangeBlackActiveCafe
} from './cafeSaga';
import {
  watchGetParticipants,
  watchGetUserAvailable,
  watchImportRematching,
  watchMigrateParticipants,
  watchResetLastWeek,
  watchGetAwaitingMainMatchAndRematchParticipants,
  watchCancelAwaitingParticipant,
  watchDeleteMainMatchParticipant,
  watchImportMainMatchParticipantCsv,
  watchUserParticipantHistory
} from './participantSaga';
import {
  watchCreateNotification,
  watchDeleteNotification,
  watchGetNotification,
  watchGetUserNotification,
  watchReadNotification,
  watchSendNotification,
  watchUpdateNotification,
  watchSendBulkMessage,
  watchImportSkipNotificationsForUsersCsv,
  watchGetAutoCheckNotification,
  watchCreateAutoCheckNotification,
  watchDeleteAutoCheckNotification
} from './notificationSaga';
import {
  watchGetParticipantCount,
  watchUpdateParticipantCount,
  watchGetDatingDayDisplay,
  watchUpdateDatingDayDisplay,
  watchGetClassificationParticipant,
  watchGetGenderRatioControlData,
  watchGetExtendParticipation,
  watchUpdateExtendParticipation,
} from './participantCountSaga';
import {
  watchCreateSetting,
  watchDeleteSetting,
  watchGetAllSettings,
  watchUpdateSetting
} from './settingSaga';
import {
  watchListMatchingDate,
  watchListUserStatus,
  watchRecommendationList,
  watchRecommendationTypes,
  watchSetRecommendation,
  watchUpdateMatchingDate
} from './matchingDateSaga';
import {
  watchGetMatchedList,
  watchCreateMatchedList,
  watchGetMatchedDataDetails,
  watchUpdateMatchedList,
  watchDeleteMatchedList
} from './matchedUserPairListSaga';
import {
  watchFakeUser,
  watchIssueCoupon,
  watchListUsers,
  watchUpdateStatusUser,
  watchUpdateUserProfile,
  watchGetUpdateUserProfileData,
  watchUploadBulkCsv,
  watchUploadIssueCouponCsv,
  watchDeleteUser,
  watchBanUser,
  watchReApproveUser,
  watchReRegisterUser,
  watchGetTempcode,
  watchCopyUser,
  watchUploadUserRankCsv,
  watchUploadBetaVerCsv,
  watchChangeBetaVer,
  watchImportBulkPairCsv
} from './usersSaga';
import { watchGetTiming, watchUpdateTiming } from './timeSettingSaga';
import {
  watchGetMessages,
  watchGetRoomDetail,
  watchGetRooms,
  watchMigrateMessages,
  watchSearchMessages,
  watchSearchRoom
} from './chatHistorySaga';
import {
  watchGetDatingDayOfWeekSetting,
  watchMigrateDatingDayOfWeekSetting
} from './datingDayOfWeekSetting';
import {
  watchCreateSchool,
  watchGetAllSchools,
  watchUpdateSchool
} from './educationGroupSaga';
import {
  watchGetMatchingInfoSaga,
  watchDeleteMatchingInfoSaga,
  watchUpdateMatchingInfoSaga,
  watchCreateMatchingInfoSaga
} from './matchingInfoSaga';
import { watchGetUserDetail } from './userDetailSaga';
import { watchGetUserDatingHistory } from './userDatingHistorySaga';
import { watchGetUserCoupon, watchDiscardUserCoupon } from './userCouponSaga';
import { watchGetUserTrial } from './userTrialSaga';
import { watchFetchUserActivityLog } from './userActivityLogSaga';
import { watchGetSystemLogs } from './systemLogSaga';
import { watchGetSystemLogDates } from './systemLogDatesSaga';
import {
  watchGetTrainStations,
  watchCreateTrainStation,
  watchUpdateTrainStation,
  watchGetMapInfo
} from './trainStationSaga';
import { watchGetAllPlans, watchGetUserPlans } from './planSaga';
import { watchUseImage } from './userImageSagas';
import {
  watchGetAllAdminAccount,
  watchCreateAdminAccount,
  watchDeleteAdminAccount,
  watchEditAdminAccount,
  watchSetCurrentAdminAccount
} from './adminManagementSaga';
import {
  watchGetBatchQueueFailDetailData,
  watchGetBatchQueueProcess,
  watchGetBatchQueueSuccessDetailData
} from './batchQueueSaga';
import {
  watchGetFeedbackFlexQuestion,
  watchGetFeedbackFlexQuestionControlData,
  watchListFeedbackFlexQuestions,
  watchCreateFeedbackFlexQuestion,
  watchUpdateFeedbackFlexQuestion,
  watchRequestUserFeedbackDetails
} from './feedbackSaga';
import { watchDownloadSupcriptionCsv } from './downloadCsvSaga';
import { watchDownloadUserIdCsv } from './downloadCsvSaga';
import {
  watchGetCertificates,
  watchApproveCertificate,
  watchRejectCertificate
} from './certificationSaga';
import {
  watchGetDatingTimeslotPrefectureSettings,
  watchUpdateDatingTimeslotPrefectureSettings
} from './prefectureSettingSaga';
import {
  watchListCommonVariables,
  watchCreateCommonVariable,
  watchUpdateCommonVariable,
  watchDeleteCommonVariable,
  watchUpdateStatusCommonVariable
} from './commonVariablesSaga';
import { watchGetUserDatingReport } from './datingReportSaga';
import { watchGetUserSubscription } from './subscriptionSaga';
import {
  watchGetBannerTableData,
  watchUpdateBannerStatus,
  watchDeleteBanner,
  watchCreateBanner,
  watchUploadBannerImage,
  watchGetEditBannerData,
  watchUpdateBanner
} from './manageBannerSaga';

import {
  watchGetUserInvoice,
  watchRefundInvoice
} from './invoiceSaga';

import {
	watchGetAllCostPlans,
} from './costPlanSaga';

import { 
  watchCreateJobPreferenceMapping, 
  watchDeleteJobPreferenceMapping, 
  watchGetJobPreferenceMapping, 
  watchGetJobPreferenceMappingOptions, 
  watchUpdateJobPreferenceMapping 
} from './jobPreferenceMappingSaga';
import { 
  watchGetDatingDayDisplayBlack, 
  watchUpdateDatingDayDisplayBlack 
} from './participantCountBlackSaga';

export default function* rootSaga() {
  yield all([
    watchLogout(),
    watchLogin(),
    watchCreateArea(),
    watchUpdateArea(),
    watchUpdateStatusArea(),
    watchUpdateArea(),
    watchDeleteArea(),
    watchCreateCafe(),
    watchUpdateCafe(),
    watchDeleteCafe(),
    watchGetCafeMapInfo(),
    watchGetAllAreas(),
    watchGetAllCafes(),
    watchImportCafeDatingCsv(),
    watchChangeApproveCafe(),
    watchChangeBlackActiveCafe(),
    watchGetParticipants(),
    watchUserParticipantHistory(),
    watchGetUserAvailable(),
    watchGetUserTrial(),
    watchCreatePrefecture(),
    watchDeletePrefecture(),
    watchUpdatePrefecture(),
    watchUpdateStatusPrefecture(),
    watchGetPrefecture(),
    watchCreateNotification(),
    watchUpdateNotification(),
    watchDeleteNotification(),
    watchGetNotification(),
    watchGetUserNotification(),
    watchSendBulkMessage(),
    watchReadNotification(),
    watchSendNotification(),
    watchResetLastWeek(),
    watchMigrateParticipants(),
    watchImportRematching(),
    watchGetAwaitingMainMatchAndRematchParticipants(),
    watchCancelAwaitingParticipant(),
    watchDeleteMainMatchParticipant(),
    watchImportMainMatchParticipantCsv(),
    watchImportSkipNotificationsForUsersCsv(),
    watchGetAutoCheckNotification(),
    watchCreateAutoCheckNotification(),
    watchDeleteAutoCheckNotification(),
    //SETTINGS
    watchCreateSetting(),
    watchDeleteSetting(),
    watchUpdateSetting(),
    watchGetAllSettings(),
    //MATCHING
    watchUpdateMatchingDate(),
    watchSetRecommendation(),
    watchListMatchingDate(),
    watchListUserStatus(),
    watchRecommendationList(),
    watchRecommendationTypes(),
    //Users management
    watchUpdateStatusUser(),
    watchUpdateUserProfile(),
    watchGetUpdateUserProfileData(),
    watchListUsers(),
    watchIssueCoupon(),
    watchFakeUser(),
    watchUploadBulkCsv(),
    watchUploadIssueCouponCsv(),
    watchReApproveUser(),
    watchGetTempcode(),
    watchCopyUser(),
    watchReRegisterUser(),
    watchBanUser(),
    watchUploadUserRankCsv(),
    watchUploadBetaVerCsv(),
    watchChangeBetaVer(),

    //job preference mapping
    watchGetJobPreferenceMapping(),
    watchGetJobPreferenceMappingOptions(),
    watchCreateJobPreferenceMapping(),
    watchDeleteJobPreferenceMapping(),
    watchUpdateJobPreferenceMapping(),

    //match user pair list
    watchGetMatchedList(),
    watchCreateMatchedList(),
    watchGetMatchedDataDetails(),
    watchUpdateMatchedList(),
    watchDeleteMatchedList(),
    watchImportBulkPairCsv(),

    //Time settings
    watchGetTiming(),
    watchUpdateTiming(),
    //Chat
    watchGetRooms(),
    watchGetRoomDetail(),
    watchGetMessages(),
    watchSearchRoom(),
    watchSearchMessages(),
    watchMigrateMessages(),
    //    dating day of week settings
    watchGetDatingDayOfWeekSetting(),
    watchMigrateDatingDayOfWeekSetting(),
    // DatingReport
    watchGetUserDatingReport(),
    //    School
    watchUpdateSchool(),
    watchCreateSchool(),
    watchGetAllSchools(),
    // Participant count
    watchGetParticipantCount(),
    watchUpdateParticipantCount(),
    watchGetDatingDayDisplay(),
    watchUpdateDatingDayDisplay(),
    watchGetClassificationParticipant(),
    watchGetGenderRatioControlData(),

    //Participant Count Black
    watchGetDatingDayDisplayBlack(),
    watchUpdateDatingDayDisplayBlack(),

    // Extend Participation
    watchGetExtendParticipation(),
    watchUpdateExtendParticipation(),
    // Matching info
    watchGetMatchingInfoSaga(),
    watchDeleteMatchingInfoSaga(),
    watchUpdateMatchingInfoSaga(),
    watchCreateMatchingInfoSaga(),
    watchGetUserDetail(),
    watchGetUserCoupon(),
    watchDiscardUserCoupon(),
    watchGetUserDatingHistory(),
    watchFetchUserActivityLog(),
    watchGetTrainStations(),
    watchCreateTrainStation(),
    watchUpdateTrainStation(),
    watchGetMapInfo(),

    // System log
    watchGetSystemLogs(),
    watchGetSystemLogDates(),

    //image
    watchUseImage(),

    // plans
    watchGetAllPlans(),
    watchGetUserPlans(),
    watchDeleteUser(),

    //Admin management
    watchGetAllAdminAccount(),
    watchCreateAdminAccount(),
    watchDeleteAdminAccount(),
    watchEditAdminAccount(),
    watchSetCurrentAdminAccount(),

    //Batch queue
    watchGetBatchQueueProcess(),
    watchGetBatchQueueSuccessDetailData(),
    watchGetBatchQueueFailDetailData(),

    //feedback
    watchGetFeedbackFlexQuestionControlData(),
    watchListFeedbackFlexQuestions(),
    watchGetFeedbackFlexQuestion(),
    watchCreateFeedbackFlexQuestion(),
    watchUpdateFeedbackFlexQuestion(),
    watchRequestUserFeedbackDetails(),

    //download csv
    watchDownloadSupcriptionCsv(),
    watchDownloadUserIdCsv(),
    //certification
    watchGetCertificates(),
    watchApproveCertificate(),
    watchRejectCertificate(),

    watchGetDatingTimeslotPrefectureSettings(),
    watchUpdateDatingTimeslotPrefectureSettings(),

    //commonVariables
    watchListCommonVariables(),
    watchCreateCommonVariable(),
    watchUpdateCommonVariable(),
    watchDeleteCommonVariable(),
    watchUpdateStatusCommonVariable(),

    // sibscription
    watchGetUserSubscription(),

    // invoice
    watchGetUserInvoice(),
    watchRefundInvoice(),

    //manage banner
    watchGetBannerTableData(),
    watchUpdateBannerStatus(),
    watchDeleteBanner(),
    watchCreateBanner(),
    watchUploadBannerImage(),
    watchGetEditBannerData(),
    watchUpdateBanner(),

		//cost-plan
		watchGetAllCostPlans()
  ]);
}
