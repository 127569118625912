import * as types from '../actions/types';
import { enableInitializing } from 'initializable-reducer';
const INITIAL = {
  data: [],
  isLoading: false,
  isError: false
};

const areaReducer = enableInitializing((state = INITIAL, action) => {
  switch (action.type) {
    case types.GET_AREA_REQUESTED:
      return {
        ...state,
        isLoading: true
      };
    case types.GET_AREA_SUCCEEDED:
      return {
        ...state,
        data: action.data || {},
        isLoading: false
      };
    case types.GET_AREA_FAILED:
      return {
        ...state,
        data: action.data || {},
        isLoading: false
      };
    case types.CREATE_AREA_REQUESTED:
      return {
        ...state,
        isLoading: true
      };
    case types.CREATE_AREA_SUCCEEDED:
      return {
        ...state,
        data: action.data || {},
        isLoading: false
      };
    case types.CREATE_AREA_FAILED:
      return {
        ...state,
        data: null,
        isLoading: false
      };
    case types.UPDATE_AREA_REQUESTED:
      return {
        ...state,
        isLoading: true
      };
    case types.UPDATE_AREA_SUCCEEDED:
      return {
        ...state,
        data: action.data || {},
        isLoading: false
      };
    case types.UPDATE_AREA_FAILED:
      return {
        ...state,
        data: null,
        isLoading: false
      };

    ////////////////////////////
    case types.UPDATE_STATUS_AREA_REQUESTED:
      return {
        ...state,
        isLoading: true
      };
    case types.UPDATE_STATUS_AREA_SUCCEEDED:
      return {
        ...state,
        data: action.data || {},
        isLoading: false
      };
    case types.UPDATE_STATUS_AREA_FAILED:
      return {
        ...state,
        data: null,
        isLoading: false
      };
    case types.DELETE_AREA_REQUESTED:
      return {
        ...state,
        isLoading: true
      };
    case types.DELETE_AREA_SUCCEEDED:
      return {
        ...state,
        data: action.data || {},
        isLoading: false
      };
    case types.DELETE_AREA_FAILED:
      return {
        ...state,
        data: null,
        isLoading: false
      };
    default:
      return state;
  }
});

export default areaReducer;
