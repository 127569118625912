import { call, put, takeLatest } from 'redux-saga/effects';
import * as types from '../actions/types';
import { requestAPI } from '../services/request';
import { API } from '../services/api';
import { ResponseStatus } from '../../constants/constant';
import { toast } from 'react-toastify';

function* getMatchingInfo(action) {
  let response = null;
  try {
    response = yield call(requestAPI, API.API_MATCH_INFO);

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.GET_MATCHING_INFO_SUCCEEDED,
        data: response.data.data
      });
    } else {
      yield put({
        type: types.GET_MATCHING_INFO_FAILED,
        error: response
      });
    }

    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    toast(error.message);
    yield put({ type: types.GET_MATCHING_INFO_FAILED, error });
  }
}

function* deleteMatchingInfo(action) {
  let response = null;
  try {
    response = yield call(
      requestAPI,
      `${API.API_MATCH_INFO}/group/${action.params.group_id}`,
      API.DELETE,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.DELETE_MATCHING_INFO_SUCCEEDED,
        data: response.data
      });
      toast.success('Delete matching information successful');
    } else {
      yield put({
        type: types.DELETE_MATCHING_INFO_FAILED,
        error: response
      });
    }

    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    alert(error.message);
    yield put({ type: types.DELETE_MATCHING_INFO_FAILED, error });
  }
}

function* createMatchingInfo(action) {
  let response = null;
  try {
    response = yield call(
      requestAPI,
      API.API_MATCH_INFO,
      API.POST,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.CREATE_MATCHING_INFO_SUCCEEDED,
        data: response.data.data
      });
      toast.success('Create matching information successful');
    } else {
      yield put({
        type: types.CREATE_MATCHING_INFO_FAILED,
        error: response
      });
    }

    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.CREATE_MATCHING_INFO_FAILED, error });
  }
}

function* updateMatchingInfo(action) {
  let response = null;
  try {
    response = yield call(
      requestAPI,
      `${API.API_MATCH_INFO}/${action.params.id}`,
      API.POST,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.UPDATE_MATCHING_INFO_SUCCEEDED,
        data: response.data.data
      });
      toast.success('Update matching information successful');
    } else {
      yield put({
        type: types.UPDATE_MATCHING_INFO_FAILED,
        error: response
      });
    }

    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.UPDATE_MATCHING_INFO_FAILED, error });
  }
}

export function* watchGetMatchingInfoSaga() {
  yield takeLatest(types.GET_MATCHING_INFO_REQUESTED, getMatchingInfo);
}

export function* watchUpdateMatchingInfoSaga() {
  yield takeLatest(types.UPDATE_MATCHING_INFO_REQUESTED, updateMatchingInfo);
}

export function* watchCreateMatchingInfoSaga() {
  yield takeLatest(types.CREATE_MATCHING_INFO_REQUESTED, createMatchingInfo);
}

export function* watchDeleteMatchingInfoSaga() {
  yield takeLatest(types.DELETE_MATCHING_INFO_REQUESTED, deleteMatchingInfo);
}
