import * as types from '../actions/types';

const initialState = {
  isLoading: false,
  data: [],
  total: 0,
  isError: false
};

const matchingDateReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.RECOMMENDATION_LIST_REQUEST:
      return {
        ...state,
        isLoading:true,
      };
    case types.RECOMMENDATION_LIST_SUCCEEDED:
      return {
        ...state,
        isLoading:false
      };
    case types.RECOMMENDATION_LIST_FAILED:
      return {
        ...state,
        isLoading:false
      };
    case types.RECOMMENDATION_TYPES_REQUEST:
      return {
        ...state,
        isLoading:true
      };
    case types.RECOMMENDATION_TYPES_SUCCEEDED:
      return {
        ...state,
        isLoading:false
      };
    case types.RECOMMENDATION_TYPES_FAILED:
      return {
        ...state,
        isLoading:false
      };
    case types.SET_RECOMMENDATION_REQUEST:
      return {
        ...state,
        isLoading:true
      };
    case types.SET_RECOMMENDATION_SUCCEEDED:
      return {
        ...state,
        isLoading:false
      };
    case types.SET_RECOMMENDATION_FAILED:
      return {
        ...state,
        isLoading:false
      };
    case types.UPDATE_MATCHING_DATE_REQUEST:
      return {
        ...state,
        isLoading:true
      };
    case types.UPDATE_MATCHING_DATE_SUCCEEDED:
      return {
        ...state,
        isLoading:false
      };
    case types.UPDATE_MATCHING_DATE_FAILED:
      return {
        ...state,
        isLoading:false
      };
    case types.LIST_MATCHING_DATE_REQUEST:
      return {
        ...state,
        isLoading:true
      };
    case types.LIST_MATCHING_DATE_SUCCEEDED:
      return {
        ...state,
        isLoading:false
      };
    case types.LIST_MATCHING_DATE_FAILED:
      return {
        ...state,
        isLoading:false
      };
    case types.LIST_USER_STATUS_REQUEST:
      return {
        ...state,
        isLoading:true
      };
    case types.LIST_USER_STATUS_SUCCEEDED:
      return {
        ...state,
        isLoading:false
      };
    case types.LIST_USER_STATUS_FAILED:
      return {
        ...state,
        isLoading:false
      };
    default:
      return state;
  }
};

export default matchingDateReducer;
