import * as types from '../actions/types';

const initialState = {
  isLoading: false,
  isError: false,
  data: null,
  isRefresh: false
};

const participantCountReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_PARTICIPANT_COUNT_REQUESTED:
      return {
        ...state,
        isLoading: true,
        error: null
      };
    case types.GET_PARTICIPANT_COUNT_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        data: action.data,
        error: null
      };
    case types.GET_PARTICIPANT_COUNT_FAILED:
      return {
        ...state,
        isError: true,
        isLoading: false,
        data: null,
        error: action.error
      };

    case types.GET_CLASSIFICATION_PARTICIPANT_REQUESTED:
      return {
        ...state,
        isLoading: true,
        error: null
      };
    case types.GET_CLASSIFICATION_PARTICIPANT_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        data: action.data,
        error: null
      };
    case types.GET_CLASSIFICATION_PARTICIPANT_FAILED:
      return {
        ...state,
        isError: true,
        isLoading: false,
        data: null,
        error: action.error
      };

    case types.GET_DATING_DAY_DISPLAY_REQUESTED:
      return {
        ...state,
        isLoading: true,
        error: null
      };
    case types.GET_DATING_DAY_DISPLAY_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        data: action.data,
        error: null
      };
    case types.GET_DATING_DAY_DISPLAY_FAILED:
      return {
        ...state,
        isError: true,
        isLoading: false,
        data: null,
        error: action.error
      };

    case types.UPDATE_DATING_DAY_DISPLAY_REQUESTED:
      return {
        ...state,
        isLoading: true,
        error: null
      };
    case types.UPDATE_DATING_DAY_DISPLAY_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        data: action.data,
        error: null
      };
    case types.UPDATE_DATING_DAY_DISPLAY_FAILED:
      return {
        ...state,
        isError: true,
        isLoading: false,
        data: null,
        error: action.error
      };

    case types.UPDATE_PARTICIPANT_COUNT_REQUESTED:
      return {
        ...state,
        isLoading: true,
        error: null
      };
    case types.UPDATE_PARTICIPANT_COUNT_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        data: action.data,
        error: null
      };
    case types.UPDATE_PARTICIPANT_COUNT_FAILED:
      return {
        ...state,
        isError: true,
        isLoading: false,
        data: null,
        error: action.error
      };

      case types.GET_GENDER_RATIO_CONTROL_DATA_REQUESTED:
      return {
        ...state,
        isLoading: true,
        error: null
      };
    case types.GET_GENDER_RATIO_CONTROL_DATA_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        data: action.data,
        error: null
      };
    case types.GET_GENDER_RATIO_CONTROL_DATA_FAILED:
      return {
        ...state,
        isError: true,
        isLoading: false,
        data: null,
        error: action.error
      };

    default:
      return state;
  }
};

export default participantCountReducer;
