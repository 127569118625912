import { call, put, takeLatest } from 'redux-saga/effects';
import * as types from '../actions/types';
import { requestAPI } from '../services/request';
import { API } from '../services/api';
import { ResponseStatus } from '../../constants/constant';
import { toast } from 'react-toastify';

function* getDatingDayOfWeekSetting(action) {
  let response = null;
  let url = action.params.userGender
    ? `?user_gender=${action.params.userGender}`
    : '';
  try {
    response = yield call(
      requestAPI,
      `${API.API_DATING_DAY_OF_WEEK_SETTING}${url}`,
      API.GET,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.GET_DATING_DAY_OF_WEEK_SETTING_SUCCEEDED,
        data: {
          ...response.data
        }
      });
      // toast.success('get dating day of week setting success');
    } else {
      yield put({
        type: types.GET_DATING_DAY_OF_WEEK_SETTING_FAILED,
        error: response
      });
    }

    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.GET_DATING_DAY_OF_WEEK_SETTING_FAILED, error });
  }
}

function* migrateDatingDayOfWeekSetting(action) {
  let response = null;
  try {
    response = yield call(
      requestAPI,
      `${API.API_DATING_DAY_OF_WEEK_SETTING}/migrate-data`,
      API.POST,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.MIGRATE_DATING_DAY_OF_WEEK_SETTING_SUCCEEDED,
        data: {
          ...response.data
        }
      });
      toast.success(response.data.message);
    } else {
      yield put({
        type: types.MIGRATE_DATING_DAY_OF_WEEK_SETTING_FAILED,
        error: response
      });
    }

    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    alert(error.message);
    yield put({ type: types.MIGRATE_DATING_DAY_OF_WEEK_SETTING_FAILED, error });
  }
}

export function* watchGetDatingDayOfWeekSetting() {
  yield takeLatest(
    types.GET_DATING_DAY_OF_WEEK_SETTING_REQUESTED,
    getDatingDayOfWeekSetting
  );
}

export function* watchMigrateDatingDayOfWeekSetting() {
  yield takeLatest(
    types.MIGRATE_DATING_DAY_OF_WEEK_SETTING_REQUESTED,
    migrateDatingDayOfWeekSetting
  );
}
