import { call, put, takeLatest } from 'redux-saga/effects';
import * as types from '../actions/types';
import { requestAPI } from '../services/request';
import { API } from '../services/api';
import { ResponseStatus } from '../../constants/constant';
import { toast } from 'react-toastify';

function* listCommonVariables(action) {
  let response = null;
  try {
    let url = '/list';
    response = yield call(requestAPI, API.API_COMMON_VARIABLE + url, API.GET, {
      query: action.params
    });

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.LIST_COMMON_VARIABLES_SUCCEEDED,
        data: {
          ...response.data
        }
      });
    } else {
      yield put({
        type: types.LIST_COMMON_VARIABLES_FAILED,
        error: response.response.data
      });
      toast.error(response.response.data.message);
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.LIST_COMMON_VARIABLES_FAILED, error });
  }
}

function* createCommonVariable(action) {
  let response = null;
  try {
    response = yield call(
      requestAPI,
      API.API_COMMON_VARIABLE + `/create`,
      API.POST,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.CREATE_COMMON_VARIABLE_SUCCEEDED,
        data: {
          ...response.data
        }
      });
      toast.success('Successful');
    } else {
      yield put({
        type: types.CREATE_COMMON_VARIABLE_FAILED,
        error: response.response.data
      });
      toast.error(response.response.data.message);
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.CREATE_COMMON_VARIABLE_FAILED, error });
  }
}

function* updateCommonVariable(action) {
  let response = null;
  try {
    response = yield call(
      requestAPI,
      `${API.API_COMMON_VARIABLE}/update/${action.params.id}`,
      API.POST,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.UPDATE_COMMON_VARIABLE_SUCCEEDED,
        data: {
          ...response.data
        }
      });
      toast.success('Successful');
    } else {
      yield put({
        type: types.UPDATE_COMMON_VARIABLE_FAILED,
        error: response.response.data
      });
      toast.error(response.response.data.message);
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.UPDATE_COMMON_VARIABLE_FAILED, error });
  }
}

function* deleteCommonVariable(action) {
  let response = null;
  try {
    response = yield call(
      requestAPI,
      API.API_COMMON_VARIABLE + `/${action.params.id}`,
      API.DELETE,
      action.params
    );
    if (action.onComplete) {
      action.onComplete(response);
    }
    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.DELETE_COMMON_VARIABLE_SUCCEEDED,
        data: {
          ...response.data
        }
      });
    } else {
      yield put({
        type: types.DELETE_COMMON_VARIABLE_FAILED,
        error: response.data.data
      });
      toast.error(response.response.data.message);
    }
  } catch (error) {
    yield put({ type: types.DELETE_COMMON_VARIABLE_FAILED, error });
  }
}

function* updateCommonVariableStatus(action) {
  let response = null;
  try {
    response = yield call(
      requestAPI,
      API.API_COMMON_VARIABLE + `/update-status/${action.params.id}`,
      API.POST,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.UPDATE_STATUS_COMMON_VARIABLE_SUCCEEDED,
        data: {
          ...response.data
        }
      });
      toast.success('Successful');
    } else {
      yield put({
        type: types.UPDATE_STATUS_COMMON_VARIABLE_FAILED,
        error: response.response.data
      });
      toast.error(response.response.data.message);
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.UPDATE_STATUS_COMMON_VARIABLE_FAILED, error });
  }
}

export function* watchListCommonVariables() {
  yield takeLatest(types.LIST_COMMON_VARIABLES_REQUEST, listCommonVariables);
}

export function* watchCreateCommonVariable() {
  yield takeLatest(types.CREATE_COMMON_VARIABLE_REQUEST, createCommonVariable);
}

export function* watchUpdateCommonVariable() {
  yield takeLatest(types.UPDATE_COMMON_VARIABLE_REQUEST, updateCommonVariable);
}

export function* watchDeleteCommonVariable() {
  yield takeLatest(types.DELETE_COMMON_VARIABLE_REQUEST, deleteCommonVariable);
}

export function* watchUpdateStatusCommonVariable() {
  yield takeLatest(types.UPDATE_STATUS_COMMON_VARIABLE_REQUEST, updateCommonVariableStatus);
}
