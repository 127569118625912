import * as types from '../actions/types';

const initialState = {
  isLoading: false,
  isError: false,
  data: null,
  isRefresh: false
};

const notificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_NOTIFICATION_REQUESTED:
      return {
        ...state,
        isLoading: true,
        error: null
      };
    case types.GET_NOTIFICATION_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        data: action.data,
        error: null
      };
    case types.GET_NOTIFICATION_FAILED:
      return {
        ...state,
        isError: true,
        isLoading: false,
        data: null,
        error: action.error
      };
    case types.GET_USER_NOTIFICATION_REQUESTED:
      return {
        ...state,
        isLoading: true,
        error: null
      };
    case types.GET_USER_NOTIFICATION_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        userNotifications: action.data.data || {},
        error: null
      };
    case types.GET_USER_NOTIFICATION_FAILED:
      return {
        ...state,
        isError: true,
        isLoading: false,
        data: null,
        error: action.error
      };
    case types.CREATE_NOTIFICATION_REQUESTED:
      return {
        ...state,
        isLoading: true,
        error: null
      };
    case types.CREATE_NOTIFICATION_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        data: action.data,
        error: null
      };
    case types.CREATE_NOTIFICATION_FAILED:
      return {
        ...state,
        isError: true,
        isLoading: false,
        data: null,
        error: action.error
      };
    case types.UPDATE_NOTIFICATION_REQUESTED:
      return {
        ...state,
        isLoading: true,
        error: null
      };
    case types.UPDATE_NOTIFICATION_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        data: action.data,
        error: null
      };
    case types.UPDATE_NOTIFICATION_FAILED:
      return {
        ...state,
        isError: true,
        isLoading: false,
        data: null,
        error: action.error
      };
    case types.DELETE_NOTIFICATION_REQUESTED:
      return {
        ...state,
        isLoading: true,
        error: null
      };
    case types.DELETE_NOTIFICATION_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        data: action.data,
        error: null
      };
    case types.DELETE_NOTIFICATION_FAILED:
      return {
        ...state,
        isError: true,
        isLoading: false,
        data: null,
        error: action.error
      };
    case types.SEND_NOTIFICATION_REQUESTED:
      return {
        ...state,
        isLoading: true,
        error: null
      };
    case types.SEND_NOTIFICATION_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        data: action.data,
        error: null
      };
    case types.SEND_NOTIFICATION_FAILED:
      return {
        ...state,
        isError: true,
        isLoading: false,
        data: null,
        error: action.error
      };
    case types.READ_NOTIFICATION_REQUESTED:
      return {
        ...state,
        isLoading: true,
        error: null
      };
    case types.READ_NOTIFICATION_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        data: action.data,
        error: null
      };
    case types.READ_NOTIFICATION_FAILED:
      return {
        ...state,
        isError: true,
        isLoading: false,
        data: null,
        error: action.error
      };
    case types.IMPORT_SKIP_NOTIFICATIONS_FOR_USERS_REQUESTED:
      return {
        ...state,
        isLoading: true,
        isError: false,
        error: null
      };
    case types.IMPORT_SKIP_NOTIFICATIONS_FOR_USERS_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        data: action.data
      };
    case types.IMPORT_SKIP_NOTIFICATIONS_FOR_USERS_FAILED:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.data
      };
    case types.IMPORT_SKIP_SMS_NOTIFICATIONS_FOR_USERS_REQUESTED:
      return {
        ...state,
        isLoading: true,
        isError: false,
        error: null
      };
    case types.IMPORT_SKIP_SMS_NOTIFICATIONS_FOR_USERS_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        data: action.data
      };
    case types.IMPORT_SKIP_SMS_NOTIFICATIONS_FOR_USERS_FAILED:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.data
      };
    case types.GET_AUTO_CHECK_NOTIFICATION_REQUESTED:
      return {
        ...state,
        isLoading: true,
        isError: false,
        error: null
      };
    case types.GET_AUTO_CHECK_NOTIFICATION_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        data: action.data
      };
    case types.GET_AUTO_CHECK_NOTIFICATION_FAILED:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.data
      };
    case types.CREATE_AUTO_CHECK_NOTIFICATION_REQUESTED:
      return {
        ...state,
        isLoading: true,
        isError: false,
        error: null
      };
    case types.CREATE_AUTO_CHECK_NOTIFICATION_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        data: action.data
      };
    case types.CREATE_AUTO_CHECK_NOTIFICATION_FAILED:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.data
      };
    case types.DELETE_AUTO_CHECK_NOTIFICATION_REQUESTED:
      return {
        ...state,
        isLoading: true,
        isError: false,
        error: null
      };
    case types.DELETE_AUTO_CHECK_NOTIFICATION_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        data: action.data
      };
    case types.DELETE_AUTO_CHECK_NOTIFICATION_FAILED:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.data
      };
    default:
      return state;
  }
};

export default notificationReducer;
