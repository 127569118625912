window.__env__ = window.__env__ || {};

export const API = {
  POST: 'post',
  PUT: 'put',
  GET: 'get',
  DELETE: 'delete',
  BASE_URL: window.__env__.BASE_URL,
  API_LOGIN: 'oauth/token',
  USE_IMAGE: 'admin/store-image',

  // user
  API_USER: 'admin/user',
  API_BAN_USER: 'admin/user/ban',
  USER_LIST: 'admin/participate-user-list',
  USER_TRIAL: 'admin/user-trial',
  USER_PROFILE: 'admin/user/view-profile',
  USER_ACTIVITY_LOG: 'admin/activity-logs',
  API_PREFECTURE: 'admin/prefectures',
  API_LOGOUT: 'admin/logout',
  API_AREAS: 'admin/areas',
  API_CAFES: 'admin/dating-places',
  API_CAFES_GET_CAFE_MAP_INFO: 'admin/dating-places/get-cafe-map-info',
  API_PING: 'admin/ping',
  // participant
  API_PARTICIPANT: 'admin/participant',
  API_PARTICIPANT_USER_DETAIL: 'admin/participant/user/detail',

  // notification
  API_NOTIFICATION: 'admin/notification',
  API_SETTINGS: 'admin/settings',
  API_TIME_SETTINGS: 'admin/time-settings',
  // dating
  API_DATING: 'admin/dating',
  API_DATING_USER_DETAIL: 'admin/dating/user/detail',
  API_DATING_DAY_OF_WEEK_SETTING: 'admin/dating-days-of-week-setting',
  API_DATING_DAY: 'admin/dating-days',
  // dating report
  API_DATING_REPORT: 'admin/dating-report',
  API_DATING_REPORT_USER_DETAIL: 'admin/dating-report/user/detail',
  // coupon
  API_COUPON: 'admin/coupon',
  USER_COUPON: 'admin/user-coupon',
  // matching
  API_MATCHING_DATE: 'admin/matching-date',
  API_MATCH_USER_PAIR_LIST: 'admin/match-pair-list',
  API_MATCH_USER_PAIR_LIST_CREATE: 'admin/match-pair-list/create',
  API_MATCH_USER_PAIR_LIST_UPDATE: 'admin/match-pair-list/update',
  API_MATCH_USER_PAIR_LIST_DELETE: 'admin/match-pair-list/cancel',

  //job preference mapping
  API_GET_JOB_PREFERENCE_MAPPING: '/admin/job-mapping/list',
  API_GET_JOB_PREFERENCE_MAPPING_OPTIONS:'/admin/job-mapping/get-options',
  API_CREATE_JOB_PREFERENCE_MAPPING: '/admin/job-mapping/create',
  API_UPDATE_JOB_PREFERENCE_MAPPING: '/admin/job-mapping/update',
  API_DELETE_JOB_PREFERENCE_MAPPING: '/admin/job-mapping/delete',
  
  //Match Info settings
  API_MATCH_INFO: 'admin/match-info',
  API_TRAIN_STATION: 'admin/train-stations',
  API_TRAIN_STATION_CREATE: 'admin/train-stations/create',
  API_TRAIN_STATION_UPDATE: 'admin/train-stations/update',
  API_TRAIN_STATION_GET_MAP_INFO:
    'admin/train-stations/get-train-station-map-info',
  API_SCHOOL: 'admin/school',
  API_CHAT: 'admin/chat',
  //participant count
  API_PARTICIPANT_COUNT: 'admin/gender-ratio',
  API_CLASSIFICATION_PARTICIPANT: 'admin/participant-classification',

  API_DATING_DAY_DISPLAY: 'admin/participant/dating-day-display',
  API_DATING_DAY_DISPLAY_BLACK: 'admin/participant/dating-day-display-black-user',
  API_LOGS: 'admin/log',
  API_LOG_DATES: 'admin/log-dates',
  API_PLAN: 'admin/plans',
  API_COST_PLAN: 'admin/plans/cost-plans',
  API_USER_PLAN: 'admin/plans/user/detail',
  API_ADMIN_MANAGEMENT: 'admin/admin-management',
  API_CURRENT_ADMIN: 'admin/admin-management/account',
  API_RE_APPROVE_USER: 'user/reapprove',
  API_RE_REGISTER_USER: 'admin/user/re-register',

  API_BATCH: 'admin/batch',
  //feedback
  API_FEEDBACK: 'admin/feedback',
  API_FEEDBACK_FLEX_QUESTION: 'admin/feedback/flex-question',
  API_REPORT_SUBSCRIPTION: '/admin/report/subscriptions',
  API_FEEDBACK_USER_DETAIL: 'admin/feedback/user/detail',

  // subscription
  USER_SUBSCRIPTION: 'admin/report/user-subscriptions',

  // invoice
  API_INVOICE: 'admin/invoice',
  USER_INVOICE: 'admin/report/user-invoices',

  //certification
  API_CERTIFICATION: '/admin/certificate',
  API_APPROVE_CERTIFICATION: '/admin/certificate/approve/',
  API_REJECT_CERTIFICATION: '/admin/certificate/reject/',

  //prefecture setting
  API_PREFECTURE_SETTING: 'admin/dating-timeslot-prefecture-setting',
  //common variables
  API_COMMON_VARIABLE: 'admin/common-variable',

  //manage banner
  API_MANAGE_BANNER: 'admin/banners',
  API_BANNER_USER_ID: 'admin/banners/banner-user-ids',
  //user rank
  API_USER_RANK: 'admin/user-rank',

  //user version
  API_USER_VERSION: 'admin/user-version',
};
