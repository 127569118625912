import * as types from '../actions/types';

const initialState = {
  isLoading: false
};

const extendTrialByTrialSuggestionReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.EXTEND_TRIAL_BY_SUGGESTION_REQUESTED:
      return {
        ...state,
        isLoading: true
      };
    case types.EXTEND_TRIAL_BY_SUGGESTION_SUCCEEDED:
      return {
        ...state,
        isLoading: false
      };
    case types.EXTEND_TRIAL_BY_SUGGESTION_FAILED:
      return {
        ...state,
        isLoading: false
      };
    default:
      return state;
  }
};

export default extendTrialByTrialSuggestionReducer;
