import * as types from '../actions/types';

const initialState = {
  isLoading: false,
  chatHistoryData: null,
  rooms:[],
  roomData:{},
  messages:[],
  isError: false,
  isRefresh: false
};

const chatHistoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_ROOMS_REQUESTED:
      return {
        ...state,
        isLoading: true,
        error: null
      };
    case types.GET_ROOMS_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        rooms: action.data.data,
        error: null
      };
    case types.GET_ROOMS_FAILED:
      return {
        ...state,
        isLoading: false,
        isError: true,
        rooms:[],
        error: action.error
      };
    case types.SEARCH_ROOMS_REQUESTED:
      return {
        ...state,
        isLoading: true,
        error: null
      };
    case types.SEARCH_ROOMS_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        rooms: action.data.data,
        error: null
      };
    case types.SEARCH_ROOMS_FAILED:
      return {
        ...state,
        isLoading: false,
        isError: true,
        rooms:[],
        error: action.error
      };
    case types.SEARCH_MESSAGES_REQUESTED:
      return {
        ...state,
        isLoading: true,
        error: null
      };
    case types.SEARCH_MESSAGES_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        messages: action.data.data,
        error: null
      };
    case types.SEARCH_MESSAGES_FAILED:
      return {
        ...state,
        isLoading: false,
        isError: true,
        messages:[],
        error: action.error
      };
    case types.GET_ROOMS_DETAIL_REQUESTED:
      return {
        ...state,
        isLoading: true,
        error: null
      };
    case types.GET_ROOMS_DETAIL_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        roomData: action.data.data || [],
        error: null
      };
    case types.GET_ROOMS_DETAIL_FAILED:
      return {
        ...state,
        isLoading: false,
        isError: true,
        roomData:{},
        error: action.error
      };
    case types.CLEAR_ROOMS_DETAIL:
      return {
        ...state,
        isLoading: false,
        roomData: {},
        error: null
      };
    case types.GET_MESSAGES_REQUESTED:
      return {
        ...state,
        isLoading: true,
        error: null
      };
    case types.GET_MESSAGES_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        messages: action.data.data || [],
        error: null
      };
    case types.GET_MESSAGES_FAILED:
      return {
        ...state,
        isLoading: false,
        isError: true,
        messages:{},
        error: action.error
      };
    case types.MIGRATE_MESSAGES_REQUESTED:
      return {
        ...state,
        isLoading: true,
        error: null
      };
    case types.MIGRATE_MESSAGES_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        messages: action.data.data || [],
        error: null
      };
    case types.MIGRATE_MESSAGES_FAILED:
      return {
        ...state,
        isLoading: false,
        isError: true,
        messages:{},
        error: action.error
      };
    default:
      return state;
  }
};

export default chatHistoryReducer;
