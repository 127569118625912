import { combineReducers } from 'redux';
import serverMaintain from './serverRepair';
import app from './appReducer';
import comm from './commonReducer';
import err from './errorReducer';
import auth from './authReducer';
import homescreen from './homescreenReducer';
import match from './matchReducer';
import participation from './participationReducer';
import popups from './popupsReducer';
import userProfile from './usersReducer';
import useCoupon from './useCouponReducer';
import cancelDate from './cancelDateReducer';
import chatRoom from './chatRoomReducer';
import chatHistory from './chatHistoryReducer';
import cafe from './cafeReducer';
import chatMessages from './chatMessagesReducer';
import changePlan from './changePlanReducer';
import drawer from './drawerReducer';
import deactiveProfile from './deactiveProfileReducer';
import cancelProfile from './cancelProfileReducer';
import feedback from './feedbackReducer';
import inviteFriends from './inviteFriendsReducer';
import matchingDate from './matchingDateReducer';
import matchedUserPairList from './matchedUserPairListReducer';
import matchProfileInfo from './matchProfileInfoReducer';
import evaluationInfo from './evaluationInfoReducer';
import evaluationList from './evaluationListReducer';
import coupons from './couponsReducer';
import extendTrialBySuggestion from './extendTrialByTrialSuggestionReducer';
import rematch from './rematchReducer';
import prefectures from './prefectureReducer';
import area from './areaReducer';
import settings from './settingReducer';
import notification from './notificationReducer';
import participantCountReducer from './participantCountReducer';
import timeSettings from './timeSettingReducer';
import educationGroup from './educationGroupReducer';
import datingDayOfWeekSetting from './datingDayOfWeekSettingReducer';
import matchingInfo from './matchingInfoReducer';
import userDetail from './userDetailReducer';
import userCoupon from './userCouponReducer';
import userTrial from './userTrialReducer';
import userActivityLog from './userActivityLogReducer';
import userDatingHistory from './userDatingHistoryReducer';
import systemLogs from './systemLogReducer';
import trainStation from './trainStationReducer';
import useImage from './useImageReducer';
import plan from './planReducer';
import sendBulkMessages from './sendBulkMessageReducer';
import adminManagement from './adminManagementReducer';
import batchQueue from './batchQueueReducer';
import downloadCsv from './downloadCsvReducer';
import certification from './certificationReducer';
import prefectureSetting from './prefectureSettingReducer';
import commonVariables from './commonVariablesReducer';
import datingReport from './datingReportReducer';
import subscription from './subscriptionReducer';
import invoice from './invoiceReducer';
import userPlan from './userPlanReducer';
import banner from './manageBannerReducer';
import extenedPaticipationReducer from './extenedPaticipationReducer';
import costPlanReducer from './costPlanReducer';
import participantCountBlackReducer from './participantCountBlackReducer';

export default combineReducers({
  comm,
  serverMaintain,
  app,
  err,
  auth,
  area,
  settings,
  prefectures,
  homescreen,
  match,
  timeSettings,
  matchingDate,
  matchedUserPairList,
  participation,
  popups,
  userProfile,
  useCoupon,
  cancelDate,
  chatRoom,
  chatHistory,
  cafe,
  chatMessages,
  changePlan,
  drawer,
  deactiveProfile,
  cancelProfile,
  feedback,
  inviteFriends,
  matchProfileInfo,
  coupons,
  extendTrialBySuggestion,
  rematch,
  evaluationInfo,
  notification,
  participantCountReducer,
  evaluationList,
  datingDayOfWeekSetting,
  datingReport,
  educationGroup,
  matchingInfo,
  userDetail,
  userCoupon,
  userTrial,
  userActivityLog,
  userDatingHistory,
  systemLogs,
  trainStation,
  plan,
  useImage,
  sendBulkMessages,
  adminManagement,
  batchQueue,
  downloadCsv,
  certification,
  prefectureSetting,
  commonVariables,
  subscription,
  invoice,
  userPlan,
  banner,
	extenedPaticipationReducer,
	costPlanReducer,
  participantCountBlackReducer
});
