import * as types from '../actions/types';

const INITIAL = {
  isLoading: false
};

const appReducer = (state = INITIAL, action) => {
  switch (action.type) {
    case types.SHOW_LOADING:
      return {
        ...state,
        isLoading: true
      };
    case types.HIDE_LOADING:
      return {
        ...state,
        isLoading: false
      };
    default:
      return state;
  }
};

export default appReducer;
