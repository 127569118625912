import React from 'react';
import { ReactSVG } from 'react-svg';
import loadingSpinner from '../../assets/loading-spinner.svg';
import styles from './index.module.css';
import './styles.css';
import PropTypes from 'prop-types';


const LoadingSpinner = ({ className }) => (
  <div className={styles.overlay}>
    <ReactSVG className={className || styles.wrapper} src={loadingSpinner} />
  </div>
);
LoadingSpinner.propTypes = {
  className: PropTypes.string
}

const Spinner = ({ hidden, className }) =>
  hidden ? null : (
    <div className={className}>
      <ReactSVG src={loadingSpinner} />
    </div>
  );
Spinner.propTypes = {
  className: PropTypes.string,
  hidden: PropTypes.bool
}
const ThreeDots = () => (
  <div className="lds-ellipsis">
    <div />
    <div />
    <div />
    <div />
  </div>
);

export { LoadingSpinner, Spinner, ThreeDots };
