import { call, put, takeLatest } from 'redux-saga/effects';
import * as types from '../actions/types';
import { requestAPI } from '../services/request';
import { API } from '../services/api';
import { ResponseStatus } from '../../constants/constant';
import { toast } from 'react-toastify';

function* getAllPlans(action) {
  let response = null;
  try {
    response = yield call(requestAPI, API.API_PLAN, API.GET, {
      query: action.params
    });

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.GET_PLAN_SUCCEEDED,
        data: response.data
      });
    } else {
      yield put({
        type: types.GET_PLAN_FAILED,
        error: response
      });
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    toast(error.message);
    yield put({ type: types.GET_PLAN_FAILED, error });
  }
}

function* getUserPlans(action) {
  let response = null;
  try {
    response = yield call(
      requestAPI,
      `${API.API_USER_PLAN}/${action.params.id}`,
      API.GET
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.GET_USER_PLAN_SUCCEEDED,
        data: response.data
      });
    } else {
      yield put({
        type: types.GET_USER_PLAN_FAILED,
        error: response
      });
    }
  } catch (error) {
    toast(error.message);
    yield put({ type: types.GET_USER_PLAN_FAILED, error });
  }
}

export function* watchGetAllPlans() {
  yield takeLatest(types.GET_PLAN_REQUESTED, getAllPlans);
}

export function* watchGetUserPlans() {
  yield takeLatest(types.GET_USER_PLAN_REQUESTED, getUserPlans);
}
