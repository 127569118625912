import * as types from '../actions/types';
import { enableInitializing } from 'initializable-reducer';
const INITIAL = {
  data: [],
  isLoading: false,
  isError: false
};

const userPlanReducer = enableInitializing((state = INITIAL, action) => {
  switch (action.type) {
    case types.GET_USER_PLAN_REQUESTED:
      return {
        ...state,
        isLoading: true
    };
    case types.GET_USER_PLAN_SUCCEEDED:
      return {
        ...state,
        data: action.data.data,
        isLoading: false
      };
    case types.GET_USER_PLAN_FAILED:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.error
      };
    default:
      return state;
  }
});

export default userPlanReducer;
