import { call, put, takeLatest } from 'redux-saga/effects';
import * as types from '../actions/types';
import { requestAPI } from '../services/request';
import { API } from '../services/api';
import { ResponseStatus } from '../../constants/constant';
import { toast } from 'react-toastify';

function* getRooms(action) {
  let response = null;
  try {
    response = yield call(
      requestAPI,
      `${API.API_CHAT}/rooms`,
      API.GET,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.GET_ROOMS_SUCCEEDED,
        data: {
          ...response.data
        }
      });
      toast.success('Get rooms successful');
    } else {
      yield put({
        type: types.GET_ROOMS_FAILED,
        error: response
      });
    }

    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.GET_ROOMS_FAILED, error });
  }
}

function* getRoomDetail(action) {
  let response = null;
  try {
    response = yield call(
      requestAPI,
      `${API.API_CHAT}/room/${action.params.id}`,
      API.GET,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.GET_ROOMS_DETAIL_SUCCEEDED,
        data: {
          ...response.data
        }
      });
    } else {
      yield put({
        type: types.GET_ROOMS_DETAIL_FAILED,
        error: response
      });
    }

    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    alert(error.message);
    yield put({ type: types.GET_ROOMS_DETAIL_FAILED, error });
  }
}

function* getAllMessages(action) {
  let response = null;
  try {
    response = yield call(
      requestAPI,
      API.API_CHAT + '/messages',
      API.GET,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.GET_MESSAGES_SUCCEEDED,
        data: response.data
      });
    } else {
      yield put({
        type: types.GET_MESSAGES_FAILED,
        error: response
      });
    }

    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    toast(error.message);
    yield put({ type: types.GET_MESSAGES_FAILED, error });
  }
}

function* searchMessages(action) {
  let response = null;
  try {
    response = yield call(
      requestAPI,
      API.API_CHAT + `/messages?search=${action.params.text}`,
      API.GET,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.SEARCH_MESSAGES_SUCCEEDED,
        data: response.data
      });
    } else {
      yield put({
        type: types.SEARCH_MESSAGES_FAILED,
        error: response
      });
    }

    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    toast(error.message);
    yield put({ type: types.SEARCH_MESSAGES_FAILED, error });
  }
}

function* searchRoom(action) {
  let response = null;
  try {
    response = yield call(
      requestAPI,
      API.API_CHAT +
        `/rooms?search=${action.params.text}&page=${action.params.page}`,
      API.GET,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.SEARCH_ROOMS_SUCCEEDED,
        data: response.data
      });
    } else {
      yield put({
        type: types.SEARCH_ROOMS_FAILED,
        error: response
      });
    }

    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    toast(error.message);
    yield put({ type: types.SEARCH_ROOMS_FAILED, error });
  }
}

function* migrateMessages(action) {
  let response = null;
  try {
    response = yield call(
      requestAPI,
      API.API_CHAT + `/migrate-messages-in-room`,
      API.POST,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.MIGRATE_MESSAGES_SUCCEEDED,
        data: response.data
      });
    } else {
      yield put({
        type: types.MIGRATE_MESSAGES_FAILED,
        error: response
      });
    }

    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    toast(error.message);
    yield put({ type: types.MIGRATE_MESSAGES_FAILED, error });
  }
}

export function* watchGetRooms() {
  yield takeLatest(types.GET_ROOMS_REQUESTED, getRooms);
}

export function* watchGetMessages() {
  yield takeLatest(types.GET_MESSAGES_REQUESTED, getAllMessages);
}

export function* watchGetRoomDetail() {
  yield takeLatest(types.GET_ROOMS_DETAIL_REQUESTED, getRoomDetail);
}

export function* watchSearchRoom() {
  yield takeLatest(types.SEARCH_ROOMS_REQUESTED, searchRoom);
}

export function* watchSearchMessages() {
  yield takeLatest(types.SEARCH_MESSAGES_REQUESTED, searchMessages);
}

export function* watchMigrateMessages() {
  yield takeLatest(types.MIGRATE_MESSAGES_REQUESTED, migrateMessages);
}
