import { call, put, takeLatest } from 'redux-saga/effects';
import * as types from '../actions/types';
import { requestAPI } from '../services/request';
import { API } from '../services/api';
import { ResponseStatus } from '../../constants/constant';
import { toast } from 'react-toastify';

function* getCertificates(action) {
  let response = null;
  try {
    response = yield call(requestAPI, API.API_CERTIFICATION, API.GET, {
      query: action.params
    });

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.LIST_CERTIFICATIONS_SUCCEEDED,
        data: response.data.data
      });
    } else {
      yield put({
        type: types.LIST_CERTIFICATIONS_FAILED,
        error: response
      });
    }

    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    toast.error(error.message);
    yield put({ type: types.LIST_CERTIFICATIONS_FAILED, error });
  }
}

function* approveCertificate(action) {
  let response = null;
  try {
    response = yield call(
      requestAPI,
      API.API_APPROVE_CERTIFICATION + action.params.id,
      API.POST
    );
    if (response.status === ResponseStatus.SUCCESS) {
      toast.success('Sucessfully approve certificate!');
      yield put({
        type: types.APPROVE_CERTIFICATION_SUCCEEDED
      });
    } else {
      toast.error('Failed approve certificate!');
      yield put({
        type: types.APPROVE_CERTIFICATION_FAILED,
        error: response
      });
    }

    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    toast.error(error.message);
    yield put({ type: types.APPROVE_CERTIFICATION_FAILED, error });
  }
}

function* rejectCertificate(action) {
  let response = null;
  try {
    response = yield call(
      requestAPI,
      API.API_REJECT_CERTIFICATION + action.params.id,
      API.POST
    );
    if (response.status === ResponseStatus.SUCCESS) {
      toast.success('Sucessfully reject certificate!');
      yield put({
        type: types.REJECT_CERTIFICATION_SUCCEEDED
      });
    } else {
      toast.error('Failed reject certificate!');
      yield put({
        type: types.REJECT_CERTIFICATION_FAILED,
        error: response
      });
    }

    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    toast.error(error.message);
    yield put({ type: types.REJECT_CERTIFICATION_FAILED, error });
  }
}

export function* watchGetCertificates() {
  yield takeLatest(types.LIST_CERTIFICATIONS_REQUESTED, getCertificates);
}

export function* watchApproveCertificate() {
  yield takeLatest(types.APPROVE_CERTIFICATION_REQUESTED, approveCertificate);
}

export function* watchRejectCertificate() {
  yield takeLatest(types.REJECT_CERTIFICATION_REQUESTED, rejectCertificate);
}
