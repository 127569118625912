import * as types from '../actions/types';

const initialState = {
  isLoading: false,
  isError: false,
  data: null,
  isRefresh: false
};

const extenedPaticipationReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_EXTEND_DATING_DAY_DISPLAY_REQUESTED:
      return {
        ...state,
        isLoading: true,
        error: null
      };
    case types.GET_EXTEND_DATING_DAY_DISPLAY_REQUESTED_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        data: action.data,
        error: null
      };
    case types.GET_EXTEND_DATING_DAY_DISPLAY_REQUESTED_FAILED:
      return {
        ...state,
        isError: true,
        isLoading: false,
        data: null,
        error: action.error
      };

    case types.UPDATE_EXTEND_DATING_DAY_DISPLAY_REQUESTED:
      return {
        ...state,
        isLoading: true,
        error: null
      };
    case types.UPDATE_EXTEND_DATING_DAY_DISPLAY_REQUESTED_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        data: action.data,
        error: null
      };
    case types.UPDATE_EXTEND_DATING_DAY_DISPLAY_REQUESTED_FAILED:
      return {
        ...state,
        isError: true,
        isLoading: false,
        data: null,
        error: action.error
      };
    default:
      return state;
  }
};

export default extenedPaticipationReducer;
