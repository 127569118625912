import createSagaMiddleware from 'redux-saga';
import { createStore, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { composeWithDevTools } from 'redux-devtools-extension';
import { logger } from 'redux-logger';
import RootSaga from './sagas/rootSagas';
import reducers from '../redux/reducers/index';
import { hideLoading } from './actions';

const persistConfig = {
  key: 'root',
  storage,
  blacklist: [
    'servermaintain',
    'app',
    'err',
    'cafe',
    'cancelDate',
    'cancelDateByPartner',
    'chatHistory',
    'chatMessages',
    'chatRoom',
    'participation',
    'popups',
    'useCoupon',
    'userProfile',
    'drawer',
    'homescreen',
    'feedback',
    'inviteFriends',
    'cancelProfile',
    'deactiveProfile',
    'changePlan',
    'coupons',
    'extendTrialBySuggestion',
    'rematch',
    'notification',
    'userCoupon',
    'userTrial',
    'userActivityLog',
    'userDatingHistory',
    'subscription',
    'invoice',
    'userPlan',
    'datingReport'
  ]
};

const persistedReducer = persistReducer(persistConfig, reducers);

const sagaMiddleware = createSagaMiddleware();

const middlewares = [sagaMiddleware];

if (process.env.NODE_ENV !== 'test') {
  middlewares.push(logger);
}

const store = createStore(
  persistedReducer,
  composeWithDevTools(applyMiddleware(...middlewares))
);
const persistor = persistStore(store, null, () => {
  store.dispatch(hideLoading());
});
sagaMiddleware.run(RootSaga);

export { store, persistor };
