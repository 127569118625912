import { call, put, takeLatest } from 'redux-saga/effects';
import * as types from '../actions/types';
import { requestAPI } from '../services/request';
import { API } from '../services/api';
import { ResponseStatus } from '../../constants/constant';
import { toast } from 'react-toastify';

function* getSystemLogs(action) {
  let response = null;
  try {
    response = yield call(
      requestAPI,
      API.API_LOGS + `?file_name=${action.params.fileName}`,
      API.GET,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.GET_SYSTEM_LOG_SUCCEEDED,
        data: response.data
      });
    } else {
      yield put({
        type: types.GET_SYSTEM_LOG_FAILED,
        error: response
      });
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    toast(error.message);
    yield put({ type: types.GET_SYSTEM_LOG_FAILED, error });
  }
}

export function* watchGetSystemLogs() {
  yield takeLatest(types.GET_SYSTEM_LOG_REQUESTED, getSystemLogs);
}
