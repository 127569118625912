import { call, put, takeLatest } from 'redux-saga/effects';
import * as types from '../actions/types';
import { requestAPI } from '../services/request';
import { API } from '../services/api';
import { ResponseStatus } from '../../constants/constant';
import { toast } from 'react-toastify';

function* getFeedbackFlexQuestionControlData(action) {
  let response = null;
  try {
    response = yield call(
      requestAPI,
      API.API_FEEDBACK_FLEX_QUESTION + '/control-data',
      API.GET,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.GET_FEEDBACK_FLEX_QUESTION_CONTROL_DATA_SUCCEEDED,
        data: {
          ...response.data
        }
      });
    } else {
      yield put({
        type: types.GET_FEEDBACK_FLEX_QUESTION_CONTROL_DATA_FAILED,
        error: response.response.data
      });
      toast.error(response.response.data.message);
    }

    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({
      type: types.GET_FEEDBACK_FLEX_QUESTION_CONTROL_DATA_FAILED,
      error
    });
  }
}

function* requestUserFeedbackDetails(action) {
  let response = null;
  try {
    response = yield call(
      requestAPI,
      API.API_FEEDBACK_USER_DETAIL + '/' + action.params.id,
      API.GET,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.GET_USER_FEEDBACK_DETAILS_SUCCEEDED,
        data: {
          ...response.data
        }
      });
    } else {
      yield put({
        type: types.GET_USER_FEEDBACK_DETAILS_FAILED,
        error: response.response.data
      });
      toast.error(response.response.data.message);
    }

    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({
      type: types.GET_USER_FEEDBACK_DETAILS_FAILED,
      error
    });
  }
}

function* listFeedbackFlexQuestions(action) {
  let response = null;
  try {
    response = yield call(
      requestAPI,
      API.API_FEEDBACK_FLEX_QUESTION + '/list',
      API.GET,
      {
        query: action.params
      }
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.LIST_FEEDBACK_FLEX_QUESTIONS_SUCCEEDED,
        data: {
          ...response.data
        }
      });
    } else {
      yield put({
        type: types.LIST_FEEDBACK_FLEX_QUESTIONS_FAILED,
        error: response.response.data
      });
      toast.error(response.response.data.message);
    }

    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.LIST_FEEDBACK_FLEX_QUESTIONS_FAILED, error });
  }
}

function* getFeedbackFlexQuestion(action) {
  let response = null;
  try {
    response = yield call(
      requestAPI,
      API.API_FEEDBACK_FLEX_QUESTION + '/detail/' + action.params.id,
      API.GET,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.GET_FEEDBACK_FLEX_QUESTION_SUCCEEDED,
        data: {
          ...response.data
        }
      });
    } else {
      yield put({
        type: types.GET_FEEDBACK_FLEX_QUESTION_FAILED,
        error: response.response.data
      });
      toast.error(response.response.data.message);
    }

    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.GET_FEEDBACK_FLEX_QUESTION_FAILED, error });
  }
}

function* createFeedbackFlexQuestion(action) {
  let response = null;
  try {
    response = yield call(
      requestAPI,
      API.API_FEEDBACK_FLEX_QUESTION + '/create',
      API.POST,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.CREATE_FEEDBACK_FLEX_QUESTION_SUCCEEDED,
        data: {
          ...response.data
        }
      });
      toast.success('Create question successful');
    } else {
      yield put({
        type: types.CREATE_FEEDBACK_FLEX_QUESTION_FAILED,
        error: response.response.data
      });
      toast.error(response.response.data.message);
    }

    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.CREATE_FEEDBACK_FLEX_QUESTION_FAILED, error });
  }
}

function* updateFeedbackFlexQuestion(action) {
  let response = null;
  try {
    response = yield call(
      requestAPI,
      API.API_FEEDBACK_FLEX_QUESTION + '/update/' + action.params.id,
      API.POST,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.UPDATE_FEEDBACK_FLEX_QUESTION_SUCCEEDED,
        data: {
          ...response.data
        }
      });
      toast.success('Update question successful');
    } else {
      yield put({
        type: types.UPDATE_FEEDBACK_FLEX_QUESTION_FAILED,
        error: response.response.data
      });
      toast.error(response.response.data.message);
    }

    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.UPDATE_FEEDBACK_FLEX_QUESTION_FAILED, error });
  }
}

export function* watchGetFeedbackFlexQuestionControlData() {
  yield takeLatest(
    types.GET_FEEDBACK_FLEX_QUESTION_CONTROL_DATA_REQUESTED,
    getFeedbackFlexQuestionControlData
  );
}

export function* watchRequestUserFeedbackDetails() { 
  yield takeLatest(
    types.GET_USER_FEEDBACK_DETAILS_REQUESTED,
    requestUserFeedbackDetails
  );
}

export function* watchListFeedbackFlexQuestions() {
  yield takeLatest(
    types.LIST_FEEDBACK_FLEX_QUESTIONS_REQUESTED,
    listFeedbackFlexQuestions
  );
}

export function* watchGetFeedbackFlexQuestion() {
  yield takeLatest(
    types.GET_FEEDBACK_FLEX_QUESTION_REQUESTED,
    getFeedbackFlexQuestion
  );
}

export function* watchCreateFeedbackFlexQuestion() {
  yield takeLatest(
    types.CREATE_FEEDBACK_FLEX_QUESTION_REQUESTED,
    createFeedbackFlexQuestion
  );
}

export function* watchUpdateFeedbackFlexQuestion() {
  yield takeLatest(
    types.UPDATE_FEEDBACK_FLEX_QUESTION_REQUESTED,
    updateFeedbackFlexQuestion
  );
}
