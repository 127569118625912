import { call, put, takeLatest } from 'redux-saga/effects';
import * as types from '../actions/types';
import { requestAPI } from '../services/request';
import { API } from '../services/api';
import { toast } from 'react-toastify';
import { ResponseStatus } from '../../constants/constant';

function* getAllParticipants(action) {
  let response = null;
  try {
    response = yield call(
      requestAPI,
      `${API.API_PARTICIPANT}/list`,
      API.GET,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.GET_PARTICIPANTS_SUCCEEDED
      });
    } else {
      yield put({ type: types.GET_PARTICIPANTS_FAILED, error: response });
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.GET_PARTICIPANTS_FAILED, error });
  }
}

function* getUserParticipantHistory(action) {
  let response = null;
  try {
    response = yield call(
      requestAPI,
      `${API.API_PARTICIPANT_USER_DETAIL}/${action.params.id}`,
      API.GET,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.GET_PARTICIPANTS_USER_HISTORY_SUCCEEDED,
        data: {
          ...response.data
          }
      });
    } else {
      yield put({ type: types.GET_PARTICIPANTS_USER_HISTORY_FAILED, error: response });
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.GET_PARTICIPANTS_USER_HISTORY_FAILED, error });
  }
}

function* getUserAvailable(action) {
  let response = null;
  try {
    response = yield call(
      requestAPI,
      `${API.API_PARTICIPANT}/users-available`,
      API.GET,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.GET_USERS_AVAILABLE_SUCCEEDED
      });
    } else {
      yield put({ type: types.GET_USERS_AVAILABLE_FAILED, error: response });
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.GET_USERS_AVAILABLE_FAILED, error });
  }
}

function* importRematching(action) {
  let response = null;
  try {
    response = yield call(
      requestAPI,
      `${API.API_DATING}/rematch`,
      API.POST,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      toast.success('Import successful!');
      yield put({
        type: types.IMPORT_REMATCHING_SUCCEEDED,
        data: response.data
      });
    } else {
      toast.error('Please try again!');
      yield put({ type: types.IMPORT_REMATCHING_FAILED, error: response });
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.IMPORT_REMATCHING_FAILED, error });
  }
}

function* migrateParticipants(action) {
  let response = null;
  try {
    response = yield call(
      requestAPI,
      `${API.API_PARTICIPANT}/migrate`,
      API.POST,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      toast.success('Migrate successful!');
      yield put({
        type: types.MIGRATE_SUCCEEDED,
        data: response.data
      });
    } else {
      toast.error('Please try again!');
      yield put({ type: types.MIGRATE_FAILED, error: response });
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    toast.error('Please try again!');
    yield put({ type: types.MIGRATE_FAILED, error });
  }
}

function* resetLastWeek(action) {
  let response = null;
  try {
    response = yield call(
      requestAPI,
      `${API.API_PARTICIPANT}/reset-dating-and-participation`,
      API.GET,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      toast.success('Reset last week successful!');
      yield put({
        type: types.RESET_LAST_WEEK_SUCCEEDED,
        data: response.data
      });
    } else {
      toast.error('Please try again!');
      yield put({ type: types.RESET_LAST_WEEK_FAILED, error: response });
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    toast.error('Please try again!');
    yield put({ type: types.RESET_LAST_WEEK_FAILED, error });
  }
}

function* getAwaitingMainMatchAndRematchParticipants(action) {
  let response = null;
  try {
    response = yield call(
      requestAPI,
      `${API.API_PARTICIPANT}/list-main-match-and-rematch`,
      API.GET,
      {
        query: action.params
      }
    );
    if (action.onComplete) {
      action.onComplete(response);
    }

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.GET_PARTICIPANTS_MAIN_MATCH_AND_REMATCH_SUCCEEDED
      });
    } else {
      yield put({
        type: types.GET_PARTICIPANTS_MAIN_MATCH_AND_REMATCH_FAILED,
        error: response
      });
    }
  } catch (error) {
    yield put({
      type: types.GET_PARTICIPANTS_MAIN_MATCH_AND_REMATCH_FAILED,
      error
    });
  }
}

function* cancelAwaitingParticipant(action) {
  let response = null;
  try {
    response = yield call(
      requestAPI,
      `${API.API_PARTICIPANT}/cancel-awaiting-participant`,
      API.POST,
      {
        query: action.params
      }
    );
    if (action.onComplete) {
      action.onComplete(response);
    }

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.CANCEL_AWAITING_PARTICIPANT_SUCCEEDED
      });
    } else {
      yield put({
        type: types.CANCEL_AWAITING_PARTICIPANT_FAILED,
        error: response
      });
    }
  } catch (error) {
    yield put({
      type: types.CANCEL_AWAITING_PARTICIPANT_FAILED,
      error
    });
  }
}

function* deleteMainMatchParticipant(action) {
  let response = null;

  try {
    response = yield call(
      requestAPI,
      `${API.API_PARTICIPANT}/delete-main-match-participant-on-dating-day/${action.params}`,
      API.POST,
      action.params
    );
    if (action.onComplete) {
      action.onComplete(response);
    }

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.DELETE_MAIN_MATCH_PARTICIPANT_SUCCEEDED
      });
    } else {
      yield put({
        type: types.DELETE_MAIN_MATCH_PARTICIPANT_FAILED,
        error: response
      });
    }
  } catch (error) {
    yield put({
      type: types.DELETE_MAIN_MATCH_PARTICIPANT_FAILED,
      error
    });
  }
}

function* importMainMatchParticipantCsv(action) {
  let response = null;

  try {
    response = yield call(
      requestAPI,
      `${API.API_PARTICIPANT}/upload-main-match-participant-csv`,
      API.POST,
      action.params
    );
    if (action.onComplete) {
      action.onComplete(response);
    }

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.IMPORT_MAIN_MATCH_PARTICIPANT_CSV_SUCCEEDED
      });
    } else {
      yield put({
        type: types.IMPORT_MAIN_MATCH_PARTICIPANT_CSV_FAILED,
        error: response
      });
    }
  } catch (error) {
    yield put({
      type: types.IMPORT_MAIN_MATCH_PARTICIPANT_CSV_FAILED,
      error
    });
  }
}

export function* watchGetParticipants() {
  yield takeLatest(types.GET_PARTICIPANTS_REQUESTED, getAllParticipants);
}

export function* watchUserParticipantHistory() {
  yield takeLatest(types.GET_PARTICIPANTS_USER_HISTORY_REQUESTED, getUserParticipantHistory);
}

export function* watchMigrateParticipants() {
  yield takeLatest(types.MIGRATE_REQUESTED, migrateParticipants);
}

export function* watchResetLastWeek() {
  yield takeLatest(types.RESET_LAST_WEEK_REQUESTED, resetLastWeek);
}

export function* watchGetUserAvailable() {
  yield takeLatest(types.GET_USERS_AVAILABLE_REQUESTED, getUserAvailable);
}

export function* watchImportRematching() {
  yield takeLatest(types.IMPORT_REMATCHING_REQUESTED, importRematching);
}

export function* watchGetAwaitingMainMatchAndRematchParticipants() {
  yield takeLatest(
    types.GET_PARTICIPANTS_MAIN_MATCH_AND_REMATCH_REQUESTED,
    getAwaitingMainMatchAndRematchParticipants
  );
}

export function* watchCancelAwaitingParticipant() {
  yield takeLatest(
    types.CANCEL_AWAITING_PARTICIPANT_REQUESTED,
    cancelAwaitingParticipant
  );
}

export function* watchDeleteMainMatchParticipant() {
  yield takeLatest(
    types.DELETE_MAIN_MATCH_PARTICIPANT_REQUESTED,
    deleteMainMatchParticipant
  );
}

export function* watchImportMainMatchParticipantCsv() {
  yield takeLatest(
    types.IMPORT_MAIN_MATCH_PARTICIPANT_CSV_REQUESTED,
    importMainMatchParticipantCsv
  );
}
