import * as types from '../actions/types';
import { pathOr, dropLast } from 'ramda';

const initialState = {
  isLoading: false,
  data: [],
  total: 0,
  isError: false
};

const couponsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.COUPON_LIST_RESET:
      return {
        ...state,
        data: [],
        total: 0
      };
    case types.COUPON_LIST_DECREMENT:
      return {
        ...state,
        data: dropLast(1, state.data),
        total: state.total > 0 ? state.total - 1 : 0
      };
    case types.COUPON_LIST_REQUEST:
    case types.EXCHANGE_COUPON_REQUEST:
      return { ...state, isLoading: true, error: null };
    case types.COUPON_LIST_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        data: [...state.data, ...pathOr([], ['data', 'coupons'], action)],
        total: pathOr(0, ['data', 'total'], action),
        error: null
      };
    case types.EXCHANGE_COUPON_SUCCEDDED:
      return {
        ...state,
        isLoading: false,
        isError: false
      };
    case types.EXCHANGE_COUPON_FAILED:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.error
      };
    case types.COUPON_LIST_FAILED:
      return {
        ...state,
        isLoading: false,
        isError: true,
        data: [],
        total: 0,
        error: action.error
      };
    case types.HIDE_ERROR:
      return {
        ...state,
        isError: false
      };
    default:
      return state;
  }
};

export default couponsReducer;
