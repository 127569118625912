import { call, put, takeLatest } from 'redux-saga/effects';
import * as types from '../actions/types';
import { requestAPI } from '../services/request';
import { API } from '../services/api';
import { ResponseStatus } from '../../constants/constant';
import { toast } from 'react-toastify';

function* listMatchedUser(action) {
  let response = null;
  try {
    response = yield call(
      requestAPI,
      API.API_MATCH_USER_PAIR_LIST,
      API.GET,
      action.params,
      300000
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.CREATE_MATCH_USE_PAIR_LIST_SUCCEEDED,
        data: {
          ...response.data
        }
      });
    } else {
      yield put({
        type: types.CREATE_MATCH_USE_PAIR_LIST_FAILED,
        error: response.response.data
      });
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.CREATE_MATCH_USE_PAIR_LIST_FAILED, error });
  }
}

function* createMatchedPair(action) {
  let response = null;
  try {
    response = yield call(
      requestAPI,
      API.API_MATCH_USER_PAIR_LIST_CREATE,
      API.POST,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.CREATE_MATCH_USE_PAIR_LIST_SUCCEEDED,
        data: {
          ...response.data
        }
      });
      toast.success('create dating successful');
    } else {
      if (response.response.status === 512) {
        yield put({
          type: types.CREATE_MATCH_USE_PAIR_LIST_FAILED,
          error: response.response.data
        });
        toast.error(response.response.data.message);
      } else {
        yield put({
          type: types.CREATE_MATCH_USE_PAIR_LIST_FAILED,
          error: response.response.data
        });
        toast.error('Please try again!');
      }
    }

    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.CREATE_MATCH_USE_PAIR_LIST_FAILED, error });
    toast.error('Please try again!');
  }
}

function* getMatchedDataDetails(action) {
  let response = null;
  try {
    const url = action.params.ids ? `/${action.params.ids}` : false;

    response = yield call(
      requestAPI,
      API.API_MATCH_USER_PAIR_LIST_UPDATE + url,
      API.GET,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.GET_UPDATE_MATCH_USE_PAIR_LIST_SUCCEEDED,
        data: {
          ...response.data
        }
      });
    } else {
      yield put({
        type: types.GET_UPDATE_MATCH_USE_PAIR_LIST_FAILED,
        error: response.response.data
      });
    }

    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.GET_UPDATE_MATCH_USE_PAIR_LIST_FAILED, error });
  }
}

function* updateMatchedPair(action) {
  let response = null;
  try {
    const url = action.params.ids ? `/${action.params.ids}` : false;
    response = yield call(
      requestAPI,
      API.API_MATCH_USER_PAIR_LIST_UPDATE + url,
      API.POST,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.UPDATE_MATCH_USE_PAIR_LIST_SUCCEEDED,
        data: {
          ...response.data
        }
      });
    } else {
      if (response.response.status === 512) {
        yield put({
          type: types.UPDATE_MATCH_USE_PAIR_LIST_FAILED,
          error: response.response.data
        });
        toast.error(response.response.data.message);
      } else {
        yield put({
          type: types.UPDATE_MATCH_USE_PAIR_LIST_FAILED,
          error: response.response.data
        });
      }
    }

    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.UPDATE_MATCH_USE_PAIR_LIST_FAILED, error });
  }
}

function* deleteMatchedPair(action) {
  let response = null;
  try {
    let url = action.params.cancelId ? `/${action.params.cancelId}` : false;
    if (action.params.user_id) {
      url = url + '?userId=' + action.params.user_id;
    }
    action.params.body = { cancelReason: action.params.cancelReason } || {};
    response = yield call(
      requestAPI,
      API.API_MATCH_USER_PAIR_LIST_DELETE + url,
      API.POST,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.DELETE_MATCH_USE_PAIR_LIST_SUCCEEDED,
        data: {
          ...response.data
        }
      });
    } else {
      yield put({
        type: types.DELETE_MATCH_USE_PAIR_LIST_FAILED,
        error: response.response.data
      });
    }

    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.DELETE_MATCH_USE_PAIR_LIST_FAILED, error });
  }
}

export function* watchGetMatchedList() {
  yield takeLatest(types.GET_MATCH_USE_PAIR_LIST_REQUESTED, listMatchedUser);
}
export function* watchCreateMatchedList() {
  yield takeLatest(
    types.CREATE_MATCH_USE_PAIR_LIST_REQUESTED,
    createMatchedPair
  );
}

export function* watchGetMatchedDataDetails() {
  yield takeLatest(
    types.GET_UPDATE_MATCH_USE_PAIR_LIST_REQUESTED,
    getMatchedDataDetails
  );
}

export function* watchUpdateMatchedList() {
  yield takeLatest(
    types.UPDATE_MATCH_USE_PAIR_LIST_REQUESTED,
    updateMatchedPair
  );
}
export function* watchDeleteMatchedList() {
  yield takeLatest(
    types.DELETE_MATCH_USE_PAIR_LIST_REQUESTED,
    deleteMatchedPair
  );
}
