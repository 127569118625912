import { call, put, takeLatest } from 'redux-saga/effects';
import * as types from '../actions/types';
import { requestAPI } from '../services/request';
import { API } from '../services/api';
import { ResponseStatus } from '../../constants/constant';

function* createSetting(action) {
  let response = null;
  try {
    response = yield call(
      requestAPI,
      `${API.API_SETTINGS}/create`,
      API.POST,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.CREATE_SETTINGS_SUCCEEDED,
        data: {
          ...response.data.data
        }
      });
    } else {
      yield put({
        type: types.CREATE_SETTINGS_FAILED,
        error: response
      });
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.CREATE_SETTINGS_FAILED, error });
  }
}

function* updateSetting(action) {
  let response = null;
  try {
    response = yield call(
      requestAPI,
      `${API.API_SETTINGS}/update/${action.id}`,
      API.POST,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.UPDATE_SETTINGS_SUCCEEDED,
        data: {
          ...response.data
        }
      });
    } else {
      yield put({
        type: types.UPDATE_SETTINGS_FAILED,
        error: response
      });
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.UPDATE_SETTINGS_FAILED, error });
  }
}

function* getAllSettings(action) {
  let response = null;
  try {
    response = yield call(
      requestAPI,
      API.API_SETTINGS,
      API.POST,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.GET_SETTINGS_SUCCEEDED,
        data: response.data
      });
    } else {
      yield put({
        type: types.GET_SETTINGS_FAILED,
        error: response
      });
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.GET_SETTINGS_FAILED, error });
  }
}

function* deleteSetting(action) {
  let response = null;
  try {
    response = yield call(
      requestAPI,
      `${API.API_SETTINGS}/delete/${action.params.id}`,
      API.POST,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.DELETE_SETTINGS_SUCCEEDED,
        data: response.data
      });
    } else {
      yield put({
        type: types.DELETE_SETTINGS_FAILED,
        error: response
      });
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.DELETE_SETTINGS_FAILED, error });
  }
}

export function* watchGetAllSettings() {
  yield takeLatest(types.GET_SETTINGS_REQUESTED, getAllSettings);
}

export function* watchUpdateSetting() {
  yield takeLatest(types.UPDATE_SETTINGS_REQUESTED, updateSetting);
}
export function* watchDeleteSetting() {
  yield takeLatest(types.DELETE_SETTINGS_REQUESTED, deleteSetting);
}

export function* watchCreateSetting() {
  yield takeLatest(types.CREATE_SETTINGS_REQUESTED, createSetting);
}
