import { call, put, takeLatest } from 'redux-saga/effects';
import * as types from '../actions/types';
import { requestAPI } from '../services/request';
import { API } from '../services/api';
import { ResponseStatus } from '../../constants/constant';
import { toast } from 'react-toastify';

function* getDatingDayDisplayBlack(action) {
  let response = null;

  try {
    response = yield call(requestAPI, API.API_DATING_DAY_DISPLAY_BLACK + '/list', API.GET, {
        query: action.params
    });

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.GET_DATING_DAY_DISPLAY_BLACK_SUCCEEDED,
        data: response.data
      });
    } else {
      yield put({
        type: types.GET_DATING_DAY_DISPLAY_BLACK_FAILED,
        error: response
      });
    }

    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.GET_DATING_DAY_DISPLAY_BLACK_FAILED, error });
  }
}

function* updateDatingDayDisplayBlack(action) {
  let response = null;

  try {
    response = yield call(
      requestAPI,
      API.API_DATING_DAY_DISPLAY_BLACK + '/set',
      API.POST,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.UPDATE_DATING_DAY_DISPLAY_BLACK_SUCCEEDED,
        data: {
          ...response.data
        }
      });
      toast.success('Update dating day display successful!');
    } else {
      yield put({
        type: types.UPDATE_DATING_DAY_DISPLAY_BLACK_FAILED,
        error: response
      });
      toast.error('Please try again!');
    }
  } catch (error) {
    yield put({ type: types.UPDATE_DATING_DAY_DISPLAY_BLACK_FAILED, error });
    toast.error('Please try again!');
  }
}

export function* watchGetDatingDayDisplayBlack() {
  yield takeLatest(types.GET_DATING_DAY_DISPLAY_BLACK_REQUESTED, getDatingDayDisplayBlack);
}

export function* watchUpdateDatingDayDisplayBlack() {
  yield takeLatest(types.UPDATE_DATING_DAY_DISPLAY_BLACK_REQUESTED, updateDatingDayDisplayBlack);
}
