import * as types from '../actions/types';

const initialState = {
  isLoading: false,
  isError: false,
  data: null,
  isRefresh: false
};

const participantCountBlackReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_DATING_DAY_DISPLAY_BLACK_REQUESTED:
      return {
        ...state,
        isLoading: true,
        error: null
      };
    case types.GET_DATING_DAY_DISPLAY_BLACK_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        data: action.data,
        error: null
      };
    case types.GET_DATING_DAY_DISPLAY_BLACK_FAILED:
      return {
        ...state,
        isError: true,
        isLoading: false,
        data: null,
        error: action.error
      };

    case types.UPDATE_DATING_DAY_DISPLAY_BLACK_REQUESTED:
      return {
        ...state,
        isLoading: true,
        error: null
      };
    case types.UPDATE_DATING_DAY_DISPLAY_BLACK_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        data: action.data,
        error: null
      };
    case types.UPDATE_DATING_DAY_DISPLAY_BLACK_FAILED:
      return {
        ...state,
        isError: true,
        isLoading: false,
        data: null,
        error: action.error
      };

    default:
      return state;
  }
};

export default participantCountBlackReducer;
