import * as types from '../actions/types';

const initialState = {
  isLoading: false,
  data: null,
  isError: false
};

const userTrialReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_USER_TRIAL_REQUESTED:
      return {
        ...state,
        isLoading: true
      };
    case types.GET_USER_TRIAL_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        data: action.data.data,
        error: null
      };
    case types.GET_USER_TRIAL_FAILED:
      return {
        ...state,
        isLoading: false,
        data: null,
        isError: true,
        error: action.error
      };
    default:
      return state;
  }
};

export default userTrialReducer;
