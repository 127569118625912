import { call, put, takeLatest } from 'redux-saga/effects';
import * as types from '../actions/types';
import { requestAPI } from '../services/request';
import { API } from '../services/api';
import { ResponseStatus } from '../../constants/constant';
import { toast } from 'react-toastify';

function* createCafe(action) {
  let response = null;
  try {
    response = yield call(
      requestAPI,
      `${API.API_CAFES}/create`,
      API.POST,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      toast.success(response.data.message);
      yield put({
        type: types.CREATE_CAFE_SUCCEEDED,
        data: {
          ...response.data
        }
      });
    } else {
      yield put({
        type: types.CREATE_CAFE_FAILED,
        error: response
      });
    }

    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    toast.error(error.message);
    yield put({ type: types.CREATE_CAFE_FAILED, error });
  }
}

function* updateCafe(action) {
  let response = null;
  try {
    response = yield call(
      requestAPI,
      `${API.API_CAFES}/update/${action.params.id}`,
      API.POST,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      toast.success(response.data.message);
      yield put({
        type: types.UPDATE_CAFE_SUCCEEDED,
        data: {
          ...response.data
        }
      });
    } else {
      yield put({
        type: types.UPDATE_CAFE_FAILED,
        error: response
      });
    }

    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.UPDATE_CAFE_FAILED, error });
  }
}

function* getAllCafes(action) {
  let response = null;
  try {
    response = yield call(requestAPI, API.API_CAFES, API.GET, {
      query: action.params
    });

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.GET_CAFE_SUCCEEDED,
        data: response.data.data
      });
    } else {
      yield put({
        type: types.GET_CAFE_FAILED,
        error: response
      });
    }

    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.GET_CAFE_FAILED, error });
  }
}

function* deleteCafe(action) {
  let response = null;
  try {
    response = yield call(
      requestAPI,
      `${API.API_CAFES}/delete/${action.id}`,
      API.POST,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.DELETE_CAFE_SUCCEEDED,
        data: response.data
      });
    } else {
      yield put({
        type: types.DELETE_CAFE_FAILED,
        error: response
      });
    }

    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.DELETE_CAFE_FAILED, error });
  }
}

function* changeApproveCafe(action) {
  let response = null;
  try {
    response = yield call(
      requestAPI,
      `${API.API_CAFES}/change-approve/${action.params.id}`,
      API.POST,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      toast(response.data.message);
      yield put({
        type: types.CHANGE_APPROVE_CAFE_SUCCEEDED,
        data: response.data
      });
    } else {
      yield put({
        type: types.CHANGE_APPROVE_CAFE_FAILED,
        error: response
      });
    }

    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.CHANGE_APPROVE_CAFE_FAILED, error });
  }
}

function* changeBlackActiveCafe(action) {
  let response = null;
  try {
    response = yield call(
      requestAPI,
      `${API.API_CAFES}/update-black/${action.params.id}`,
      API.POST,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      toast.success('Successful');
      yield put({
        type: types.CHANGE_BLACK_ACTIVE_CAFE_SUCCEEDED,
        data: response.data
      });
    } else {
      yield put({
        type: types.CHANGE_BLACK_ACTIVE_CAFE_FAILED,
        error: response
      });
    }

    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.CHANGE_BLACK_ACTIVE_CAFE_FAILED, error });
  }
}

function* importCafeDatingCsv(action) {
  let response = null;

  try {
    response = yield call(
      requestAPI,
      `${API.API_CAFES}/import-csv`,
      API.POST,
      action.params
    );
    if (action.onComplete) {
      action.onComplete(response);
    }

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.IMPORT_CAFE_DATING_CSV_SUCCEEDED
      });
    } else {
      yield put({
        type: types.IMPORT_CAFE_DATING_CSV_FAILED,
        error: response
      });
    }
  } catch (error) {
    yield put({
      type: types.IMPORT_CAFE_DATING_CSV_FAILED,
      error
    });
  }
}

function* getCafeMapInfo(action) {
  try {
    let response = yield call(
      requestAPI,
      API.API_CAFES_GET_CAFE_MAP_INFO,
      API.GET,
      { query: action.params }
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.GET_CAFE_MAP_INFO_SUCCEEDED,
        payload: {
          ...response.data
        }
      });
    } else {
      yield put({
        type: types.GET_CAFE_MAP_INFO_FAILED,
        error: response
      });
    }

    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    toast.error(error.message);
    yield put({ type: types.GET_CAFE_MAP_INFO_FAILED, error });
  }
}

export function* watchGetAllCafes() {
  yield takeLatest(types.GET_CAFE_REQUESTED, getAllCafes);
}

export function* watchUpdateCafe() {
  yield takeLatest(types.UPDATE_CAFE_REQUESTED, updateCafe);
}
export function* watchDeleteCafe() {
  yield takeLatest(types.DELETE_CAFE_REQUESTED, deleteCafe);
}

export function* watchCreateCafe() {
  yield takeLatest(types.CREATE_CAFE_REQUESTED, createCafe);
}

export function* watchChangeApproveCafe() {
  yield takeLatest(types.CHANGE_APPROVE_CAFE_REQUESTED, changeApproveCafe);
}

export function* watchChangeBlackActiveCafe() {
  yield takeLatest(types.CHANGE_BLACK_ACTIVE_CAFE_REQUESTED, changeBlackActiveCafe);
}

export function* watchImportCafeDatingCsv() {
  yield takeLatest(types.IMPORT_CAFE_DATING_CSV_REQUESTED, importCafeDatingCsv);
}

export function* watchGetCafeMapInfo() {
  yield takeLatest(types.GET_CAFE_MAP_INFO_REQUESTED, getCafeMapInfo);
}
