import { call, put, takeLatest } from 'redux-saga/effects';
import * as types from '../actions/types';
import { requestAPI } from '../services/request';
import { API } from '../services/api';
import { ResponseStatus } from '../../constants/constant';
import { toast } from 'react-toastify';

function* getAdmins(action) {
  let response = null;
  try {
    response = yield call(requestAPI, API.API_ADMIN_MANAGEMENT, API.GET, {
      query: action.params
    });

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.GET_ADMINS_SUCCEEDED,
        data: {
          ...response.data
        }
      });
      // toast.success('Get Admins successful');
    } else {
      yield put({
        type: types.GET_ADMINS_FAILED,
        error: response
      });
    }

    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.GET_ADMINS_FAILED, error });
  }
}

function* setCurrentAdminAccount(action) {
  let response = null;
  try {
    response = yield call(requestAPI, API.API_CURRENT_ADMIN, API.GET);

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.SET_CURRENT_ADMIN_SUCCEEDED,
        data: {
          ...response.data
        }
      });
    } else {
      yield put({
        type: types.SET_CURRENT_ADMIN_FAILED,
        error: response
      });
    }

    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.SET_CURRENT_ADMIN_FAILED, error });
  }
}

function* createAdminAccount(action) {
  let response = null;
  try {
    response = yield call(
      requestAPI,
      `${API.API_ADMIN_MANAGEMENT}`,
      API.POST,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.CREATE_ADMIN_ACCOUNT_SUCCEEDED,
        data: {
          ...response.data
        }
      });
      toast.success('Create Admin Account successful');
    } else {
      yield put({
        type: types.CREATE_ADMIN_ACCOUNT_FAILED,
        error: response
      });
    }

    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.CREATE_ADMIN_ACCOUNT_FAILED, error });
  }
}

function* editAdminAccount(action) {
  let response = null;
  try {
    response = yield call(
      requestAPI,
      `${API.API_ADMIN_MANAGEMENT}/${action.params.id}`,
      API.PUT,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.EDIT_ADMIN_ACCOUNT_SUCCEEDED,
        data: {
          ...response.data
        }
      });
      toast.success('Edit Admin Account successful');
    } else {
      yield put({
        type: types.EDIT_ADMIN_ACCOUNT_FAILED,
        error: response
      });
      toast.error('Cannot edit this account');
    }

    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.EDIT_ADMIN_ACCOUNT_FAILED, error });
  }
}

function* deleteAdminAccount(action) {
  let response = null;
  try {
    response = yield call(
      requestAPI,
      `${API.API_ADMIN_MANAGEMENT}/${action.params.id}`,
      API.DELETE,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.DELETE_ADMIN_ACCOUNT_SUCCEEDED,
        data: {
          ...response.data
        }
      });
      toast.success('Delete Admin Account successful');
    } else {
      yield put({
        type: types.DELETE_ADMIN_ACCOUNT_FAILED,
        error: response
      });
      toast.error('Cannot delete this account');
    }

    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.DELETE_ADMIN_ACCOUNT_FAILED, error });
  }
}

export function* watchGetAllAdminAccount() {
  yield takeLatest(types.GET_ADMINS_REQUEST, getAdmins);
}

export function* watchCreateAdminAccount() {
  yield takeLatest(types.CREATE_ADMIN_ACCOUNT_REQUEST, createAdminAccount);
}

export function* watchDeleteAdminAccount() {
  yield takeLatest(types.DELETE_ADMIN_ACCOUNT_REQUEST, deleteAdminAccount);
}

export function* watchEditAdminAccount() {
  yield takeLatest(types.EDIT_ADMIN_ACCOUNT_REQUEST, editAdminAccount);
}

export function* watchSetCurrentAdminAccount() {
  yield takeLatest(types.SET_CURRENT_ADMIN_REQUEST, setCurrentAdminAccount);
}