import { API } from '../redux/services/api';

//Tables

export const MATCHING_PAIR_USER_TABLES = [
  'Dating Id',
  'Is Fake',
  'Male',
  'Female',
  'Area',
  'Dating Place',
  'Date',
  'Time',
  'Coupon Activated',
  'Action'
];

export const PRIORITY_POINTS_TABLES = [
  'Sr. No',
  'User',
  'Timing',
  'Gender',
  'Last Satisfaction',
  'Starting Point',
  'Timing Point',
  'Bachelor Coupon Point',
  'Plan Point',
  'Flex Point',
  'Review Point x Review Multiple',
  'Last Satisfaction Converted',
  'Priority Points'
];

export const USER_LIST_TABLES = [
  'Sr. No',
  'User ID',
  'Name',
  'Gender',
  'Participate Date',
  'Coupon Active',
  'Action'
];

export const PARTICIPANT_LIST_TABLE = [
  'User ID',
  'Name',
  'Gender',
  'Participated Date',
  'Coupon',
  'Matching Type',
  'Action'
];

export const JOB_PREFERENCE_MAPPING = [
  'ID',
  'Preference Job',
  'Job',
  'Gender',
  'Action',
]

export const TEXT_ALIGN_DATA = [
  { value: 'left', label: 'Left' },
  { value: 'center', label: 'Center' },
  { value: 'right', label: 'Right' }
];

export const NOTIFICATION_SETTING_TABLES = [
  'Sr. No',
  'Label',
  'Group Key',
  'Male Message',
  'Female Message',
  'Status',
  'Created Date',
  'Updated Date',
  'Action'
];

export const NOTIFICATION_TYPE = {
  Email: 'email',
  Sms: 'sms',
  Push: 'push',
  SecondPush: 'second_push'
};

export const SMS_NOTIFICATION_TABLES = [
  'Sr. No',
  'Label',
  'Group Key',
  'Male Message',
  'Female Message',
  'Unread Time (Minutes)',
  'Enabled',
  'Updated Date',
  'Action'
];

export const CAFES_TABLES = [
  'ID',
  'Name',
  'Area Name',
  'Train Station Name',
  'Status',
  'Action'
];

export const COST_PLAN_TABLES = [
  { value: 'L', label: 'Light' },
  { value: 'N', label: 'Normal' },
  { value: 'P', label: 'Premium' }
];

export const EDUCATION_GROUP_DATA_TABLES = [
  { value: '1', label: 'High school' },
  { value: '2', label: 'Associate Diploma' },
  { value: '3', label: 'Less famous uni' },
  { value: '4', label: 'Medium standard uni' },
  { value: '5', label: 'Famous uni' },
  { value: '6', label: 'More famous uni' }
];

export const EDUCATION_GROUP_SELECTED_DATA_TABLES = [
  { value: 'true', label: 'Is Selectable' },
  { value: 'false', label: 'Unselectable' }
];

export const PAIR_LIST_USER_GROUP_SELECTED = [
  { value: '2', label: 'All' },
  { value: '0', label: 'Real' },
  { value: '1', label: 'Fake' }
];

export const CAFE_TYPE_TABLES = [
  { value: 'cafe', label: 'Cafe' },
  { value: 'park', label: 'Park' }
];

export const TRAIN_STATIONS_TABLES = ['ID', 'Name', 'Area', 'Action'];

export const PREFECTURES_TABLES = [
  'Sr. No',
  'Prefecture ID',
  'Name (EN)',
  'Status',
  'Action'
];

export const PREFECTURE = {
  TOKYO: 1,
  KANSAI: 2,
  NAGOYA: 3,
  FUKUOKA: 5,
  HOKKAIDO: 6,
  SHIZUOKA: 7,
  MIYAGI: 9
};

export const MATCH_PROFILE_SETTINGS_TABLES = [
  'Sr. No',
  'Page',
  'Gender',
  'Description',
  'Image',
  'Action'
];
// ['Sr. No','Page','Gender','Age from','Age to','Short URL','Description','Image','Action'];

export const AREA_TABLES = [
  'Sr. No',
  'Area',
  'Prefectures',
  'Status',
  'Action'
];
export const USERS_TABLES = [
  'Id',
  'Name',
  'Gender',
  'Email',
  'Phone',
  'Status',
  'Action'
];

export const LOCATION_AREA_TABLES = [
  'Sr. No',
  'Name',
  'Image',
  'Display Address',
  'City',
  'ZipCode',
  'Rating',
  'Created Date',
  'Updated Date',
  'Status',
  'Action'
];

export const MATCHING_DATE = [
  { value: 'all', label: 'All' },
  { value: 'sat', label: 'Saturday' },
  { value: 'sun', label: 'Sunday' }
];

export const TIME_TABLES = [
  { value: '15:00', label: '15:00' },
  { value: '15:30', label: '15:30' },
  { value: '16:00', label: '16:00' },
  { value: '16:30', label: '16:30' },
  { value: '17:00', label: '17:00' },
  { value: '17:30', label: '17:30' },
  { value: '18:00', label: '18:00' },
  { value: '18:30', label: '18:30' },
  { value: '19:00', label: '19:00' }
];

export const DAY_OF_WEEK = [
  { value: 'monday', label: 'Monday' },
  { value: 'tuesday', label: 'Tuesday' },
  { value: 'wednesday', label: 'Wednesday' },
  { value: 'thursday', label: 'Thursday' },
  { value: 'friday', label: 'Friday' },
  { value: 'saturday', label: 'Saturday' },
  { value: 'sunday', label: 'Sunday' }
];

export const SUBSCRIPTION_STATUS_TABLES = [
  { value: 'pending', label: 'Pending' },
  { value: 'paid', label: 'Paid' },
  { value: 'cancelled', label: 'Cancelled' },
  { value: 'expired', label: 'Expired' }
];

export const TARGET_GROUPS = [
  {
    value: '0',
    label: 'Users who participated and has active dating last week'
  },
  { value: 1, label: 'Users who have not participated for this week' },
  { value: 2, label: 'Users who are matched on first matching' },
  {
    value: 3,
    label: "Female participants who aren't matched on first matching"
  },
  { value: 4, label: "Male users who didn't participate on the week" },
  { value: 5, label: 'Users who have active dating on 1st dating day' },
  { value: 6, label: 'Users who have active dating on 2nd dating day' },
  { value: 7, label: 'Users who are re-matched on 1st dating day' },
  { value: 8, label: 'Users who are re-matched on 2nd dating day' },
  { value: 9, label: "User who aren't re-matched and are in trial" },
  { value: 10, label: "User who aren't re-matched and is paid user" },
  {
    value: 11,
    label: 'Users who are canceled after rematching and are in trial'
  },
  {
    value: 12,
    label: 'Users who are canceled after rematching and are paid user'
  },
  { value: 13, label: 'Male users matching first time.' },
  {
    value: 14,
    label:
      "Male users who didn't participate on the week and is in last week trial."
  },
  { value: 15, label: "Male user who didn't enter credit card form." }
];

export const STRIPE_STATUS_TABLES = [
  { value: 'trialing', label: 'trialing' },
  { value: 'active', label: 'active' },
  { value: 'incomplete', label: 'incomplete' },
  { value: 'incomplete_expired', label: 'incomplete_expired' },
  { value: 'past_due', label: 'past_due' },
  { value: 'cancelled', label: 'cancelled' },
  { value: 'unpaid', label: 'unpaid' }
];

export const PARTICIPATION_LOG_TABLES = [
  'Id',
  'Match Type',
  'Male Participants',
  'Female Participants',
  'Total Participants',
  'Dating Pairs',
  'Time Of Matching',
  'Week Start',
  'Week End'
];
export const DATING_DAY_SETTINGS_TABLES = ['Sr. No', 'Date', 'Day', 'Action'];

export const MATCHING_SETTINGS_TABLES = ['Sr. No', 'Key', 'Value', 'Action'];

export const SELECT_PARTNER_TABLES = [
  'Sr. No',
  'ID',
  'Name',
  'Gender',
  'Actions'
];

export const EDUCATION_GROUP_TABLES = [
  'School ID',
  'School Name',
  'Selectable',
  'Education Group'
];

export const STRIPE_PLANS_TABLES = [
  'ID',
  'Name',
  'Nickname',
  'Third Party Plan ID',
  'Discount Type',
  'Contract Term',
  'Cost plan',
  'Final Amount',
  'Updated At'
];

export const SUBSCRIPTIONS_TABLES = [
  'ID',
  'User',
  'Plan',
  'Amount',
  'Stripe Sub',
  'Stripe',
  'Invoice',
  'Status',
  'Trial Start',
  'Trial End',
  'Start Date',
  'End Date'
];

export const MISSING_PARTNER_TABLES = [
  'Sr. No',
  'ID',
  'Name',
  'Gender',
  'Paid / Free',
  'Area',
  'Timing',
  'Participate Date',
  'Unmatched Date',
  'Coupon Activated',
  'Action'
];

export const MIGRATION_PAIR_TABLES = [
  'Pair ID',
  'Male ID',
  'Female ID',
  'Male name',
  'Female name',
  'Dating day',
  'Dating date',
  'Dating time',
  'Dating cafe',
  'Cancel person',
  'Import status'
];

export const OVERALL_POINTS_TABLES = [
  'User ID',
  'Name',
  'Gender',
  'Age',
  'Height',
  'Body Type',
  'Marriage Willingness',
  'Hobby',
  'Character',
  'Face Strength',
  'Face Features',
  'Smoking',
  'Alcohol',
  'Divorce History',
  'Expected Job',
  'Annual Income',
  'Education Group',
  'School Name',
  'Preferred Age Min',
  'Preferred Age Max',
  'Preferred Height Min',
  'Preferred Height Max',
  'Preferred Body Type',
  'Smoking Preference',
  'Drinking Preference',
  'Divorce Preference',
  'Preferred Annual Income',
  'Minimum Education',
  'Preferred Job',
  'Importance Of Looks',
  'Preferred Face Type',
  'Hobby Preference',
  'First Priority',
  'Second Priority',
  'Third Priority',
  'Face Image',
  'Face Starting Point',
  'Personality Starting Point',
  'Specific Addon',
  'Starting Point',
  'Face Type Match Addition',
  'Male First Time Female Reduction',
  'Age (Male MR)',
  'Height (Male MR)',
  'Body Type (Male MR)',
  'Educational Background (Male MR)',
  'Alcohol (Male MR)',
  'Divorce (Male MR)',
  'Job (Male MR)',
  'Hobby (Male MR)',
  'Age (Female MR)',
  'Height (Female MR)',
  'Body Type (Female MR)',
  'Annual Income (Female MR)',
  'Educational Background (Female MR)',
  'Smoking (Female MR)',
  'Alcohol (Female MR)',
  'Divorce (Female MR)',
  'Job (Female MR)',
  'Hobby (Female MR)',
  "Male's Face Evaluation (Female MR)",
  'Exclusion (Male)',
  'Exclusion (Female)',
  'Other Exclusions',
  'Dating Use Count',
  'Preferred Day',
  'Overall Point'
];

export const SYSTEM_LOG_TABLES = ['Sr. No', 'Message', 'Status', 'Times Stamp'];

export const BULK_MESSAGE_SUCCESS_TABLES = [
  'User Id',
  'Title',
  'Message',
  'Redirect To'
];

export const BULK_MESSAGE_FAIL_TABLES = [
  'User Id',
  'Title',
  'Message',
  'Redirect To',
  'Error detail'
];

export const ADMIN_MANAGEMENT_TABLES = {
  id: 'ID',
  role: 'Role',
  name: 'Name',
  email: 'Email',
  referredBy: 'Referred By',
  createdAt: 'Created At',
  action: 'Action'
};

export const PAGES = [
  { value: '10', label: 10 },
  { value: '50', label: 50 },
  { value: '100', label: 100 }
];

export const HttpCode = {
  SUCEESS: 200,
  UNAUTHORIZED: 401
};

export const StorageKey = {
  SOCIAL_ID: '5LOs0kIiq6M5upU',
  LOGIN_TYPE: 'KyDey0IBFZdG6PL',
  LOGIN_MIGRATING: 'uEvxKOFyfpVVxPF',
  REOPEN: 'REOPEN'
};

export const LoginType = {
  MOBILE: 'MOBILE',
  LINE: 'LINE',
  FACEBOOK: 'FACEBOOK'
};

export const TOAST_TYPE = {
  SUCCESS: 'success',
  ERROR: 'error',
  WARNING: 'warning',
  INFO: 'info'
};

export const ResponseStatus = {
  FAILURE: 0,
  SUCCESS: 200,
  // SUCCESS: 1,
  FORCEFULLY: 2,
  NEW_USER: 3,
  DEACTIVE_USER: 4,
  PROFILE_AWAITING_USER: 5,
  INCOMPLETE_PROFILE_USER: 6,
  DOCCUMENT_REQUIRED: 7,
  CANCEL_USER: 8,
  TEMPORARY_CANCEL: 9,
  PERMANENT_CANCEL: 10,
  LOCK_USER: 11,
  VALIDATION_ERROR: 101,
  FEEDBACK_VIEW: 301,
  COUPON_VALIDATION_UNPARTICIPATION: 12,
  FEEDBACK_NOT_GIVEN: 13,
  PROFILE_MISSING_EMAIL: 14
};

export const ResponseMessage = {
  SUCCESS: 'Successful'
};

export const TrialStatus = {
  Active: { value: 10, color: 'info' },
  TempCancelled: { value: 20, color: 'danger' },
  Completed: { value: 30, color: 'success' }
};

export const UserPlanStatus = {
  Scheduled: { value: 10, color: 'info' },
  ScheduleCancelled: { value: 19, color: 'danger' },
  Active: { value: 20, color: 'success' },
  Inactive: { value: 99, color: 'warning' }
};

export const UserStatus = {
  Incomplete: { value: 1, color: 'secondary' },
  Awaiting: { value: 2, color: 'info' },
  Approved: { value: 4, color: 'success' },
  Deactivated: { value: 5, color: 'warning' },
  Cancelled: { value: 6, color: 'danger' }
};

export const SubscriptionStatus = {
  Active: { value: 'active', color: 'success' },
  Canceled: { value: 'canceled', color: 'danger' },
  Incomplete: { value: 'incomplete', color: 'info' },
  IncompleteExpired: { value: 'incomplete_expired', color: 'warning' },
  PastDue: { value: 'past_due', color: 'secondary' },
  Unpaid: { value: 'unpaid', color: 'dark' }
};

export const InvoiceStatus = {
  Draft: { value: 1, color: 'secondary' },
  Open: { value: 2, color: 'info' },
  Paid: { value: 3, color: 'success' },
  Uncollectible: { value: 4, color: 'danger' },
  Void: { value: 5, color: 'warning' }
};

export const PreferencesComplete = {
  LAST_STEP: 6
};

export const ParticipationStatus = {
  MATCH: 3,
  ALGORYTHAM: 2
};

export const AdminRole = {
  Admin: 'admin',
  Member: 'member'
};

export const AsyncKeys = {
  USER_DATA: 'user_data',
  USER_ID: 'USER_ID',
  USER_NAME: 'USER_NAME',
  USER_EMAIL: 'USER_EMAIL',
  USER_PIC: 'USER_PIC',
  USER_PREFRENCE: 'USER_PREFRENCE',
  USER_ACCESS_TOKEN: 'USER_ACCESS_TOKEN',
  USER_REFRESH_TOKEN: 'USER_REFRESH_TOKEN',
  USER_GENDER: 'USER_GENDER',
  USER_LANGUAGE: 'USER_LANGUAGE',
  USER_PROFILE_IMAGE: 'USER_ROFILE_IMAGE',
  MATCH_PROFILE_DATA: 'MATCH_PROFILE_DATA',
  POPOUP_GLOBAL: 'POPOUP_GLOBAL',
  INTRO_SCREEN: 'INTRO_SCREEN',
  FIRST_TIME_CHAT_SATURDAY: 'FIRST_TIME_CHAT_SATURDAY',
  FIRST_TIME_CHAT_SUNDAY: 'FIRST_TIME_CHAT_SUNDAY',
  USER_SOCIAL_LOGIN_URL: 'USER_SOCIAL_LOGIN_URL',
  COUPON_COUNT: 'COUPON_COUNT',
  COUPON_EXPIRE_AT: 'COUPON_EXPIRE_AT'
};

export const WeekPlan = {
  WEEKDAY: 'weekday',
  WEEKEND: 'weekend'
};

export const CostPlan = {
  LIGHT: 'light',
  NORMAL: 'normal',
  PREMIUM: 'premium'
};

export const UserPlan = {
  FREE: 'Free',
  PAID: 'Paid'
};

export const DatingEvent = {
  CANCELLED: 1,
  LOCATION_CHANGED: 2
};

export const ExchangeCoupon = {
  NUMBER_BOTH_DATE_EXCHANGE: 3
};

export const CouponStatus = {
  READY_TO_USE_COUPON: 0,
  COUPON_NOT_AVAILABLE: 1,
  COUPON_IN_USE: 2,
  BACHELOR_READY_TO_USE_COUPON: 0,
  BACHELOR_COUPON_NOT_AVAILABLE: 1,
  BACHELOR_COUPON_IN_USE: 2
};

export const CouponType = {
  TYPE_BOTH_DATE_COUPON: 'both_day_dating_coupon',
  TYPE_BACHELOR_COUPON: 'bachelor_coupon',
  TYPE_FEMALE_COUPON: 'female_coupon',
  TYPE_DATING: 'dating'
};

export const UserGender = {
  MALE: 1,
  FEMALE: 2
};

export const userCouponStatus = {
  Used: { value: 1, color: 'success' },
  Unused: { value: 2, color: 'secondary' },
  Exchanged: { value: 4, color: 'info' },
  Expired: { value: 5, color: 'danger' }
};

export const FeedbackPointType = {
  BAD: 1,
  GOOD: 2
};

export const FeedbackType = {
  FACE: '容姿/雰囲気',
  PERSONANLITY: '性格・会話',
  BEHAVIOR: '振る舞い'
};

export const MatchDetails = {
  STATUS_NO_ANY_DATE: 0,
  STATUS_DATING_MATCHED_PENDING: 1,
  STATUS_DATING_MATCHED_CONFIRM: 2,
  STATUS_DATING_MATCHED_CANCELLED: 3,
  STATUS_DATING_MATCHED_COMPLETED: 4
};

export const RequestChangeTime = {
  STATUS_REQUEST_TIME_PENDING_DELAY: 0,
  STATUS_REQUEST_TIME_PENDING: 1,
  STATUS_REQUEST_TIME_CANCEL: 2,
  STATUS_REQUEST_TIME_REJECT: 3,
  STATUS_REQUEST_TIME_APPROVED: 4
};

export const MatchProfile = {
  POPUP_PARTNER_NOT_FOUND: 1,
  POPUP_HOW_TO_BE_MATCHED: 2,
  POPUP_DATE_DECIDED: 3,
  POPUP_PARTNER_CANCELLED: 4,
  POPUP_PARTNER_CHANGED_LOCATION: 5
};

export const GlobalPopup = {
  CHANGE_COST_PLAN: 6,
  CHANGE_COST_PLAN_DOWNGRADE: 7,
  CHANGE_COST_PLAN_UPGRADE: 8,
  COMPLETE_UPDATE_PROFILE: 9
};

export const CancelDateByMeOption = {
  // Constants for cancel option in cancelled date by me api
  INFORMED_PARTNER: 1,
  DID_NOT_SHOW_UP_PARTNER: 2
};

export const CancelDateByPartnerOption = {
  INFORMED_ME: 1,
  DID_NOT_SHOW_UP: 2
};

export const ChatFeature = {
  USER_TYPE_BASE_USER: 'baseUser',
  USER_TYPE_PARTNER_USER: 'partnerUser',
  USER_TYPE_CAFE_INFO: 'cafeInfo',
  USER_TYPE_CAFE_SUGGESTION: 'cafeSuggestion',
  USER_TYPE_PARK_SUGGESTION: 'parkSuggestion',
  USER_TYPE_VIDEO_SUGGESTION: 'videoSuggestion',
  USER_TYPE_CAFE_SUGGESTION_LINK: 'cafeSuggestionLink',
  USER_TYPE_VIDEO_SUGGESTION_LINK: 'videoSuggestionLink',
  LOCATION_CATEGORY_CAFE: 'cafe',
  LOCATION_CATEGORY_PARK: 'park'
};

export const ViewsOnLove = [
  { key: 1, title: '付き合うまでに会いたい回数' },
  { key: 2, title: '連絡頻度' },
  { key: 3, title: '会う頻度' }
];

export const ViewsOnMarriage = [
  { key: 1, title: '子供について' },
  { key: 2, title: '家事・育児' },
  { key: 3, title: '仕事' }
];

export const redirectFB = () => {
  window.location.replace(API.BASE_URL + 'social-login?redirect_url=app-login');
};
export const redirectReopenForm = () => {
  window.location.replace(API.BASE_URL + 'reopen-form');
};
export const redirectSmsLogin = nextAction => {
  window.location.replace(
    API.BASE_URL +
      `sms-portal?redirect_url=app-login${
        nextAction ? '&action=' + nextAction : ''
      }`
  );
};
export const redirectUserProfile = () => {
  window.location.replace(API.BASE_URL + 'user-profile');
};
export const redirectUserProfileStep2 = () => {
  window.location.replace(API.BASE_URL + 'user-profile?edit=1&step=2');
};
export const redirectLINELogin = nextAction => {
  window.location.replace(
    API.BASE_URL +
      `social-login?redirect_url=app-line-login${
        nextAction ? '&action=' + nextAction : ''
      }`
  );
};
export const redirectFacebookLogin = nextAction => {
  window.location.replace(
    API.BASE_URL +
      `social-login?redirect_url=app-login${
        nextAction ? '&action=' + nextAction : ''
      }`
  );
};
export const redirectLINE = (socialLoginLink, userId) => {
  window.location.replace(
    `${socialLoginLink}&id=${userId}&redirect_url=${encodeURIComponent(
      API.BASE_URL + 'social-login?redirect_url=line-login'
    )}`
  );
};

export const redirectUnderScreeningMaleUser = () => {
  window.location.replace('https://wi.bachelorapp.net/usc-male');
};

export const redirectSMS = (userId, email) => {
  window.location.replace(
    `https://bachelorapp.zendesk.com/hc/ja/requests/new?ticket_form_id=900000026183&uref=${userId}&email=${email}`
  );
};

export const redirectNewSMS = (userId, language_id) => {
  window.location.replace(
    `${API.BASE_URL}phoneNumber?uref=${userId}&language_id=${language_id}`
  );
};

export const redirectUnderScreeningFemaleUser = () => {
  window.location.replace('https://wi.bachelorapp.net/usc-female');
};

export const redirectSuspendCannotConfirmMale = (userId, email) => {
  window.location.replace(
    `https://bachelorapp.zendesk.com/hc/ja/articles/900000527003?uref=${userId}&email=${email}`
  );
};

export const redirectSuspendCannotConfirmFeMale = (userId, email) => {
  window.location.replace(
    `https://bachelorapp.zendesk.com/hc/ja/articles/900000534246?uref=${userId}&email=${email}`
  );
};

export const redirectSuspendMale = (userId, email) => {
  window.location.replace(
    `https://bachelorapp.zendesk.com/hc/ja/articles/900000514483?uref=${userId}&email=${email}`
  );
};

export const redirectSuspendFeMale = (userId, email) => {
  window.location.replace(
    `https://bachelorapp.zendesk.com/hc/ja/articles/900000518406?uref=${userId}&email=${email}`
  );
};

export const redirectSuspendCancelMale = (userId, email) => {
  window.location.replace(
    `https://bachelorapp.zendesk.com/hc/ja/articles/360033268632-%E4%BC%91%E4%BC%9A%E3%81%AB%E3%81%A4%E3%81%84%E3%81%A6?uref=${userId}&email=${email}`
  );
};

export const redirectSuspendCancelFeMale = (userId, email) => {
  window.location.replace(
    `https://bachelorapp.zendesk.com/hc/ja/articles/360033641111-%E4%BC%91%E4%BC%9A%E3%81%AB%E3%81%A4%E3%81%84%E3%81%A6?uref=${userId}&email=${email}`
  );
};

export const redirectVideoDateSuggestion = (userId, email) => {
  window.open(
    `https://bachelorapp.zendesk.com/hc/ja/articles/900001661606?uref=${userId}&email=${email}`
  );
};

export const reopenValue = {
  TRIAL_MALE: 'trial_male',
  AWAITING_FOR_SCREENING_MALE: 'awaiting_for_screening_male',
  AWAITING_FOR_SCREENING_FEMALE: 'awaiting_for_screening_female'
};

export const ROUTE_PATH = {
  CAFE: '/cafe',
  MANAGE_CAFE: '/manage-cafe'
};

export const TITLE_PARTICIPANT_COUNT = {
  Recommend: 'Recommend',
  AwaitingCancel: 'Awaiting cancel'
};

export const SYSTEM_LOG_STATUS = {
  ERROR: 'ERROR',
  EMERGENCY: 'EMERGENCY',
  CRITICAL: 'CRITICAL',
  ALERT: 'ALERT',
  WARNING: 'WARNING',
  NOTICE: 'NOTICE',
  DEBUG: 'DEBUG'
};

export const VALIDATION_MSG = {
  REQUIRED: 'This field is required',
  NUMBER_TYPE: 'Value must be number',
  INVALID_PREFECTURE: 'this prefecture is invalid',
  INVALID_EMAIL: 'Invalid email address',
  ROLE_UNSELECTED: 'Role must be selected',
  INVALID_PASSWORD: 'Invalid Password',
  UNMATCH_PASSWORD: 'Password must match',
  INVALID_URL: 'Invalid URL'
};

export const CAFE_STATE = {
  Open: 1,
  Close: 0
};

export const CAFE_PLACE_PAGE = {
  Create: 'Create',
  Update: 'Update'
};

export const PARTICIPANT_REMATCH = 'rematch';
export const PARTICIPANT_MAIN_MATCH = 'main-match';

export const BATCH_QUEUE_IMPORT_DETAIL_STATUS = {
  success: 1,
  fail: 2
};

export const FEEDBACK_FLEX_QUESTION_LIST_TABLE = [
  'Id',
  'Content',
  'Order',
  'Gender show',
  'Required',
  'Status',
  'Prefectures',
  'Action'
];

export const FEEDBACK_FLEX_QUESTION_AND_OPTION_STATUS = {
  ACTIVE: 10,
  IN_ACTIVE: 20
};

export const FEEDBACK_FLEX_QUESTION_TYPE = {
  CHECKBOX: 'checkbox',
  RADIO: 'radio',
  TEXT_BOX: 'text_box'
};

export const FEEDBACK_FLEX_QUESTION_FOR_GENDER = {
  BOTH: 0,
  MALE: 1,
  FEMALE: 2
};

export const FEEDBACK_FLEX_QUESTION_CATEGORY = {
  AI_LEARNING: 0,
  DATING_QUESTIONNAIRE: 1
};

export const CERTIFICATION_TABLES = [
  'ID',
  'Name',
  'Gender',
  'Status',
  'Birthday',
  'Annual Income',
  'Type',
  'Image',
  'Action'
];

export const USER_CERTIFICATION_TYPE = {
  1: 'Annual Income',
  2: 'Unmarried'
};

export const USER_CERTIFICATION_POPUP_ACTION = {
  APPROVE: 'APPROVE',
  REJECT: 'REJECT'
};

export const AUTO_CHECK_NOTIFICATION_TABLES = [
  'Sr. No',
  'Notification Type',
  'Notification Key',
  'Day of Week',
  'Time',
  'Actions'
];

export const REGISTRATION_STEPS = {
  StepZero: 0,
  StepOne: 1,
  StepOnePointFive: 1.5,
  StepTwo: 2,
  StepThree: 3,
  StepFour: 4,
  StepFive: 5,
  StepSix: 6,
  StepSeven: 7,
  StepEight: 8,
  StepNine: 9,
  StepTen: 10,
  StepEleven: 11,
  StepTwelve: 12,
  StepThirteenth: 13,
  StepOneThirtyFive: 13.5,
  StepFourteenth: 14,
  StepFifteenth: 15,
  StepSixteenth: 16
};

export const PREFECTURE_SETTINGS_STATUS = {
  OPEN: 1,
  CLOSE: 0
};

export const STATUS_OF_APPROVAL = {
  Approved: 'approved',
  Unapproved: 'unapproved'
};

export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 250,
      width: 250
    }
  }
};

export const COMMON_VARIABLES_TABLES = [
  'ID',
  'Key',
  'Contents',
  'Status',
  'Prefecture Option',
  'Prefectures',
  'Updated',
  'Action'
];

export const CommonVariableStatusOptions = [
  { label: 'Active', value: 1 },
  { label: 'Inactive', value: 0 }
];

export const CommonVariableStatus = {
  Active: 1,
  Inactive: 0
};

export const CommonVariablePrefectureOption = {
  Active: 1,
  Inactive: 0
};

export const DatingStatus = {
  Incompleted: { value: 1, color: 'danger' },
  Cancelled: { value: 2, color: 'warning' },
  Completed: { value: 3, color: 'success' }
};

export const ParticipantsStatus = {
  Awaiting: { value: 10, color: 'info' },
  Unmatched: { value: 20, color: 'secondary' },
  Matched: { value: 30, color: 'success' },
  Cancelled: { value: 40, color: 'warning' },
  Expired: { value: 50, color: 'danger' },
  Irregular: { value: 60, color: 'dark' }
};

export const NotificationStatus = {
  Processing: { value: 1, color: 'info' },
  Success: { value: 2, color: 'success' },
  Fail: { value: 3, color: 'danger' }
};

export const StripeUrl = {
  customerBaseUrl:
    window.__env__.ENVIRONMENT == 'production'
      ? 'https://dashboard.stripe.com/customers/'
      : 'https://dashboard.stripe.com/test/customers/',
  subscriptionBaseUrl:
    window.__env__.ENVIRONMENT == 'production'
      ? 'https://dashboard.stripe.com/subscriptions/'
      : 'https://dashboard.stripe.com/test/subscriptions/',
  invoiceBaseUrl:
    window.__env__.ENVIRONMENT == 'production'
      ? 'https://dashboard.stripe.com/invoices/'
      : 'https://dashboard.stripe.com/test/invoices/'
};

export const ReasonForCancellation = {
  BadHealthCondition: 10,
  HavingUrgentBusiness: 20,
  DissatisfactionToPartnersAge: 30,
  DissatisfactionToPartnersIncome: 31,
  DissatisfactionToPartnersJob: 32,
  DissatisfactionToPartnersHeight: 33,
  DissatisfactionToPartnersBodyShape: 34,
  DissatisfactionToPartnersEducation: 35,
  DissatisfactionToPartnersManner: 36,
  DissatisfactionToPartnersBRate: 37,
  DissatisfactionToPartnerOther: 39,
  ForCovidPrevention: 40,
  LateOrNoShow: 50,
  NoReturnMessage: 60,
  HaveCovid: 70,
  Other: 99
};

export const MenuItems = {
  Dashboard: {
    label: 'Dashboard',
    link: '/dashboard',
    iconClass: 'nav-icon fas fa-tachometer-alt',
    adminOnly: false,
    subMenu: {}
  },
  UserManagement: {
    label: 'Manage Users',
    link: '/manage-users',
    iconClass: 'nav-icon fas fa-user-friends',
    adminOnly: false,
    subMenu: {}
  },
  MatchingAlgorithm: {
    label: 'Matching Algorithm',
    link: '#',
    iconClass: 'nav-icon fa fa-exchange-alt',
    adminOnly: false,
    subMenu: {
      ParticipantList: {
        label: 'Participant List',
        link: '/matching/participate-user-list',
        adminOnly: false
      },
      MatchedUserPairList: {
        label: 'Matched User Pair List',
        link: '/matching/matching-pair-users',
        adminOnly: false
      },
      JobPreferenceMapping: {
        label: 'Job Preference Mapping',
        link: '/matching/job-preference-mapping',
        adminOnly: false
      }
    }
    
  },
  DatingDay: {
    label: 'Dating Day',
    link: '#',
    iconClass: 'nav-icon fas fa-calendar',
    adminOnly: false,
    subMenu: {
      DatingDaySettings: {
        label: 'Dating Day Settings',
        link: '/dating-day-settings',
        adminOnly: false
      },
      PrefectureSettings: {
        label: 'Prefecture Settings',
        link: '/prefecture-settings',
        adminOnly: false
      }
    }
  },
  DatingPlace: {
    label: 'Dating Place',
    link: '#',
    iconClass: 'nav-icon fas fa-coffee',
    adminOnly: false,
    subMenu: {
      ManageDatingPlace: {
        label: 'Manage Dating Place',
        link: '/manage-cafe',
        adminOnly: false
      }
    }
  },
  TrainStation: {
    label: 'Train Station',
    link: '/train-stations',
    iconClass: 'nav-icon fa fa-train',
    adminOnly: false,
    subMenu: {}
  },
  PrefecturesAreas: {
    label: 'Prefectures & Areas',
    link: '#',
    iconClass: 'nav-icon fa fa-map-marker',
    adminOnly: false,
    subMenu: {
      Prefectures: {
        label: 'Prefectures',
        link: '/prefectures',
        adminOnly: false
      },
      Area: {
        label: 'Area',
        link: '/areas',
        adminOnly: false
      }
    }
  },
  SchoolManagement: {
    label: 'School Management',
    link: '/education-group',
    iconClass: 'nav-icon fa fa-school',
    adminOnly: false,
    subMenu: {}
  },
  Migrations: {
    label: 'Migrations',
    link: '#',
    iconClass: 'nav-icon fa fa-upload',
    adminOnly: false,
    subMenu: {
      Pair: {
        label: 'Pair',
        link: '/migrations/pair',
        adminOnly: false
      }
    }
  },
  PlanSubscriptions: {
    label: 'Plan & Subscriptions',
    link: '#',
    iconClass: 'nav-icon fa fa-upload',
    adminOnly: false,
    subMenu: {
      Plan: {
        label: 'Plan',
        link: '/plan',
        adminOnly: false
      }
    }
  },
  Notifications: {
    label: 'Notifications',
    link: '#',
    iconClass: 'nav-icon fa fa-send-o',
    adminOnly: false,
    subMenu: {
      Email: {
        label: 'Email Notifications',
        link: '/notifications',
        adminOnly: false
      },
      SMS: {
        label: 'SMS Notifications',
        link: '/sms-notifications',
        adminOnly: false
      },
      Push: {
        label: 'Push Notifications',
        link: '/push-notifications',
        adminOnly: false
      },
      SecondPush: {
        label: 'Second Push Notifications',
        link: '/second-push-notifications',
        adminOnly: false
      },
      SendBulkEmail: {
        label: 'Send Bulk Email Notifications',
        link: '/send-bulk-email-notifications',
        adminOnly: true
      },
      SendBulkPush: {
        label: 'Send Bulk Push Notifications',
        link: '/send-bulk-push-notifications',
        adminOnly: true
      },
      SkipEmail: {
        label: 'Skip Email Notifications',
        link: '/skip-notifications-for-users',
        adminOnly: true
      },
      SkipSMS: {
        label: 'Skip SMS Notifications',
        link: '/skip-sms-notifications-for-users',
        adminOnly: true
      },
      SkipPush: {
        label: 'Skip Push Notifications',
        link: '/skip-push-notifications-for-users',
        adminOnly: true
      },
      AutoCheckSettings: {
        label: 'Auto Check Settings',
        link: '/auto-check-settings',
        adminOnly: true
      }
    }
  },
  CommonVariables: {
    label: 'Common Variables',
    link: '/common-variables',
    iconClass: 'nav-icon fa fa-send-o',
    adminOnly: false,
    subMenu: {}
  },
  ChatHistory: {
    label: 'Chat History',
    link: '/chat-history',
    iconClass: 'nav-icon fa fa-send-o',
    adminOnly: false,
    subMenu: {}
  },
  Certification: {
    label: 'Certification',
    link: '/certification',
    iconClass: 'nav-icon fa fa-certificate',
    adminOnly: true,
    subMenu: {}
  },
  CountParticipant: {
    label: 'Count Participant',
    link: '/count-participant',
    iconClass: 'nav-icon fa fa-send-o',
    adminOnly: true,
    subMenu: {}
  },
  CountParticipantBlack: {
    label: 'Count Participant(Black)',
    link: '/count-participant-black',
    iconClass: 'nav-icon fa fa-send-o',
    adminOnly: true,
    subMenu: {}
  },
  ExtendParticipation: {
    label: 'Extend Participation', link: '/extend-participation', iconClass: "nav-icon fa fa-send-o", adminOnly: true, subMenu: {}
  },
  AdminManagement: {
    label: 'Admin Management',
    link: '/admin-management',
    iconClass: 'nav-icon fa fa-user',
    adminOnly: true,
    subMenu: {}
  },
  Feedbacks: {
    label: 'Feedbacks',
    link: '#',
    iconClass: 'nav-icon fa fa-commenting-o',
    adminOnly: true,
    subMenu: {
      FlexibleQuestions: {
        label: 'Flexible Questions',
        link: '/feedback-flexible-questions',
        adminOnly: true
      }
    }
  },
  OtherSettings: {
    label: 'Other Settings',
    link: '#',
    iconClass: 'nav-icon fa fa-cogs',
    adminOnly: true,
    subMenu: {
      DatingDaySettings: {
        label: 'Dating Day Settings',
        link: '/dating-day-settings',
        adminOnly: true
      },
      MatchInfoSettings: {
        label: 'Match Info Settings',
        link: '/match-info-settings',
        adminOnly: true
      }
    }
  },
  DownloadCSV: {
    label: 'Download CSV',
    link: '/download-csv',
    iconClass: 'nav-icon fa fa-download',
    adminOnly: true,
    subMenu: {}
  },
  ManageBanner: {
    label: 'Banners management',
    link: '/manage-banner',
    iconClass: 'nav-icon far fa-flag',
    adminOnly: true,
    subMenu: {}
  }
};

export const commonVariableIntOptions = {
  status: {
    [CommonVariableStatus.Inactive]: { text: 'Inactive', color: 'danger' },
    [CommonVariableStatus.Active]: { text: 'Active', color: 'success' }
  },
  prefecture_option: {
    [CommonVariablePrefectureOption.Inactive]: {
      text: 'Inactive',
      color: 'danger'
    },
    [CommonVariablePrefectureOption.Active]: {
      text: 'Active',
      color: 'success'
    }
  }
};

export const IsBan = {
  BANNED: 1,
  ALLOWED: 0
};

export const manageBannerHeader = [
  'ID',
  'Title',
  'Image',
  'Order',
  'Gender show',
  '2nd-form',
  'User_status',
  'Device',
  'Status',
  'URL',
  'Prefectures',
  'Rank',
  'Plan',
  'User_id',
  'Job',
  'Action'
];

export const manageBannerDeviceOptions = [
  {
    name: 'All',
    value: '0'
  },
  {
    name: 'IOS',
    value: '1'
  },
  {
    name: 'Android',
    value: '2'
  },
  {
    name: 'Web',
    value: '3'
  }
];

export const manageBannerGenderOptions = [
  {
    name: 'Male',
    value: UserGender.MALE,
    color: 'skyblue'
  },
  {
    name: 'Female',
    value: UserGender.FEMALE,
    color: 'pink'
  }
];

export const manageBannerUserStatusOptions = [
  {
    name: 'Approved',
    value: UserStatus.Approved.value.toString()
  },
  {
    name: 'Deactivated',
    value: UserStatus.Deactivated.value.toString()
  }
];

export const manageBannerInitialFormValues = {
  id: null,
  title: '',
  image: '',
  order: '',
  hasCompleteSecondForm: false,
  device: [],
  condition: [],
  status: true,
  url: '',
  prefecture: [],
  plan: [],
  rank: [],
  jobCsvFile: null,
  userIdCsvFile: null,
  gender: [UserGender.MALE, UserGender.FEMALE]
};
export const PlanValue = {
  ALL: 'ALL',
  NON_PLAN: 'nonPlan',
  LIGHT: 'light',
  NORMAL: 'normal',
  PREMIUM: 'premium',
  BLACK: 'black'
};
export const RankValue = {
  ALL: 'ALL',
  NON_RANK: 'NonRank',
  SILVER: 'Silver',
  GOLD: 'Gold',
  DIAMOND: 'Diamond',
  BLACK: 'Black'
};
export const manageBannerInputCsv = {
  USER_ID: 'user_id',
  JOB: 'job'
};
export const manageBannerOptionField = {
  RANK: 'rank',
  PLAN: 'plan'
};
export const manageBannerUserPlanOptions = [
  { name: 'ALL', value: PlanValue.ALL },
  {
    name: 'Non-plan',
    value: PlanValue.NON_PLAN
  },
  {
    name: 'Light',
    value: PlanValue.LIGHT
  },
  {
    name: 'Normal',
    value: PlanValue.NORMAL
  },
  {
    name: 'Premium',
    value: PlanValue.PREMIUM
  },
  {
    name: 'Black',
    value: PlanValue.BLACK
  }
];
export const manageBannerUserRankOptions = [
  { name: 'ALL', value: PlanValue.ALL },
  {
    name: 'Non-rank',
    value: RankValue.NON_RANK
  },
  {
    name: 'Silver',
    value: RankValue.SILVER
  },
  {
    name: 'Gold',
    value: RankValue.GOLD
  },
  {
    name: 'Diamond',
    value: RankValue.DIAMOND
  },
  {
    name: 'Black',
    value: RankValue.BLACK
  }
];
export const bannerUrlRegex = /^((http|https|bachelordate):\/\/)/;

export const UserVersionStatus = {
  APPROVED: 1,
  UNAPPROVED: 0
}

export const VersionType = {
  ITEM_BETA: 'item_beta',
}

export const USER_DETAIL_LIMIT_MIN_AGE = 18;
export const USER_DETAIL_LIMIT_MAX_AGE = 99;
export const MONTH_OPTIONS = [
  { label: '01', value: 1 },
  { label: '02', value: 2 },
  { label: '03', value: 3 },
  { label: '04', value: 4 },
  { label: '05', value: 5 },
  { label: '06', value: 6 },
  { label: '07', value: 7 },
  { label: '08', value: 8 },
  { label: '09', value: 9 },
  { label: '10', value: 10 },
  { label: '11', value: 11 },
  { label: '12', value: 12 }
];