import axios from 'axios';
import * as api from './api';
import { store } from '../store';
import { toast } from 'react-toastify';

export default function request(options) {
  return axios(options);
}

function* requestAPI(path, method, params = {}, timeout = 120000) {
  let baseURL = api.API.BASE_URL;
  let accessToken = yield store.getState().auth.userData.access_token
    ? store.getState().auth.userData.access_token
    : '';
  let headers = {
    'Content-Type': params.header ? params.header : 'application/json'
    // 'Content-Type': 'application/x-www-form-urlencoded',
    // 'Content-Type': 'multipart/form-data',
    // 'Content-Type': 'application/json',
  };
  if (accessToken) {
    headers = {
      ...headers,
      // Authorization: `Bearer dd`
      Authorization: `Bearer ${accessToken}`
    };
  }
  let response = null;
  // console.log(params);
  try {
    response = yield axios.request({
      url: path,
      method: method,
      baseURL: baseURL,
      headers: headers,
      data:
        method === api.API.POST || method === api.API.PUT ? params.body : null,
      params: params.query,
      timeout: timeout
    });
    switch (path) {
      case 'admin/user/upload-bulk-approval-csv':
        if (
          response.data.data.failures &&
          response.data.data.failures.length > 0
        ) {
          let rowFailure = [];
          response.data.data.failures.map((data, index) => {
            index % 12 !== 0
              ? rowFailure.push(data[0].row)
              : rowFailure.push('\n');
            return rowFailure;
          });
          if (rowFailure.length) {
            toast.error(
              'Upload file bulk member rate failed on rows'.concat(rowFailure)
            );
          }
        } else if (
          response.data.data.failures &&
          response.data.data.failures.length <= 0
        ) {
          toast.success('Upload file bulk member rate successful');
        }
        break;
      default:
        break;
    }
    return response;
  } catch (e) {
    // do sth
    if (
      e.response.status === 512 &&
      typeof e.response.data.message === 'object'
    ) {
      Object.keys(e.response.data.message).map(value => {
        const message = e.response.data.message[value][0];
        message === 'The name must be a string.'
          ? toast.error('Page name is required')
          : toast.error(e.response.data.message[value][0]);
      });
      return e.response;
    } else if (e.response.status === 401) {
      toast.error(e.response.data.message);
      window.location.replace('/login');
    } else if (e.response.status === 422) {
      const { title } = e.response.data.errors;
      if (title) {
        toast.error(title.toString());
        return;
      }
      if (e.response.data && typeof e.response.data.errors === 'object') {
        Object.keys(e.response.data.errors).map(value => {
          toast.error(e.response.data.errors[value][0]);
        });
      } else if (e.response.data.message) {
        toast.error(e.response.data.message);
      }
    } else if (e.response.status === 500 && e.response.data.message !== '') {
      toast.error(e.response.data.message);
    }
    return e;
  }
}

export { requestAPI };
