import { call, put, takeLatest } from 'redux-saga/effects';
import * as types from '../actions/types';
import { requestAPI } from '../services/request';
import { API } from '../services/api';
import { ResponseStatus } from '../../constants/constant';
import { toast } from 'react-toastify';

function* getTrainStations(action) {
  let response = null;
  try {
    response = yield call(
      requestAPI,
      API.API_TRAIN_STATION,
      API.GET,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.GET_TRAIN_STATION_SUCCEEDED,
        data: response.data
      });
    } else {
      yield put({
        type: types.GET_TRAIN_STATION_FAILED,
        error: response
      });
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    toast(error.message);
    yield put({ type: types.GET_TRAIN_STATION_FAILED, error });
  }
}

function* createTrainStation(action) {
  try {
    let response = yield call(
      requestAPI,
      API.API_TRAIN_STATION_CREATE,
      API.POST,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.CREATE_TRAIN_STATION_SUCCEEDED,
        payload: {
          ...response.data
        }
      });
    } else {
      yield put({
        type: types.CREATE_TRAIN_STATION_FAILED,
        error: response
      });
    }

    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    toast.error(error.message);
    yield put({ type: types.CREATE_TRAIN_STATION_FAILED, error });
  }
}

function* updateTrainStation(action) {
  try {
    let response = yield call(
      requestAPI,
      API.API_TRAIN_STATION_UPDATE + `/${action.params.id}`,
      API.POST,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.UPDATE_TRAIN_STATION_SUCCEEDED,
        payload: {
          ...response.data
        }
      });
    } else {
      yield put({
        type: types.UPDATE_TRAIN_STATION_FAILED,
        error: response
      });
    }

    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    toast.error(error.message);
    yield put({ type: types.UPDATE_TRAIN_STATION_FAILED, error });
  }
}

function* getMapInfo(action) {
  try {
    let response = yield call(
      requestAPI,
      API.API_TRAIN_STATION_GET_MAP_INFO,
      API.GET,
      { query: action.params }
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.GET_MAP_INFO_SUCCEEDED,
        payload: {
          ...response.data
        }
      });
    } else {
      yield put({
        type: types.GET_MAP_INFO_FAILED,
        error: response
      });
    }

    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    toast.error(error.message);
    yield put({ type: types.GET_MAP_INFO_FAILED, error });
  }
}

export function* watchCreateTrainStation() {
  yield takeLatest(types.CREATE_TRAIN_STATION_REQUESTED, createTrainStation);
}

export function* watchUpdateTrainStation() {
  yield takeLatest(types.UPDATE_TRAIN_STATION_REQUESTED, updateTrainStation);
}

export function* watchGetMapInfo() {
  yield takeLatest(types.GET_MAP_INFO_REQUESTED, getMapInfo);
}

export function* watchGetTrainStations() {
  yield takeLatest(types.GET_TRAIN_STATION_REQUESTED, getTrainStations);
}
