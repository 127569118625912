import * as types from './types';

export function login(params, onComplete) {
  return {
    type: types.LOGIN_REQUESTED,
    params,
    onComplete
  };
}
export function logout(params, onComplete) {
  return {
    type: types.LOGOUT_REQUESTED,
    params,
    onComplete
  };
}
export function updateMatchingDate(params, onComplete) {
  return {
    type: types.LOGOUT_REQUESTED,
    params,
    onComplete
  };
}

export function getTempcode(params, onComplete) {
  return {
    type: types.TEMP_CODE_REQUESTED,
    params,
    onComplete
  };
}

export function listUserStatus(params, onComplete) {
  return {
    type: types.LOGOUT_REQUESTED,
    params,
    onComplete
  };
}

export function getListRecommendation(params, onComplete) {
  return {
    type: types.LOGOUT_REQUESTED,
    params,
    onComplete
  };
}

export function getTypesRecommendation(params, onComplete) {
  return {
    type: types.LOGOUT_REQUESTED,
    params,
    onComplete
  };
}

export function setRecommendation(params, onComplete) {
  return {
    type: types.LOGOUT_REQUESTED,
    params,
    onComplete
  };
}

export function importRematching(params, onComplete) {
  return {
    type: types.IMPORT_REMATCHING_REQUESTED,
    params,
    onComplete
  };
}

export function resetLastWeek(params, onComplete) {
  return {
    type: types.RESET_LAST_WEEK_REQUESTED,
    params,
    onComplete
  };
}

export function migrateParticipants(params, onComplete) {
  return {
    type: types.MIGRATE_REQUESTED,
    params,
    onComplete
  };
}
//Time settings
export function getTiming(params, onComplete) {
  return {
    type: types.GET_TIMING_REQUESTED,
    params,
    onComplete
  };
}

export function updateTiming(params, onComplete) {
  return {
    type: types.UPDATE_TIMING_REQUESTED,
    params,
    onComplete
  };
}

export function getUserNotifications(params, onComplete) {
  return {
    type: types.GET_USER_NOTIFICATION_REQUESTED,
    params,
    onComplete
  };
}

export function getNotifications(params, onComplete) {
  return {
    type: types.GET_NOTIFICATION_REQUESTED,
    params,
    onComplete
  };
}

//PREFECTURES
export function getPrefectures(params, onComplete) {
  return {
    type: types.GET_PREFECTURE_REQUESTED,
    params,
    onComplete
  };
}

export function createPrefecture(params, onComplete) {
  return {
    type: types.CREATE_PREFECTURE_REQUESTED,
    params,
    onComplete
  };
}

export function updatePrefecture(params, onComplete) {
  return {
    type: types.UPDATE_PREFECTURE_REQUESTED,
    params,
    onComplete
  };
}

export function deletePrefecture(params, onComplete) {
  return {
    type: types.DELETE_PREFECTURE_REQUESTED,
    params,
    onComplete
  };
}

export function updateStatusPrefecture(params, onComplete) {
  return {
    type: types.UPDATE_STATUS_PREFECTURE_REQUESTED,
    params,
    onComplete
  };
}

//CAFE
export function getCafes(params, onComplete) {
  return {
    type: types.GET_CAFE_REQUESTED,
    params,
    onComplete
  };
}

export function createCafe(params, onComplete) {
  return {
    type: types.CREATE_CAFE_REQUESTED,
    params,
    onComplete
  };
}

export function updateCafe(params, onComplete) {
  return {
    type: types.UPDATE_CAFE_REQUESTED,
    params,
    onComplete
  };
}

export function deleteCafe(params, onComplete) {
  return {
    type: types.DELETE_CAFE_REQUESTED,
    params,
    onComplete
  };
}

export function changeApproveCafe(params, onComplete) {
  return {
    type: types.CHANGE_APPROVE_CAFE_REQUESTED,
    params,
    onComplete
  };
}

export function changeBlackActiveCafe(params, onComplete) {
  return {
    type: types.CHANGE_BLACK_ACTIVE_CAFE_REQUESTED,
    params,
    onComplete
  };
}

export function getCafeMapInfo(params, onComplete) {
  return {
    type: types.GET_CAFE_MAP_INFO_REQUESTED,
    params,
    onComplete
  };
}

export function importCafeDatingCsv(params, onComplete) {
  return {
    type: types.IMPORT_CAFE_DATING_CSV_REQUESTED,
    params,
    onComplete
  };
}
//AREA
export function getAreas(params, onComplete) {
  return {
    type: types.GET_AREA_REQUESTED,
    params,
    onComplete
  };
}

export function createArea(params, onComplete) {
  return {
    type: types.CREATE_AREA_REQUESTED,
    params,
    onComplete
  };
}

export function updateArea(params, onComplete) {
  return {
    type: types.UPDATE_AREA_REQUESTED,
    params,
    onComplete
  };
}
export function updateStatusArea(params, onComplete) {
  return {
    type: types.UPDATE_STATUS_AREA_REQUESTED,
    params,
    onComplete
  };
}
export function deleteArea(params, onComplete) {
  return {
    type: types.DELETE_AREA_REQUESTED,
    params,
    onComplete
  };
}

//SYSTEM LOGS
export function getSystemLogs(params, onComplete) {
  return {
    type: types.GET_SYSTEM_LOG_REQUESTED,
    params,
    onComplete
  };
}

export function getLogDates(params, onComplete) {
  return {
    type: types.GET_LOG_DATES_REQUESTED,
    params,
    onComplete
  };
}

//NOTIFICATION
export function createNotification(params, onComplete) {
  return {
    type: types.CREATE_NOTIFICATION_REQUESTED,
    params,
    onComplete
  };
}

export function requestUploadUserImage(params, onComplete) {
  return {
    type: types.STORE_IMAGE_REQUESTED,
    params,
    onComplete
  };
}

export function updateNotification(params, onComplete) {
  return {
    type: types.UPDATE_NOTIFICATION_REQUESTED,
    params,
    onComplete
  };
}

export function deleteNotification(params, onComplete) {
  return {
    type: types.DELETE_NOTIFICATION_REQUESTED,
    params,
    onComplete
  };
}

export function sendNotification(params, onComplete) {
  return {
    type: types.SEND_NOTIFICATION_REQUESTED,
    params,
    onComplete
  };
}
export function sendBulkMessage(params, onComplete) {
  return {
    type: types.SEND_BULK_MESSAGE_REQUESTED,
    params,
    onComplete
  };
}
export function readNotification(params, onComplete) {
  return {
    type: types.SEND_NOTIFICATION_REQUESTED,
    params,
    onComplete
  };
}
export function listUsers(params, onComplete) {
  return {
    type: types.LIST_USERS_REQUEST,
    params,
    onComplete
  };
}

export function deleteUser(params, onComplete) {
  return {
    type: types.DELETE_USER_REQUESTED,
    params,
    onComplete
  };
}

export function banUser(params, onComplete) {
  return {
    type: types.BAN_USER_REQUESTED,
    params,
    onComplete
  };
}

export function updateUserStatus(params, onComplete) {
  return {
    type: types.UPDATE_STATUS_USER_REQUEST,
    params,
    onComplete
  };
}

export function updateUserProfile(params, onComplete) {
  return {
    type: types.UPDATE_USER_PROFILE_REQUEST,
    params,
    onComplete
  };
}

export function getUpdateUserProfileData(params, onComplete) {
  return {
    type: types.GET_UPDATE_USER_PROFILE_DATA_REQUEST,
    params,
    onComplete
  };
}

export function fakeUser(params, onComplete) {
  return {
    type: types.FAKE_USER_REQUESTED,
    params,
    onComplete
  };
}

export function issueCoupon(params, onComplete) {
  return {
    type: types.ISSUE_COUPON_REQUESTED,
    params,
    onComplete
  };
}

export function changeBetaVer(params, onComplete) {
  return {
    type: types.CHANGE_BETA_VER_REQUESTED,
    params,
    onComplete
  };
}

export function homescreen(params, onComplete) {
  return {
    type: types.HOME_SCREEN_REQUESTED,
    params,
    onComplete
  };
}

export function clearHomescreen(params, onComplete) {
  return {
    type: types.CLEAR_HOMESCREEN,
    params,
    onComplete
  };
}

export function getMatchDetail(params, onComplete) {
  return {
    type: types.CURRENT_USER_GET_MATCH_DATA_REQUESTED,
    params,
    onComplete
  };
}
export function getChangeCostPlan(params, onComplete) {
  return {
    type: types.CURRENT_USER_CHANGE_COST_PLAN_REQUESTED,
    params,
    onComplete
  };
}

export function getCancelChangeCostPlan(params, onComplete) {
  return {
    type: types.CURRENT_USER_CANCEL_COST_PLAN_REQUESTED,
    params,
    onComplete
  };
}

export function resetMatchDetail() {
  return {
    type: types.CURRENT_USER_RESET_MATCH_DATA
  };
}

export function getPopups(params, onComplete) {
  return {
    type: types.POPUPS_REQUESTED,
    params,
    onComplete
  };
}

export function deletePopup(params, onComplete) {
  return {
    type: types.DELETE_POPUP_REQUESTED,
    params,
    onComplete
  };
}

export function partnerReflectedByCouponPopupShown() {
  return {
    type: types.PARTNER_REFLECTED_COUPON_POPUP_SHOWN
  };
}

export function userProfile(params, onComplete) {
  return {
    type: types.USER_PROFILE_REQUESTED,
    params,
    onComplete
  };
}

export function getUserDetail(params, onComplete) {
  return {
    type: types.USER_DETAIL_REQUESTED,
    params,
    onComplete
  };
}

export function getUserCoupon(params, onComplete) {
  return {
    type: types.GET_USER_COUPON_REQUESTED,
    params,
    onComplete
  };
}

export function discardUserCoupon(id, onComplete) {
  return {
    type: types.DISCARD_USER_COUPON_REQUESTED,
    id,
    onComplete
  };
}

export function getUserTrial(params, onComplete) {
  return {
    type: types.GET_USER_TRIAL_REQUESTED,
    params,
    onComplete
  };
}

export function getUserDatingHistory(params, onComplete) {
  return {
    type: types.GET_USER_DATING_HISTORY_REQUESTED,
    params,
    onComplete
  };
}

export function fetchUserActivityLog(params, onComplete) {
  return {
    type: types.GET_USER_ACTIVITY_LOG_REQUESTED,
    params,
    onComplete
  };
}

export function useCoupon(params, onComplete) {
  return {
    type: types.USE_COUPON_REQUESTED,
    params,
    onComplete
  };
}

export function cancelDate(params, onComplete) {
  return {
    type: types.CANCEL_DATE_REQUESTED,
    params,
    onComplete
  };
}

export function cancelDateByPartner(params, onComplete) {
  return {
    type: types.CANCEL_DATE_BY_PARTNER_REQUESTED,
    params,
    onComplete
  };
}

export function chatRoom(params, onComplete) {
  return {
    type: types.CHAT_ROOM_REQUESTED,
    params,
    onComplete
  };
}

export function chatHistory(params, onComplete) {
  return {
    type: types.CHAT_HISTORY_REQUESTED,
    params,
    onComplete
  };
}

export function getCafe(params, onComplete) {
  return {
    type: types.GET_CAFE_REQUESTED,
    params,
    onComplete
  };
}

export function getChatMessages(params, onComplete) {
  return {
    type: types.GET_CHAT_MESSAGES_REQUESTED,
    params,
    onComplete
  };
}

export function getTwilioToken(params, onComplete) {
  return {
    type: types.GET_TWILIO_TOKEN_REQUESTED,
    params,
    onComplete
  };
}

export function updateUserParticipationDates(dates) {
  return {
    type: types.UPDATE_USER_PARTICIPATION_DATES,
    dates
  };
}

export function showMaintain() {
  return {
    type: types.SHOW_MAINTAIN
  };
}

export function showLoading() {
  return {
    type: types.SHOW_LOADING
  };
}

export function hideLoading() {
  return {
    type: types.HIDE_LOADING
  };
}
export function showTooltip() {
  return {
    type: types.SHOW_TOOLTIPS
  };
}

export function hideTooltip() {
  return {
    type: types.HIDE_TOOLTIPS
  };
}

export function showError() {
  return {
    type: types.SHOW_ERROR
  };
}

export function hideError() {
  return {
    type: types.HIDE_ERROR
  };
}

export function setParticipation(params, onComplete) {
  return {
    type: types.SET_PARTICIPATION_REQUESTED,
    params,
    onComplete
  };
}

export function getAllParticipants(params, onComplete) {
  return {
    type: types.GET_PARTICIPANTS_REQUESTED,
    params,
    onComplete
  };
}

export function getUserParticipantHistory(params, onComplete) {
  return {
    type: types.GET_PARTICIPANTS_USER_HISTORY_REQUESTED,
    params,
    onComplete
  };
}

export function requestDeactiveProfile(params, onComplete) {
  return {
    type: types.DEACTIVE_PROFILE_REQUESTED,
    params,
    onComplete
  };
}

export function openDrawer() {
  return {
    type: types.OPEN_DRAWER
  };
}

export function closeDrawer() {
  return {
    type: types.CLOSE_DRAWER
  };
}

export function requestCancelProfile(params, onComplete) {
  return {
    type: types.CANCEL_PROFILE_REQUESTED,
    params,
    onComplete
  };
}

export function requestSaveUserFeedback(params, onComplete) {
  return {
    type: types.SAVE_USER_FEEDBACK_REQUESTED,
    params,
    onComplete
  };
}

export function requestAddRequestChangeTime(params, onComplete) {
  return {
    type: types.ADD_REQUEST_TIME_CHANGE_REQUESTED,
    params,
    onComplete
  };
}

export function requestUpdateRequestChangeTime(params, onComplete) {
  return {
    type: types.UPDATE_REQUEST_TIME_CHANGE_REQUESTED,
    params,
    onComplete
  };
}

export function requestUserFeedbackDetails(params, onComplete) {
  return {
    type: types.GET_USER_FEEDBACK_DETAILS_REQUESTED,
    params,
    onComplete
  };
}

export function requestInviteFriendsData(params, onComplete) {
  return {
    type: types.INVITE_FRIENDS_DATA_REQUESTED,
    params,
    onComplete
  };
}

export function requestCompleteInvitationFriends(params, onComplete) {
  return {
    type: types.COMPLETE_INVITATION_FRIENDS_DATA_REQUESTED,
    params,
    onComplete
  };
}

export function requestFriends(params, onComplete) {
  return {
    type: types.COMPLETE_INVITATION_FRIENDS_DATA_REQUESTED,
    params,
    onComplete
  };
}

export function requestMatchProfileInfo(params, onComplete) {
  return {
    type: types.MATCH_PROFILE_INFO_REQUEST,
    params,
    onComplete
  };
}

export function requestCouponList(params, onComplete) {
  return {
    type: types.COUPON_LIST_REQUEST,
    params,
    onComplete
  };
}

export function requestExchangeCoupon(params, onComplete) {
  return {
    type: types.EXCHANGE_COUPON_REQUEST,
    params,
    onComplete
  };
}

export function decrementCoupon() {
  return {
    type: types.COUPON_LIST_DECREMENT
  };
}

export function resetCouponList() {
  return {
    type: types.COUPON_LIST_RESET
  };
}

export function extendTrialByTrialSuggestion(onComplete) {
  return {
    type: types.EXTEND_TRIAL_BY_SUGGESTION_REQUESTED,
    onComplete
  };
}

export function requestRematch(onComplete) {
  return {
    type: types.REMATCH_REQUESTED,
    onComplete
  };
}

export function requestEvaluationInfo(params, onComplete) {
  return {
    type: types.EVALUATION_INFO_REQUEST,
    params,
    onComplete
  };
}

export function requestEvaluationList(params, onComplete) {
  return {
    type: types.EVALUATION_LIST_REQUEST,
    params,
    onComplete
  };
}

// job preference mapping
export function getJobPreferenceMapping(params, onComplete) {
  return {
    type: types.GET_JOB_PREFERENCE_MAPPING_REQUESTED,
    params,
    onComplete
  };
}

export function getJobPreferenceMappingOptions(params, onComplete) {
  return {
    type: types.GET_JOB_PREFERENCE_MAPPING_OPTIONS_REQUESTED,
    params,
    onComplete
  };
}

export function createJobPreferenceMapping(params, onComplete) {
  return {
    type: types.CREATE_JOB_PREFERENCE_MAPPING_REQUESTED,
    params,
    onComplete
  };
}

export function updateJobPreferenceMapping(params, onComplete) {
  return {
    type: types.UPDATE_JOB_PREFERENCE_MAPPING_REQUESTED,
    params,
    onComplete
  };
}

export function deleteJobPreferenceMapping(params, onComplete) {
  return {
    type: types.DELETE_JOB_PREFERENCE_MAPPING_REQUESTED,
    params,
    onComplete
  };
}

//matched user pair list

export function requestMatchUserPairList(params, onComplete) {
  return {
    type: types.GET_MATCH_USE_PAIR_LIST_REQUESTED,
    params,
    onComplete
  };
}

export function createMatchUserPairList(params, onComplete) {
  return {
    type: types.CREATE_MATCH_USE_PAIR_LIST_REQUESTED,
    params,
    onComplete
  };
}

export function getUpdateInfo(params, onComplete) {
  return {
    type: types.GET_UPDATE_MATCH_USE_PAIR_LIST_REQUESTED,
    params,
    onComplete
  };
}

export function updateMatchUserPairList(params, onComplete) {
  return {
    type: types.UPDATE_MATCH_USE_PAIR_LIST_REQUESTED,
    params,
    onComplete
  };
}

export function cancelMatchUserPairList(params, onComplete) {
  return {
    type: types.DELETE_MATCH_USE_PAIR_LIST_REQUESTED,
    params,
    onComplete
  };
}

//matching date

export function requestMatchingDateList(params, onComplete) {
  return {
    type: types.LIST_MATCHING_DATE_REQUEST,
    params,
    onComplete
  };
}

//DATING DAY SETTING

export function getDatingDayOfWeekSetting(params, onComplete) {
  return {
    type: types.GET_DATING_DAY_OF_WEEK_SETTING_REQUESTED,
    params,
    onComplete
  };
}

export function migrateDatingDayOfWeekSetting(params, onComplete) {
  return {
    type: types.MIGRATE_DATING_DAY_OF_WEEK_SETTING_REQUESTED,
    params,
    onComplete
  };
}

export function getUserDatingReport(params, onComplete) {
  return {
    type: types.GET_USER_DATING_REPORT_REQUESTED,
    params,
    onComplete
  };
}

export function getSchools(params, onComplete) {
  return {
    type: types.GET_SCHOOL_REQUESTED,
    params,
    onComplete
  };
}
export function updateSchool(params, onComplete) {
  return {
    type: types.UPDATE_SCHOOL_REQUESTED,
    params,
    onComplete
  };
}
export function createSchool(params, onComplete) {
  return {
    type: types.CREATE_SCHOOL_REQUESTED,
    params,
    onComplete
  };
}

//Chat
export function getRooms(params, onComplete) {
  return {
    type: types.GET_ROOMS_REQUESTED,
    params,
    onComplete
  };
}
export function getRoomDetail(params, onComplete) {
  return {
    type: types.GET_ROOMS_DETAIL_REQUESTED,
    params,
    onComplete
  };
}

export function clearRoomDetail() {
  return {
    type: types.CLEAR_ROOMS_DETAIL
  };
}

export function getMessages(params, onComplete) {
  return {
    type: types.GET_MESSAGES_REQUESTED,
    params,
    onComplete
  };
}

export function searchMessages(params, onComplete) {
  return {
    type: types.SEARCH_MESSAGES_REQUESTED,
    params,
    onComplete
  };
}

export function searchRoom(params, onComplete) {
  return {
    type: types.SEARCH_ROOMS_REQUESTED,
    params,
    onComplete
  };
}

export function migrateMessages(params, onComplete) {
  return {
    type: types.MIGRATE_MESSAGES_REQUESTED,
    params,
    onComplete
  };
}

export function getParticipantCount(params, onComplete) {
  return {
    type: types.GET_PARTICIPANT_COUNT_REQUESTED,
    params,
    onComplete
  };
}

export function getClassificationParticipant(params, onComplete) {
  return {
    type: types.GET_CLASSIFICATION_PARTICIPANT_REQUESTED,
    params,
    onComplete
  };
}
export function updateParticipantCount(params, onComplete) {
  return {
    type: types.UPDATE_PARTICIPANT_COUNT_REQUESTED,
    params,
    onComplete
  };
}

export function getDatingDayDisplay(params, onComplete) {
  return {
    type: types.GET_DATING_DAY_DISPLAY_REQUESTED,
    params,
    onComplete
  };
}

export function updateDatingDayDisplay(params, onComplete) {
  return {
    type: types.UPDATE_DATING_DAY_DISPLAY_REQUESTED,
    params,
    onComplete
  };
}

export function getDatingDayDisplayBlack(params, onComplete) {
  return {
    type: types.GET_DATING_DAY_DISPLAY_BLACK_REQUESTED,
    params,
    onComplete
  };
}

export function updateDatingDayDisplayBlack(params, onComplete) {
  return {
    type: types.UPDATE_DATING_DAY_DISPLAY_BLACK_REQUESTED,
    params,
    onComplete
  };
}

export function uploadBulkCsv(params, onComplete) {
  return {
    type: types.UPLOAD_BULK_CSV_REQUEST,
    params,
    onComplete
  };
}

export function uploadIssueCouponCsv(params, onComplete) {
  return {
    type: types.UPLOAD_CSV_ISSUE_COUPON_REQUESTED,
    params,
    onComplete
  };
}

export function uploadBetaVerCsv(params, onComplete) {
  return {
    type: types.UPLOAD_BETA_VER_CSV_REQUESTED,
    params,
    onComplete
  };
}

// Maching Info
export function getMatchingInfo(params, onComplete) {
  return {
    type: types.GET_MATCHING_INFO_REQUESTED,
    params,
    onComplete
  };
}

export function createMatchingInfo(params, onComplete) {
  return {
    type: types.CREATE_MATCHING_INFO_REQUESTED,
    params,
    onComplete
  };
}

export function updateMatchingInfo(params, onComplete) {
  return {
    type: types.UPDATE_MATCHING_INFO_REQUESTED,
    params,
    onComplete
  };
}

export function deleteMatchingInfo(params, onComplete) {
  return {
    type: types.DELETE_MATCHING_INFO_REQUESTED,
    params,
    onComplete
  };
}

//Train station
export function getTrainStations(params, onComplete) {
  return {
    type: types.GET_TRAIN_STATION_REQUESTED,
    params,
    onComplete
  };
}

export function createTrainStation(params, onComplete) {
  return {
    type: types.CREATE_TRAIN_STATION_REQUESTED,
    params,
    onComplete
  };
}

export function updateTrainStation(params, onComplete) {
  return {
    type: types.UPDATE_TRAIN_STATION_REQUESTED,
    params,
    onComplete
  };
}

export function getMapInfo(params, onComplete) {
  return {
    type: types.GET_MAP_INFO_REQUESTED,
    params,
    onComplete
  };
}

//plan
export function getPlans(params, onComplete) {
  return {
    type: types.GET_PLAN_REQUESTED,
    params,
    onComplete
  };
}
export function getUserPlans(params, onComplete) {
  return {
    type: types.GET_USER_PLAN_REQUESTED,
    params,
    onComplete
  };
}

export function getUserSubscriptions(params, onComplete) {
  return {
    type: types.GET_USER_SUBSCRIPTION_REQUESTED,
    params,
    onComplete
  };
}

export function getUserInvoice(params, onComplete) {
  return {
    type: types.GET_USER_INVOICE_REQUESTED,
    params,
    onComplete
  };
}

export function refundInvoice(invoiceId, onComplete) {
  return {
    type: types.REFUND_INVOICE_REQUESTED,
    invoiceId,
    onComplete
  };
}

//participant
export function getAwaitingMainMatchAndRematchParticipants(params, onComplete) {
  return {
    type: types.GET_PARTICIPANTS_MAIN_MATCH_AND_REMATCH_REQUESTED,
    params,
    onComplete
  };
}

// get admins
export function getAdmins(params, onComplete) {
  return {
    type: types.GET_ADMINS_REQUEST,
    params,
    onComplete
  };
}

// get current admin
export function setCurrentAdmin(onComplete) {
  return {
    type: types.SET_CURRENT_ADMIN_REQUEST,
    onComplete
  };
}

// reset current admin user
export function clearCurrentAdmin(onComplete) {
  return {
    type: types.CLEAR_CURRENT_ADMIN,
    onComplete
  };
}

export function cancelAwaitingParticipant(params, onComplete) {
  return {
    type: types.CANCEL_AWAITING_PARTICIPANT_REQUESTED,
    params,
    onComplete
  };
}

// create admin account
export function createAdminAccount(params, onComplete) {
  return {
    type: types.CREATE_ADMIN_ACCOUNT_REQUEST,
    params,
    onComplete
  };
}

//delete admin account
export function deleteAdminAccount(params, onComplete) {
  return {
    type: types.DELETE_ADMIN_ACCOUNT_REQUEST,
    params,
    onComplete
  };
}

//edit admin account
export function editAdminAccount(params, onComplete) {
  return {
    type: types.EDIT_ADMIN_ACCOUNT_REQUEST,
    params,
    onComplete
  };
}

export function deleteMainMatchParticipant(params, onComplete) {
  return {
    type: types.DELETE_MAIN_MATCH_PARTICIPANT_REQUESTED,
    params,
    onComplete
  };
}

//Re-approve user
export function reApproveUser(params, onComplete) {
  return {
    type: types.RE_APPROVE_USER_REQUEST,
    params,
    onComplete
  };
}

//Copy user
export function copyUser(params, onComplete) {
  return {
    type: types.COPY_USER_REQUEST,
    params,
    onComplete
  };
}

export function importMainMatchParticipantCsv(params, onComplete) {
  return {
    type: types.IMPORT_MAIN_MATCH_PARTICIPANT_CSV_REQUESTED,
    params,
    onComplete
  };
}

export function importSkipNotificationsForUsersCsv(params, onComplete) {
  return {
    type: types.IMPORT_SKIP_NOTIFICATIONS_FOR_USERS_REQUESTED,
    params,
    onComplete
  };
}

export function getBulkMessageImportPercent(params, onComplete) {
  return {
    type: types.GET_PROCESS_BATCH_QUEUE_REQUESTED,
    params,
    onComplete
  };
}

export function getBulkMessageImportSuccessDetail(params, onComplete) {
  return {
    type: types.GET_BATCH_QUEUE_SUCCESS_DETAIL_DATA_REQUESTED,
    params,
    onComplete
  };
}

export function getBulkMessageImportFailDetail(params, onComplete) {
  return {
    type: types.GET_BATCH_QUEUE_FAIL_DETAIL_DATA_REQUESTED,
    params,
    onComplete
  };
}

export function getFeedbackFlexQuestionControlData(params, onComplete) {
  return {
    type: types.GET_FEEDBACK_FLEX_QUESTION_CONTROL_DATA_REQUESTED,
    params,
    onComplete
  };
}

export function listFeedbackFlexQuestions(params, onComplete) {
  return {
    type: types.LIST_FEEDBACK_FLEX_QUESTIONS_REQUESTED,
    params,
    onComplete
  };
}

export function getFeedbackFlexQuestion(params, onComplete) {
  return {
    type: types.GET_FEEDBACK_FLEX_QUESTION_REQUESTED,
    params,
    onComplete
  };
}

export function createFeedbackFlexQuestion(params, onComplete) {
  return {
    type: types.CREATE_FEEDBACK_FLEX_QUESTION_REQUESTED,
    params,
    onComplete
  };
}

export function updateFeedbackFlexQuestion(params, onComplete) {
  return {
    type: types.UPDATE_FEEDBACK_FLEX_QUESTION_REQUESTED,
    params,
    onComplete
  };
}

//Download CSV
export function downloadSubcriptionCsv(params, onComplete) {
  return {
    type: types.DOWNLOAD_SUBSCRIPTION_CSV_REQUESTED,
    params,
    onComplete
  };
}
export function downloadUserIdCsv(params, onComplete) {
  return {
    type: types.DOWNLOAD_USER_ID_CSV_REQUESTED,
    params,
    onComplete
  };
}
export function getAutoCheckNotifications(params, onComplete) {
  return {
    type: types.GET_AUTO_CHECK_NOTIFICATION_REQUESTED,
    params,
    onComplete
  };
}

export function createAutoCheckNotifications(params, onComplete) {
  return {
    type: types.CREATE_AUTO_CHECK_NOTIFICATION_REQUESTED,
    params,
    onComplete
  };
}

export function deleteAutoCheckNotifications(params, onComplete) {
  return {
    type: types.DELETE_AUTO_CHECK_NOTIFICATION_REQUESTED,
    params,
    onComplete
  };
}

//Certification
export function listCertifications(params, onComplete) {
  return {
    type: types.LIST_CERTIFICATIONS_REQUESTED,
    params,
    onComplete
  };
}

export function approveCertificate(params, onComplete) {
  return {
    type: types.APPROVE_CERTIFICATION_REQUESTED,
    params,
    onComplete
  };
}

export function rejectCertificate(params, onComplete) {
  return {
    type: types.REJECT_CERTIFICATION_REQUESTED,
    params,
    onComplete
  };
}

export function getDatingTimeslotPrefectureSettings(params, onComplete) {
  return {
    type: types.GET_DATING_TIMESLOT_PREFECTURE_SETTINGS_REQUESTED,
    params,
    onComplete
  };
}

export function updateDatingTimeslotPrefectureSettings(params, onComplete) {
  return {
    type: types.UPDATE_DATING_TIMESLOT_PREFECTURE_SETTINGS_REQUESTED,
    params,
    onComplete
  };
}

export function listCommonVariables(params, onComplete) {
  return {
    type: types.LIST_COMMON_VARIABLES_REQUEST,
    params,
    onComplete
  };
}

export function createCommonVariable(params, onComplete) {
  return {
    type: types.CREATE_COMMON_VARIABLE_REQUEST,
    params,
    onComplete
  };
}

export function updateCommonVariable(params, onComplete) {
  return {
    type: types.UPDATE_COMMON_VARIABLE_REQUEST,
    params,
    onComplete
  };
}

export function deleteCommonVariable(params, onComplete) {
  return {
    type: types.DELETE_COMMON_VARIABLE_REQUEST,
    params,
    onComplete
  };
}

export function updateCommonVariableStatus(params, onComplete) {
  return {
    type: types.UPDATE_STATUS_COMMON_VARIABLE_REQUEST,
    params,
    onComplete
  };
}

export function getGenderRatioControlData(params, onComplete) {
  return {
    type: types.GET_GENDER_RATIO_CONTROL_DATA_REQUESTED,
    params,
    onComplete
  };
}

export function getExtendParticipation(params, onComplete) {
  return {
    type: types.GET_EXTEND_DATING_DAY_DISPLAY_REQUESTED,
    params,
    onComplete
  };
}
export function updateExtendParticipation(params, onComplete) {
  return {
    type: types.UPDATE_EXTEND_DATING_DAY_DISPLAY_REQUESTED,
    params,
    onComplete
  };
}

export function uploadUserRankCsv(params, onComplete) {
  return {
    type: types.UPLOAD_CSV_USER_RANK_REQUESTED,
    params,
    onComplete
  };
}

export function getCostPlans(params, onComplete) {
	return {
		type: types.GET_COST_PLAN_REQUESTED,
		params,
		onComplete
	};
}

export function reRegisterUser(params, onComplete) {
  return {
    type: types.RE_REGISTER_USER_REQUEST,
    params,
    onComplete
  };
}

export function getBannerTableData(params, onComplete) {
  return {
    type: types.GET_BANNER_TABLE_DATA,
    params,
    onComplete
  };
}

export function updateBannerStatus(params, onComplete) {
  return {
    type: types.UPDATE_BANNER_STATUS,
    params,
    onComplete
  };
}

export function deleteBanner(params, onComplete) {
  return {
    type: types.DELETE_BANNER,
    params,
    onComplete
  };
}

export function createBanner(params, onComplete) {
  return {
    type: types.CREATE_BANNER,
    params,
    onComplete
  };
}

export function uploadBannerImage(params, onComplete) {
  return {
    type: types.UPLOAD_BANNER_IMAGE,
    params,
    onComplete
  };
}

export function getEditBannerData(params, onComplete) {
  return {
    type: types.GET_EDIT_BANNER_DATA,
    params,
    onComplete
  };
}

export function updateBanner(params, onComplete) {
  return {
    type: types.UPDATE_BANNER,
    params,
    onComplete
  };
}

export function importBulkPairCsv(params, onComplete) {
  return {
    type: types.IMPORT_BULK_PAIR_REQUESTED,
    params,
    onComplete
  };
}
