import * as types from '../actions/types';

const initialState = {
  isLoading: false,
  isError: false,
  data: {},
  error: null
};

const manageBannerReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_BANNER_TABLE_DATA:
      return {
        ...state,
        isLoading: true
      };
    case types.GET_BANNER_TABLE_DATA_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        data: action.data,
        error: null
      };
    case types.GET_BANNER_TABLE_DATA_FAILED:
      return {
        ...state,
        isLoading: false,
        data: {},
        isError: true,
        error: action.error
      };
    case types.UPDATE_BANNER_STATUS_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        data: action.data,
        error: null
      };
    case types.UPDATE_BANNER_STATUS_FAILED:
      return {
        ...state,
        isLoading: false,
        data: null,
        isError: true,
        error: action.error
      };
    case types.DELETE_BANNER:
      return {
        ...state,
        isLoading: true
      };
    case types.DELETE_BANNER_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        error: null
      };
    case types.DELETE_BANNER_FAILED:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.error
      };
    case types.CREATE_BANNER:
      return {
        ...state,
        isLoading: true
      };
    case types.CREATE_BANNER_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        data: action.data,
        error: null
      };
    case types.CREATE_BANNER_FAILED:
      return {
        ...state,
        isLoading: false,
        data: null,
        isError: true,
        error: action.error
      };
    case types.UPLOAD_BANNER_IMAGE:
      return {
        ...state,
        isLoading: true
      };
    case types.UPLOAD_BANNER_IMAGE_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        data: action.data,
        error: null
      };
    case types.UPLOAD_BANNER_IMAGE_FAILED:
      return {
        ...state,
        isLoading: false,
        data: null,
        isError: true,
        error: action.error
      };
    case types.GET_EDIT_BANNER_DATA:
      return {
        ...state,
        isLoading: true
      };
    case types.GET_EDIT_BANNER_DATA_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        data: action.data,
        error: null
      };
    case types.GET_EDIT_BANNER_DATA_FAILED:
      return {
        ...state,
        isLoading: false,
        data: {},
        isError: true,
        error: action.error
      };
    case types.UPDATE_BANNER:
      return {
        ...state,
        isLoading: true
      };
    case types.UPDATE_BANNER_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        data: action.data,
        error: null
      };
    case types.UPDATE_BANNER_FAILED:
      return {
        ...state,
        isLoading: false,
        data: {},
        isError: true,
        error: action.error
      };
    default:
      return state;
  }
};

export default manageBannerReducer;
