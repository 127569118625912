import * as types from '../actions/types';
import { enableInitializing } from 'initializable-reducer';
const INITIAL = {
  data: [],
  isLoading: false,
  isError: false
};

const trainStationReducer = enableInitializing((state = INITIAL, action) => {
  switch (action.type) {
    case types.GET_TRAIN_STATION_REQUESTED:
      return {
        ...state,
        isLoading: true
      };
    case types.GET_TRAIN_STATION_SUCCEEDED:
      return {
        ...state,
        data: action.data || {},
        isLoading: false
      };
    case types.GET_TRAIN_STATION_FAILED:
      return {
        ...state,
        data: action.data || {},
        isLoading: false
      };
    case types.CREATE_TRAIN_STATION_REQUESTED:
      return { ...state, isLoading: true, error: null };
    case types.CREATE_TRAIN_STATION_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        data: action.data,
        error: null
      };
    case types.CREATE_TRAIN_STATION_FAILED:
      return {
        ...state,
        isLoading: false,
        data: [],
        error: action.data
      };
    case types.UPDATE_TRAIN_STATION_REQUESTED:
      return { ...state, isLoading: true, error: null };
    case types.UPDATE_TRAIN_STATION_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        data: action.data,
        error: null
      };
    case types.UPDATE_TRAIN_STATION_FAILED:
      return {
        ...state,
        isLoading: false,
        data: [],
        error: action.data
      };
    case types.GET_MAP_INFO_REQUESTED:
      return { ...state, isLoading: true, error: null };
    case types.GET_MAP_INFO_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        data: action.data || {},
        error: null
      };
    case types.GET_MAP_INFO_FAILED:
      return {
        ...state,
        isError: true,
        isLoading: false,
        data: null,
        error: action.error
      };
    default:
      return state;
  }
});

export default trainStationReducer;
