import { connect } from 'react-redux';
import { compose, branch, renderNothing } from 'recompose';
import { any, equals } from 'ramda';
import { LoadingSpinner } from '../../components/Loading';

const mapStateToProps = state => {
  return {
    // To show the global loading indicator,
    // just add the reducer's loading state here
    showLoading: any(equals(true))([
      state.auth.isLoading,
      state.settings.isLoading,
      state.prefectures.isLoading,
      state.matchingInfo.isLoading,
      state.homescreen.isLoading,
      state.match.isLoading,
      state.notification.isLoading,
      state.timeSettings.isLoading,
      state.participation.isLoading,
      state.popups.isLoading,
      state.coupons.isLoading,
      state.useCoupon.isLoading,
      state.userProfile.isLoading,
      state.app.isLoading,
      state.area.isLoading,
      state.chatRoom.isLoading,
      state.cafe.isLoading,
      state.changePlan.isLoading,
      state.cancelProfile.isLoading,
      state.deactiveProfile.isLoading,
      state.feedback.isLoading,
      state.inviteFriends.isLoading,
      state.extendTrialBySuggestion.isLoading,
      state.rematch.isLoading,
      state.evaluationInfo.isLoading,
      state.evaluationList.isLoading,
      state.datingDayOfWeekSetting.isLoading,
      state.chatHistory.isLoading,
      state.educationGroup.isLoading,
      state.systemLogs.isLoading,
      state.matchedUserPairList.isLoading,
      state.useImage.isLoading,
      state.userDetail.isLoading,
      state.userCoupon.isLoading,
      state.userActivityLog.isLoading,
      state.userDatingHistory.isLoading,
      state.participation.isLoading,
      state.matchingDate.isLoading,
      state.sendBulkMessages.isLoading,
      state.adminManagement.isLoading,
      state.downloadCsv.isLoading,
      state.certification.isLoading,
      state.commonVariables.isLoading,
      state.prefectureSetting.isLoading,
      state.userPlan.isLoading,
      state.userTrial.isLoading,
      state.subscription.isLoading,
      state.invoice.isLoading,
      state.participantCountReducer.isLoading,
      state.banner.isLoading,
      state.extenedPaticipationReducer.isLoading,
      state.costPlanReducer.isLoading,
      state.participantCountBlackReducer.isLoading,
      state.trainStation.isLoading
    ])
  };
};

const LoadingSpinnerContainer = compose(
  connect(mapStateToProps),
  branch(({ showLoading }) => !showLoading, renderNothing)
)(LoadingSpinner);

export { LoadingSpinnerContainer as LoadingSpinner };
