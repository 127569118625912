import { call, put, takeLatest } from 'redux-saga/effects';
import * as types from '../actions/types';
import { requestAPI } from '../services/request';
import { API } from '../services/api';
import { ResponseStatus } from '../../constants/constant';
import { toast } from 'react-toastify';

function* downloadSubcriptionCsv(action) {
  let response = null;
  try {
    response = yield call(requestAPI, API.API_REPORT_SUBSCRIPTION, API.GET, {
      query: action.params
    });

    if (response.status === ResponseStatus.SUCCESS) {
      toast.success('Sucessfully download subcriptions report!');
      yield put({
        type: types.DOWNLOAD_SUBSCRIPTION_CSV_SUCCEEDED
      });
    } else {
      toast.error('Failed download subcriptions report!');
      yield put({
        type: types.DOWNLOAD_SUBSCRIPTION_CSV_FAILED,
        error: response
      });
    }

    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    toast.error(error.message);
    yield put({ type: types.DOWNLOAD_SUBSCRIPTION_CSV_FAILED, error });
  }
}
function* downloadUserIdCsv(action) {
  let response = null;
  try {
    response = yield call(
      requestAPI,
      API.API_BANNER_USER_ID + `/${action.params}`,
      API.GET
    );
    if (response.status === ResponseStatus.SUCCESS) {
      toast.success('Sucessfully download user id report!');
      yield put({
        type: types.DOWNLOAD_USER_ID_CSV_SUCCEEDED
      });
    } else {
      toast.error('Failed download user id report!');
      yield put({
        type: types.DOWNLOAD_USER_ID_CSV_FAILED,
        error: response
      });
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    toast.error(error.message);
    yield put({ type: types.DOWNLOAD_USER_ID_CSV_FAILED, error });
  }
}
export function* watchDownloadSupcriptionCsv() {
  yield takeLatest(
    types.DOWNLOAD_SUBSCRIPTION_CSV_REQUESTED,
    downloadSubcriptionCsv
  );
}
export function* watchDownloadUserIdCsv() {
  yield takeLatest(types.DOWNLOAD_USER_ID_CSV_REQUESTED, downloadUserIdCsv);
}
