import { call, put, takeLatest } from 'redux-saga/effects';
import * as types from '../actions/types';
import { requestAPI } from '../services/request';
import { API } from '../services/api';
import { ResponseStatus } from '../../constants/constant';

function* listMatchingDate(action) {
  let response = null;
  try {
    response = yield call(
      requestAPI,
      API.API_DATING_DAY,
      API.GET,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.LIST_MATCHING_DATE_SUCCEEDED,
        data: {
          ...response.data
        }
      });
    } else {
      yield put({
        type: types.LIST_MATCHING_DATE_FAILED,
        error: response.response.data
      });
    }

    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.LIST_MATCHING_DATE_FAILED, error });
  }
}

function* listUserStatus(action) {
  let response = null;
  try {
    response = yield call(
      requestAPI,
      API.API_MATCHING_DATE + `/list-user-status/${action.matchingDateId}`,
      API.POST,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.LIST_USER_STATUS_SUCCEEDED,
        data: {
          ...response.data
        }
      });
    } else {
      yield put({
        type: types.LIST_USER_STATUS_FAILED,
        error: response.response.data
      });
    }

    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.LIST_USER_STATUS_FAILED, error });
  }
}

function* updateMatchingDate(action) {
  let response = null;
  try {
    response = yield call(
      requestAPI,
      API.API_MATCHING_DATE + `/update-matching-date`,
      API.POST,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.UPDATE_MATCHING_DATE_SUCCEEDED,
        data: {
          ...response.data
        }
      });
    } else {
      yield put({
        type: types.UPDATE_MATCHING_DATE_FAILED,
        error: response.response.data
      });
    }

    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.UPDATE_MATCHING_DATE_FAILED, error });
  }
}

function* setRecommendation(action) {
  let response = null;
  try {
    response = yield call(
      requestAPI,
      API.API_MATCHING_DATE + '/recommendation/set',
      API.POST,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.SET_RECOMMENDATION_SUCCEEDED,
        data: response.data
      });
    } else {
      yield put({
        type: types.SET_RECOMMENDATION_FAILED,
        error: response
      });
    }

    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.SET_RECOMMENDATION_FAILED, error });
  }
}

function* getRecommendationList(action) {
  let response = null;
  try {
    response = yield call(
      requestAPI,
      API.API_MATCHING_DATE + '/recommendation/list',
      API.GET,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.RECOMMENDATION_LIST_SUCCEEDED,
        data: response.data
      });
    } else {
      yield put({
        type: types.RECOMMENDATION_LIST_FAILED,
        error: response
      });
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.RECOMMENDATION_LIST_FAILED, error });
  }
}

function* getRecommendationTypes(action) {
  let response = null;
  try {
    response = yield call(
      requestAPI,
      API.API_MATCHING_DATE + '/recommendation/types',
      API.GET,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.RECOMMENDATION_TYPES_SUCCEEDED,
        data: response.data
      });
    } else {
      yield put({
        type: types.RECOMMENDATION_TYPES_FAILED,
        error: response
      });
    }

    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.RECOMMENDATION_TYPES_FAILED, error });
  }
}

export function* watchSetRecommendation() {
  yield takeLatest(types.SET_RECOMMENDATION_REQUEST, setRecommendation);
}
export function* watchUpdateMatchingDate() {
  yield takeLatest(types.UPDATE_MATCHING_DATE_REQUEST, updateMatchingDate);
}

export function* watchListUserStatus() {
  yield takeLatest(types.LIST_USER_STATUS_REQUEST, listUserStatus);
}
export function* watchListMatchingDate() {
  yield takeLatest(types.LIST_MATCHING_DATE_REQUEST, listMatchingDate);
}
export function* watchRecommendationList() {
  yield takeLatest(types.RECOMMENDATION_LIST_REQUEST, getRecommendationList);
}
export function* watchRecommendationTypes() {
  yield takeLatest(types.RECOMMENDATION_TYPES_REQUEST, getRecommendationTypes);
}
