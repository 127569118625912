import * as types from '../actions/types';

const initialState = {
  isLoading: false,
  data: null,
  isError: false,
  isRefresh: false
};

const commonVariablesReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LIST_COMMON_VARIABLES_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case types.LIST_COMMON_VARIABLES_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        data: action.data,
        error: null
      };
    case types.LIST_COMMON_VARIABLES_FAILED:
      return {
        ...state,
        isLoading: false,
        data: null,
        isError: true,
        error: action.error
      };
    case types.CREATE_COMMON_VARIABLE_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case types.CREATE_COMMON_VARIABLE_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        data: action.data,
        error: null
      };
    case types.CREATE_COMMON_VARIABLE_FAILED:
      return {
        ...state,
        isLoading: false,
        data: null,
        isError: true,
        error: action.error
      };
    case types.UPDATE_COMMON_VARIABLE_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case types.UPDATE_COMMON_VARIABLE_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        data: action.data,
        error: null
      };
    case types.UPDATE_COMMON_VARIABLE_FAILED:
      return {
        ...state,
        isLoading: false,
        data: null,
        isError: true,
        error: action.error
      };
    case types.DELETE_COMMON_VARIABLE_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case types.DELETE_COMMON_VARIABLE_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        error: null
      };
    case types.DELETE_COMMON_VARIABLE_FAILED:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.error
      };
    case types.UPDATE_STATUS_COMMON_VARIABLE_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case types.UPDATE_STATUS_COMMON_VARIABLE_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        data: action.data,
        error: null
      };
    case types.UPDATE_STATUS_COMMON_VARIABLE_FAILED:
      return {
        ...state,
        isLoading: false,
        data: null,
        isError: true,
        error: action.error
      };
    default:
      return state;
  }
};

export default commonVariablesReducer;
