import * as types from '../actions/types';

const initialState = {
  isLoading: false,
  data: null,
  isError: false,
  isRefresh: false
};

const sendBulkMessagesReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SEND_BULK_MESSAGE_REQUESTED:
      return {
        ...state,
        isLoading: true
      };
    case types.SEND_BULK_MESSAGE_SUCCEEDED:
      return {
        ...state,
        data: action.data,
        error: null,
        isLoading: false
      };
    case types.SEND_BULK_MESSAGE_FAILED:
      return {
        ...state,
        data: null,
        isError: true,
        error: action.error,
        isLoading: false
      };
    default:
      return state;
  }
};

export default sendBulkMessagesReducer;
