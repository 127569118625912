import * as types from '../actions/types';
import { enableInitializing } from 'initializable-reducer';
const INITIAL = {
  data: [],
  isLoading: false,
  isError: false
};

const adminManagementReducer = enableInitializing((state = INITIAL, action) => {
  switch (action.type) {
    case types.GET_ADMINS_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case types.GET_ADMINS_SUCCEEDED:
      return {
        ...state,
        data: action.data || {},
        isLoading: false
      };
    case types.GET_ADMINS_FAILED:
      return {
        ...state,
        data: action.data || {},
        isLoading: false
      };
    case types.CREATE_ADMIN_ACCOUNT_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case types.CREATE_ADMIN_ACCOUNT_SUCCEEDED:
      return {
        ...state,
        data: action.data || {},
        isLoading: false
      };
    case types.CREATE_ADMIN_ACCOUNT_FAILED:
      return {
        ...state,
        data: action.data || {},
        isLoading: false
      };
    case types.DELETE_ADMIN_ACCOUNT_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case types.DELETE_ADMIN_ACCOUNT_SUCCEEDED:
      return {
        ...state,
        data: action.data || {},
        isLoading: false
      };
    case types.DELETE_ADMIN_ACCOUNT_FAILED:
      return {
        ...state,
        data: action.data || {},
        isLoading: false
      };
    case types.EDIT_ADMIN_ACCOUNT_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case types.EDIT_ADMIN_ACCOUNT_SUCCEEDED:
      return {
        ...state,
        data: action.data || {},
        isLoading: false
      };
    case types.EDIT_ADMIN_ACCOUNT_FAILED:
      return {
        ...state,
        data: action.data || {},
        isLoading: false
      };
    case types.SET_CURRENT_ADMIN_REQUEST:
      return {
        ...state,
        isLoading: false
      };
    case types.SET_CURRENT_ADMIN_SUCCEEDED:
      return {
        ...state,
        currentAdminUser: {
          ...action.data || {},
          isAdmin: action.data?.data?.role === 'admin'
        },
        isLoading: false
      };
    case types.SET_CURRENT_ADMIN_FAILED:
      return {
        ...state,
        data: action.data || {},
        isLoading: false
      };
    case types.CLEAR_CURRENT_ADMIN:
      return {
        ...state,
        currentAdminUser: null,
        isLoading: false
      };
    default:
      return state;
  }
});

export default adminManagementReducer;
