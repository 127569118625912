import * as types from '../actions/types';

const initialState = {
  isLoading: false,
  isError: false,
  data: [],
  isRefresh: false
};

const prefectureReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CREATE_PREFECTURE_REQUESTED:
      return {
        ...state,
        isLoading: true,
        error: null
      };
    case types.CREATE_PREFECTURE_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        data: action.data,
        error: null
      };
    case types.CREATE_PREFECTURE_FAILED:
      return {
        ...state,
        isLoading: false,
        data: [],
        error: action.data
      };
    case types.UPDATE_PREFECTURE_REQUESTED:
      return {
        ...state,
        isLoading: true,
        error: null
      };
    case types.UPDATE_PREFECTURE_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        data: action.data,
        error: null
      };
    case types.UPDATE_PREFECTURE_FAILED:
      return {
        ...state,
        isLoading: false,
        data: [],
        error: action.data
      };
    ////////////////////////////////
    case types.UPDATE_STATUS_PREFECTURE_REQUESTED:
      return {
        ...state,
        isLoading: true,
        error: null
      };
    case types.UPDATE_STATUS_PREFECTURE_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        data: action.data,
        error: null
      };
    case types.UPDATE_STATUS_PREFECTURE_FAILED:
      return {
        ...state,
        isLoading: false,
        data: [],
        error: action.data
      };
    /////////////////////////
    case types.GET_PREFECTURE_REQUESTED:
      return {
        ...state,
        isLoading: true,
        error: null
      };
    case types.GET_PREFECTURE_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        data: action.data || [],
        // data: action.data || [],
        error: null
      };
    case types.GET_PREFECTURE_FAILED:
      return {
        ...state,
        isError: true,
        isLoading: false,
        data: null,
        error: action.error
      };
    default:
      return state;
  }
};

export default prefectureReducer;
