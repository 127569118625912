import { call, put, takeLatest } from 'redux-saga/effects';
import * as types from '../actions/types';
import { requestAPI } from '../services/request';
import { API } from '../services/api';
import { ResponseStatus } from '../../constants/constant';
import { toast } from 'react-toastify';

function* getParticipantCount(action) {
  let response = null;

  try {
    response = yield call(requestAPI, API.API_PARTICIPANT_COUNT, API.GET, {
      query: action.params
    });

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.GET_PARTICIPANT_COUNT_SUCCEEDED,
        data: response.data
      });
    } else {
      yield put({
        type: types.GET_PARTICIPANT_COUNT_FAILED,
        error: response
      });
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.GET_PARTICIPANT_COUNT_FAILED, error });
  }
}

function* getClassificationParticipant(action) {
  let response = null;

  try {
    response = yield call(
      requestAPI,
      API.API_CLASSIFICATION_PARTICIPANT,
      API.GET,
      {
        query: action.params
      }
    );
    if (action.onComplete) {
      action.onComplete(response);
    }

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.GET_CLASSIFICATION_PARTICIPANT_SUCCEEDED,
        data: response.data
      });
    } else {
      yield put({
        type: types.GET_CLASSIFICATION_PARTICIPANT_FAILED,
        error: response
      });
    }
  } catch (error) {
    yield put({ type: types.GET_CLASSIFICATION_PARTICIPANT_FAILED, error });
  }
}

function* getDatingDayDisplay(action) {
  let response = null;

  try {
    response = yield call(requestAPI, API.API_DATING_DAY_DISPLAY + '/list', API.GET, {
        query: action.params
    });

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.GET_DATING_DAY_DISPLAY_SUCCEEDED,
        data: response.data
      });
    } else {
      yield put({
        type: types.GET_DATING_DAY_DISPLAY_FAILED,
        error: response
      });
    }

    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.GET_DATING_DAY_DISPLAY_FAILED, error });
  }
}

function* updateDatingDayDisplay(action) {
  let response = null;

  try {
    response = yield call(
      requestAPI,
      API.API_DATING_DAY_DISPLAY + '/set',
      API.POST,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.UPDATE_DATING_DAY_DISPLAY_SUCCEEDED,
        data: {
          ...response.data
        }
      });
      toast.success('Update dating day display successful!');
    } else {
      yield put({
        type: types.UPDATE_DATING_DAY_DISPLAY_FAILED,
        error: response
      });
      toast.error('Please try again!');
    }
  } catch (error) {
    yield put({ type: types.UPDATE_DATING_DAY_DISPLAY_FAILED, error });
    toast.error('Please try again!');
  }
}


function* updateParticipantCount(action) {
  let response = null;

  try {
    response = yield call(
      requestAPI,
      `${API.API_PARTICIPANT_COUNT}/update`,
      API.POST,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.UPDATE_PARTICIPANT_COUNT_SUCCEEDED,
        data: {
          ...response.data
        }
      });
      toast.success('Update successful!');
    } else {
      yield put({
        type: types.UPDATE_PARTICIPANT_COUNT_FAILED,
        error: response
      });
      toast.error('Please try again!');
    }
  } catch (error) {
    yield put({ type: types.UPDATE_PARTICIPANT_COUNT_FAILED, error });
    toast.error('Please try again!');
  }
}

function* getGenderRatioControlData(action) {
  let response = null;

  try {
    response = yield call(
      requestAPI,
      `${API.API_PARTICIPANT_COUNT}/control-data`,
      API.GET,
      { query: action.params }
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.GET_GENDER_RATIO_CONTROL_DATA_SUCCEEDED,
        data: response.data
      });
    } else {
      yield put({
        type: types.GET_GENDER_RATIO_CONTROL_DATA_FAILED,
        error: response
      });
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.GET_GENDER_RATIO_CONTROL_DATA_FAILED, error });
  }
}

function* getExtendParticipation(action) {
  let response = null;
  try {
    response = yield call(
      requestAPI,
      `${API.API_PARTICIPANT}/dating-day-rematch-display/list`,
      API.GET,
      { query: action.params }
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.GET_EXTEND_DATING_DAY_DISPLAY_REQUESTED_SUCCEEDED,
        data: response.data
      });
    } else {
      yield put({
        type: types.GET_EXTEND_DATING_DAY_DISPLAY_REQUESTED_FAILED,
        error: response
      });
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({
      type: types.GET_EXTEND_DATING_DAY_DISPLAY_REQUESTED_FAILED,
      error
    });
  }
}
function* updateExtendParticipation(action) {
  let response = null;
  try {
    response = yield call(
      requestAPI,
      `${API.API_PARTICIPANT}/dating-day-rematch-display/set`,
      API.POST,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.UPDATE_EXTEND_DATING_DAY_DISPLAY_REQUESTED_SUCCEEDED,
        data: {
          ...response.data
        }
      });
      toast.success('Successful');
    } else {
      yield put({
        type: types.UPDATE_EXTEND_DATING_DAY_DISPLAY_REQUESTED_FAILED,
        error: response.response.data
      });
      toast.error(response.response.data.message);
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({
      type: types.UPDATE_EXTEND_DATING_DAY_DISPLAY_REQUESTED_FAILED,
      error
    });
  }
}

export function* watchGetParticipantCount() {
  yield takeLatest(types.GET_PARTICIPANT_COUNT_REQUESTED, getParticipantCount);
}

export function* watchGetClassificationParticipant() {
  yield takeLatest(
    types.GET_CLASSIFICATION_PARTICIPANT_REQUESTED,
    getClassificationParticipant
  );
}

export function* watchUpdateParticipantCount() {
  yield takeLatest(
    types.UPDATE_PARTICIPANT_COUNT_REQUESTED,
    updateParticipantCount
  );
}

export function* watchGetDatingDayDisplay() {
  yield takeLatest(types.GET_DATING_DAY_DISPLAY_REQUESTED, getDatingDayDisplay);
}

export function* watchUpdateDatingDayDisplay() {
  yield takeLatest(types.UPDATE_DATING_DAY_DISPLAY_REQUESTED, updateDatingDayDisplay);
}

export function* watchGetGenderRatioControlData() {
  yield takeLatest(types.GET_GENDER_RATIO_CONTROL_DATA_REQUESTED, getGenderRatioControlData);
}

export function* watchGetExtendParticipation() {
  yield takeLatest(
    types.GET_EXTEND_DATING_DAY_DISPLAY_REQUESTED,
    getExtendParticipation
  );
}
export function* watchUpdateExtendParticipation() {
  yield takeLatest(
    types.UPDATE_EXTEND_DATING_DAY_DISPLAY_REQUESTED,
    updateExtendParticipation
  );
}
