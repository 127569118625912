import * as types from '../actions/types';
import { enableInitializing } from 'initializable-reducer';

const INITIAL = {
  data: [],
  isLoading: false,
  isError: false
};

const systemLogReducer = enableInitializing((state = INITIAL, action) => {
  switch (action.type) {
    case types.GET_SYSTEM_LOG_REQUESTED:
      return {
        ...state,
        isLoading: true
      };
    case types.GET_SYSTEM_LOG_SUCCEEDED:
      return {
        ...state,
        data: action.data || {},
        isLoading: false
      };
    case types.GET_SYSTEM_LOG_FAILED:
      return {
        ...state,
        data: action.data || {},
        isLoading: false
      };
    default:
      return state;
  }
});

export default systemLogReducer;
