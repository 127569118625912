import * as types from '../actions/types';

const initialState = {
  isLoading: false,
  data: [],
  details: [],
  total: 0,
  isError: false
};

const matchedUserPairListReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_MATCH_USE_PAIR_LIST_REQUESTED:
      return {
        ...state,
        isLoading:true,
      };
    case types.GET_MATCH_USE_PAIR_LIST_SUCCEEDED:
      return {
        ...state,
        isLoading:false,
        data: action.data
      };
    case types.GET_MATCH_USE_PAIR_LIST_FAILED:
      return {
        ...state,
        isLoading:false,
        isError: true
      };
    case types.CREATE_MATCH_USE_PAIR_LIST_REQUESTED:
      return {
        ...state,
        isLoading:true
      };
    case types.CREATE_MATCH_USE_PAIR_LIST_SUCCEEDED:
      return {
        ...state,
        isLoading:false
      };
    case types.CREATE_MATCH_USE_PAIR_LIST_FAILED:
      return {
        ...state,
        isLoading:false,
        isError: true
      };
    case types.GET_UPDATE_MATCH_USE_PAIR_LIST_REQUESTED:
      return {
        ...state,
        isLoading:true
      };
    case types.GET_UPDATE_MATCH_USE_PAIR_LIST_SUCCEEDED:
      return {
        ...state,
        isLoading:false,
        details: action.data
      };
    case types.GET_UPDATE_MATCH_USE_PAIR_LIST_FAILED:
      return {
        ...state,
        isLoading:false,
        isError: true
      };
    case types.UPDATE_MATCH_USE_PAIR_LIST_REQUESTED:
      return {
        ...state,
        isLoading:true
      };
    case types.UPDATE_MATCH_USE_PAIR_LIST_SUCCEEDED:
      return {
        ...state,
        isLoading:false
      };
    case types.UPDATE_MATCH_USE_PAIR_LIST_FAILED:
      return {
        ...state,
        isLoading:false,
        isError: true
      };
    case types.DELETE_MATCH_USE_PAIR_LIST_REQUESTED:
      return {
        ...state,
        isLoading:true
      };
    case types.DELETE_MATCH_USE_PAIR_LIST_SUCCEEDED:
      return {
        ...state,
        isLoading:false,
      };
    case types.DELETE_MATCH_USE_PAIR_LIST_FAILED:
      return {
        ...state,
        isLoading:false,
        isError: true
      };
    default:
      return state;
  }
};

export default matchedUserPairListReducer;
