import * as types from '../actions/types';

const initialState = {
  isLoading: false,
  isRefresh: false,
  isError: false,
  error: null
};

const participationReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_PARTICIPATION_REQUESTED:
      return { ...state, isLoading: true, isError: false, error: null };
    case types.SET_PARTICIPATION_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        isError: false,
        error: null
      };
    case types.SET_PARTICIPATION_FAILED_NEED_ACTION:
      return {
        ...state,
        isLoading: false,
        isError: false,
        error: null
      };
    case types.SET_PARTICIPATION_FAILED:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.error
      };
    case types.IMPORT_REMATCHING_REQUESTED:
      return { ...state, isLoading: true, isError: false, error: null };
    case types.IMPORT_REMATCHING_SUCCEEDED:
      return { ...state, isLoading: false, isError: false, error: null };
    case types.IMPORT_REMATCHING_FAILED:
      return { ...state, isLoading: false, isError: false, error: null };
    case types.MIGRATE_REQUESTED:
      return {
        ...state,
        isLoading: true,
        isError: false,
        error: null
      };
    case types.MIGRATE_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        isError: false,
        error: null
      };
    case types.MIGRATE_FAILED:
      return {
        ...state,
        isLoading: false,
        isError: false,
        error: null
      };
    case types.RESET_LAST_WEEK_REQUESTED:
      return {
        ...state,
        isLoading: true,
        isError: false,
        error: null
      };
    case types.RESET_LAST_WEEK_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        isError: false,
        error: null
      };
    case types.RESET_LAST_WEEK_FAILED:
      return {
        ...state,
        isLoading: false,
        isError: false,
        error: null
      };
    case types.GET_PARTICIPANTS_MAIN_MATCH_AND_REMATCH_REQUESTED:
      return {
        ...state,
        isLoading: true,
        isError: false,
        error: null
      };
    case types.GET_PARTICIPANTS_MAIN_MATCH_AND_REMATCH_SUCCEEDED:
      return {
        ...state,
        isLoading: false
      };
    case types.GET_PARTICIPANTS_MAIN_MATCH_AND_REMATCH_FAILED:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.error
      };
    case types.GET_PARTICIPANTS_REQUESTED:
      return {
        ...state,
        isLoading: true,
        isError: false,
        error: null
      };
    case types.GET_PARTICIPANTS_SUCCEEDED:
      return {
        ...state,
        isLoading: false
      };
    case types.GET_PARTICIPANTS_FAILED:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.error
      };
    
    case types.GET_PARTICIPANTS_USER_HISTORY_REQUESTED:
      return {
        ...state,
        isLoading: true,
      };
    case types.GET_PARTICIPANTS_USER_HISTORY_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        userParticipants: action.data.data,
        error: null
      };
    case types.GET_PARTICIPANTS_USER_HISTORY_FAILED:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.error
      };
    case types.CANCEL_AWAITING_PARTICIPANT_REQUESTED:
      return {
        ...state,
        isLoading: true,
        isError: false,
        error: null
      };
    case types.CANCEL_AWAITING_PARTICIPANT_SUCCEEDED:
      return {
        ...state,
        isLoading: false
      };
    case types.CANCEL_AWAITING_PARTICIPANT_FAILED:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.error
      };
    case types.DELETE_MAIN_MATCH_PARTICIPANT_REQUESTED:
      return {
        ...state,
        isLoading: true,
        isError: false,
        error: null
      };
    case types.DELETE_MAIN_MATCH_PARTICIPANT_SUCCEEDED:
      return {
        ...state,
        isLoading: false
      };
    case types.DELETE_MAIN_MATCH_PARTICIPANT_FAILED:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.error.data
      };
    case types.IMPORT_MAIN_MATCH_PARTICIPANT_CSV_REQUESTED:
      return {
        ...state,
        isLoading: true,
        isError: false,
        error: null
      };
    case types.IMPORT_MAIN_MATCH_PARTICIPANT_CSV_SUCCEEDED:
      return {
        ...state,
        isLoading: false
      };
    case types.IMPORT_MAIN_MATCH_PARTICIPANT_CSV_FAILED:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.error
      };
    default:
      return state;
  }
};

export default participationReducer;
