import { call, put, takeLatest } from 'redux-saga/effects';
import { requestAPI } from '../services/request';
import * as types from '../actions/types';
import { API } from '../services/api';
import { ResponseStatus } from '../../constants/constant';
import { toast } from 'react-toastify';

function* getDatingTimeslotPrefectureSettings(action) {
  let response = null;
  try {
    response = yield call(
      requestAPI,
      `${API.API_PREFECTURE_SETTING}?prefecture_id=${action.params.prefectureId}`,
      API.GET,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.GET_DATING_TIMESLOT_PREFECTURE_SETTINGS_SUCCEEDED,
        data: {
          ...response.data
        }
      });
    } else {
      yield put({
        type: types.GET_DATING_TIMESLOT_PREFECTURE_SETTINGS_FAILED,
        error: response
      });
    }

    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    alert(error.message);
    yield put({ type: types.GET_DATING_TIMESLOT_PREFECTURE_SETTINGS_FAILED, error });
  }
}

function* updateDatingTimeslotPrefectureSettings(action) {
  let response = null;
  try {
    response = yield call(
      requestAPI,
      `${API.API_PREFECTURE_SETTING}`,
      API.POST,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      toast.success(response.data.message);
      yield put({
        type: types.UPDATE_DATING_TIMESLOT_PREFECTURE_SETTINGS_SUCCEEDED,
        data: {
          ...response.data
        }
      });
    } else {
      yield put({
        type: types.UPDATE_DATING_TIMESLOT_PREFECTURE_SETTINGS_FAILED,
        error: response
      });
    }

    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    toast.error(error.message);
    yield put({
      type: types.UPDATE_DATING_TIMESLOT_PREFECTURE_SETTINGS_FAILED,
      error
    });
  }
}

export function* watchGetDatingTimeslotPrefectureSettings() {
  yield takeLatest(
    types.GET_DATING_TIMESLOT_PREFECTURE_SETTINGS_REQUESTED,
    getDatingTimeslotPrefectureSettings
  );
}

export function* watchUpdateDatingTimeslotPrefectureSettings() {
  yield takeLatest(
    types.UPDATE_DATING_TIMESLOT_PREFECTURE_SETTINGS_REQUESTED,
    updateDatingTimeslotPrefectureSettings
  );
}
