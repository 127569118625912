import { call, put, takeLatest } from 'redux-saga/effects';
import * as types from '../actions/types';
import { requestAPI } from '../services/request';
import { API } from '../services/api';
import { ResponseStatus } from '../../constants/constant';
import { toast } from 'react-toastify';

function* getSchools(action) {
  let response = null;
  try {
    response = yield call(
      requestAPI,
      `${API.API_SCHOOL}`,
      API.GET,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.GET_SCHOOL_SUCCEEDED,
        data: {
          ...response.data
        }
      });
      //toast.success('Get School successful');
    } else {
      yield put({
        type: types.GET_SCHOOL_FAILED,
        error: response
      });
    }

    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.GET_SCHOOL_FAILED, error });
  }
}

function* updateSchool(action) {
  let response = null;
  try {
    response = yield call(
      requestAPI,
      `${API.API_SCHOOL}/${action.params.id}`,
      API.PUT,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.UPDATE_SCHOOL_SUCCEEDED,
        data: {
          ...response.data
        }
      });
      toast.success('Update School successful');
    } else {
      yield put({
        type: types.UPDATE_SCHOOL_FAILED,
        error: response
      });
      toast.error(response.message);
    }

    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    alert(error.message);
    yield put({ type: types.UPDATE_SCHOOL_FAILED, error });
  }
}

function* createSchool(action) {
  let response = null;
  try {
    response = yield call(
      requestAPI,
      `${API.API_SCHOOL}`,
      API.POST,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.CREATE_SCHOOL_SUCCEEDED,
        data: {
          ...response.data
        }
      });
      toast.success('Create School successful');
    } else {
      yield put({
        type: types.CREATE_SCHOOL_FAILED,
        error: response
      });
      toast.error('Create school failed');
    }

    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.CREATE_SCHOOL_FAILED, error });
  }
}

function* deleteArea(action) {
  let response = null;
  try {
    response = yield call(
      requestAPI,
      `${API.API_AREAS}/delete/${action.params.id}`,
      API.POST,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.DELETE_AREA_SUCCEEDED,
        data: response.data
      });
    } else {
      yield put({
        type: types.DELETE_AREA_FAILED,
        error: response
      });
    }

    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    alert(error.message);
    yield put({ type: types.DELETE_AREA_FAILED, error });
  }
}

export function* watchGetAllSchools() {
  yield takeLatest(types.GET_SCHOOL_REQUESTED, getSchools);
}

export function* watchCreateSchool() {
  yield takeLatest(types.CREATE_SCHOOL_REQUESTED, createSchool);
}
export function* watchUpdateSchool() {
  yield takeLatest(types.UPDATE_SCHOOL_REQUESTED, updateSchool);
}
export function* watchDeleteArea() {
  yield takeLatest(types.DELETE_AREA_REQUESTED, deleteArea);
}
