import { call, put, takeLatest } from 'redux-saga/effects';
import * as types from '../actions/types';
import { requestAPI } from '../services/request';
import { API } from '../services/api';
import {
  BATCH_QUEUE_IMPORT_DETAIL_STATUS,
  ResponseStatus
} from '../../constants/constant';

function* getBatchQueueProcess(action) {
  let response = null;
  try {
    response = yield call(
      requestAPI,
      API.API_BATCH + '/' + action.params.id,
      API.GET,
      {}
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.GET_PROCESS_BATCH_QUEUE_SUCCEEDED,
        data: response.data
      });
    } else {
      yield put({
        type: types.GET_PROCESS_BATCH_QUEUE_FAILED,
        error: response
      });
    }

    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.GET_PROCESS_BATCH_QUEUE_FAILED, error });
  }
}

function* getBatchQueueSuccessDetailData(action) {
  let response = null;
  let params = {
    status: BATCH_QUEUE_IMPORT_DETAIL_STATUS.success,
    ...action.params
  };
  try {
    response = yield call(
      requestAPI,
      API.API_BATCH + '/' + action.params.id + '/logs',
      API.GET,
      { query: params }
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.GET_BATCH_QUEUE_SUCCESS_DETAIL_DATA_SUCCEEDED,
        data: response.data
      });
    } else {
      yield put({
        type: types.GET_BATCH_QUEUE_SUCCESS_DETAIL_DATA_FAILED,
        error: response
      });
    }

    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({
      type: types.GET_BATCH_QUEUE_SUCCESS_DETAIL_DATA_FAILED,
      error
    });
  }
}

function* getBatchQueueFailDetailData(action) {
  let response = null;
  let params = {
    status: BATCH_QUEUE_IMPORT_DETAIL_STATUS.fail,
    ...action.params
  };
  try {
    response = yield call(
      requestAPI,
      API.API_BATCH + '/' + action.params.id + '/logs',
      API.GET,
      { query: params }
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.GET_BATCH_QUEUE_FAIL_DETAIL_DATA_SUCCEEDED,
        data: response.data
      });
    } else {
      yield put({
        type: types.GET_BATCH_QUEUE_FAIL_DETAIL_DATA_FAILED,
        error: response
      });
    }

    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.GET_BATCH_QUEUE_FAIL_DETAIL_DATA_FAILED, error });
  }
}

export function* watchGetBatchQueueProcess() {
  yield takeLatest(
    types.GET_PROCESS_BATCH_QUEUE_REQUESTED,
    getBatchQueueProcess
  );
}

export function* watchGetBatchQueueSuccessDetailData() {
  yield takeLatest(
    types.GET_BATCH_QUEUE_SUCCESS_DETAIL_DATA_REQUESTED,
    getBatchQueueSuccessDetailData
  );
}

export function* watchGetBatchQueueFailDetailData() {
  yield takeLatest(
    types.GET_BATCH_QUEUE_FAIL_DETAIL_DATA_REQUESTED,
    getBatchQueueFailDetailData
  );
}
