import { call, put, takeLatest } from 'redux-saga/effects';
import * as types from '../actions/types';
import { requestAPI } from '../services/request';
import { API } from '../services/api';
import { ResponseStatus } from '../../constants/constant';
import { toast } from 'react-toastify';

function* updateTiming(action) {
  let response = null;
  try {
    response = yield call(
      requestAPI,
      `${API.API_TIME_SETTINGS}`,
      API.POST,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.UPDATE_TIMING_SUCCEEDED,
        data: {
          ...response.data.data
        }
      });
      toast.success('Update time successful');
    } else {
      yield put({
        type: types.UPDATE_TIMING_FAILED,
        error: response
      });
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.UPDATE_TIMING_FAILED, error });
  }
}

function* getTiming(action) {
  let response = null;
  try {
    response = yield call(
      requestAPI,
      `${API.API_TIME_SETTINGS}`,
      API.GET,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.GET_TIMING_SUCCEEDED,
        data: {
          ...response.data
        }
      });
    } else {
      yield put({
        type: types.GET_TIMING_FAILED,
        error: response
      });
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.GET_TIMING_FAILED, error });
  }
}

export function* watchGetTiming() {
  yield takeLatest(types.GET_TIMING_REQUESTED, getTiming);
}

export function* watchUpdateTiming() {
  yield takeLatest(types.UPDATE_TIMING_REQUESTED, updateTiming);
}
