import { call, put, takeLatest } from 'redux-saga/effects';
import * as types from '../actions/types';
import { requestAPI } from '../services/request';
import { API } from '../services/api';
import { ResponseStatus } from '../../constants/constant';
import { toast } from 'react-toastify';

function* listUsers(action) {
  let response = null;
  try {
    let url = '/list';
    response = yield call(requestAPI, API.API_USER + url, API.GET, {
      query: action.params
    });

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.LIST_USERS_SUCCEEDED,
        data: {
          ...response.data
        }
      });
    } else {
      yield put({
        type: types.LIST_USERS_FAILED,
        error: response.response.data
      });
      toast.error(response.response.data.message);
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.LIST_USERS_FAILED, error });
  }
}

function* updateUserStatus(action) {
  let response = null;
  try {
    response = yield call(
      requestAPI,
      API.API_USER + `/update-status`,
      API.POST,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.UPDATE_STATUS_USER_SUCCEEDED,
        data: {
          ...response.data
        }
      });
      toast.success('Successful');
    } else {
      yield put({
        type: types.UPDATE_STATUS_USER_FAILED,
        error: response.response.data
      });
      toast.error(response.response.data.message);
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.UPDATE_STATUS_USER_FAILED, error });
  }
}

function* updateUserProfile(action) {
  let response = null;
  try {
    response = yield call(
      requestAPI,
      API.API_USER + `/update-profile`,
      API.POST,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.UPDATE_USER_PROFILE_SUCCEEDED,
        data: {
          ...response.data
        }
      });
      toast.success('Successful');
    } else {
      yield put({
        type: types.UPDATE_USER_PROFILE_FAILED,
        error: response.response.data
      });
      toast.error(response.response.data.message);
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.UPDATE_USER_PROFILE_FAILED, error });
  }
}

function* getUpdateUserProfileData(action) {
  let response = null;
  try {
    response = yield call(
      requestAPI,
      API.API_USER + `/get-profile/${action.params.id}`,
      API.GET,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.GET_UPDATE_USER_PROFILE_DATA_SUCCEEDED,
        data: {
          ...response.data
        }
      });
    } else {
      yield put({
        type: types.GET_UPDATE_USER_PROFILE_DATA_FAILED,
        error: response.response.data
      });
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.GET_UPDATE_USER_PROFILE_DATA_FAILED, error });
  }
}

function* fakeUser(action) {
  let response = null;
  try {
    response = yield call(
      requestAPI,
      API.API_USER + `/fake`,
      API.POST,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.FAKE_USER_SUCCEEDED,
        data: {
          ...response.data
        }
      });
      toast.success(
        `${
          action.params.body.fake
            ? 'Set fake user successful'
            : 'Set real user successful'
        }`
      );
    } else {
      yield put({
        type: types.FAKE_USER_FAILED,
        error: response.response.data
      });
      toast.error(response.response.data.message);
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.FAKE_USER_FAILED, error });
  }
}

function* issueCoupon(action) {
  let response = null;
  try {
    response = yield call(
      requestAPI,
      API.API_COUPON + `/issue`,
      API.POST,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.ISSUE_COUPON_SUCCEEDED,
        data: {
          ...response.data
        }
      });
      toast.success('Issue coupon successful');
    } else {
      yield put({
        type: types.ISSUE_COUPON_FAILED,
        error: response.response.data
      });
      toast.error(response.response.data.message);
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.FAKE_USER_FAILED, error });
  }
}

function* changeBetaVer(action) {
  let response = null;
  try {
    response = yield call(
      requestAPI,
      API.API_USER_VERSION + `/change-beta-version`,
      API.POST,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.CHANGE_BETA_VER_SUCCEEDED,
        data: {
          ...response.data
        }
      });
      toast.success('Change Beta Version successful');
    } else {
      yield put({
        type: types.CHANGE_BETA_VER_FAILED,
        error: response.response.data
      });
      toast.error(response.response.data.message);
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.CHANGE_BETA_VER_FAILED, error });
  }
}

function* uploadBulkCsv(action) {
  let response = null;
  try {
    response = yield call(
      requestAPI,
      API.API_USER + `/upload-bulk-approval-csv`,
      API.POST,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.UPLOAD_BULK_CSV_SUCCEEDED,
        data: {
          ...response.data
        }
      });
      toast.success(response.data.message);
    } else {
      yield put({
        type: types.UPLOAD_BULK_CSV_FAILED,
        error: response
      });
      toast.error(response.response.data.message);
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.UPLOAD_BULK_CSV_FAILED, error });
  }
}

function* uploadIssueCouponCsv(action) {
  let response = null;
  try {
    response = yield call(
      requestAPI,
      API.API_COUPON + `/issue/importCSV`,
      API.POST,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.UPLOAD_CSV_ISSUE_COUPON_SUCCEEDED,
        data: {
          ...response.data
        }
      });
      toast.success(response.data.message);
    } else {
      yield put({
        type: types.UPLOAD_CSV_ISSUE_COUPON_FAILED,
        error: response
      });
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.UPLOAD_CSV_ISSUE_COUPON_FAILED, error });
  }
}

function* uploadBetaVerCsv(action) {
  let response = null;
  try {
    response = yield call(
      requestAPI,
      API.API_USER_VERSION + `/import-csv`,
      API.POST,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.UPLOAD_BETA_VER_CSV_SUCCEEDED,
        data: {
          ...response.data
        }
      });
      toast.success(response.data.message);
    } else {
      yield put({
        type: types.UPLOAD_BETA_VER_CSV_FAILED,
        error: response
      });
      toast.error(response.response.data.message);
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.UPLOAD_BETA_VER_CSV_FAILED, error });
  }
}

function* importBulkPairCsv(action) {
  let response = null;
  try {
    response = yield call(
        requestAPI,
        API.API_MATCH_USER_PAIR_LIST + `/import-csv`,
        API.POST,
        action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.IMPORT_BULK_PAIR_SUCCEEDED,
        data: {
          ...response.data
        }
      });
    } else {
      yield put({
        type: types.IMPORT_BULK_PAIR_FAILED,
        error: response
      });
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.IMPORT_BULK_PAIR_FAILED, error });
  }
}

function* deleteUser(action) {
  let response = null;
  try {
    response = yield call(
      requestAPI,
      API.API_USER + `/${action.params.userId}`,
      API.DELETE,
      action.params
    );
    if (action.onComplete) {
      action.onComplete(response);
    }
    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.DELETE_USER_SUCCEEDED,
        data: {
          ...response.data
        }
      });
    } else {
      yield put({
        type: types.DELETE_USER_FAILED,
        error: response.data.data
      });
      toast.error(response.response.data.message);
    }
  } catch (error) {
    yield put({ type: types.DELETE_USER_FAILED, error });
  }
}

function* banUser(action) {
  let response = null;
  try {
    response = yield call(
      requestAPI,
      API.API_BAN_USER + `/${action.params.userId}`,
      API.POST,
      action.params
    );
    if (action.onComplete) {
      action.onComplete(response);
    }
    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.BAN_USER_SUCCEEDED,
        data: {
          ...response.data
        }
      });
      toast.success('Successfully Banned User');
    } else {
      yield put({
        type: types.BAN_USER_FAILED,
        error: response.data.data
      });
      toast.error(response.response.data.message);
    }
  } catch (error) {
    yield put({ type: types.BAN_USER_FAILED, error });
  }
}

function* reApproveUser(action) {
  let response = null;
  try {
    response = yield call(
      requestAPI,
      API.API_USER + `/reapprove`,
      API.POST,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.RE_APPROVE_USER_SUCCEEDED,
        data: {
          ...response.data
        }
      });
      toast.success('Successful');
    } else {
      yield put({
        type: types.RE_APPROVE_USER_FAILED,
        error: response.response.data
      });
      toast.error(response.response.data.message);
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.RE_APPROVE_USER_FAILED, error });
  }
}

function* reRegisterUser(action) {
  let response = null;
  try {
    response = yield call(
      requestAPI,
      API.API_RE_REGISTER_USER + `/${action.params.userId}`,
      API.POST
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.RE_REGISTER_USER_SUCCEEDED,
        data: {
          ...response.data
        }
      });
      toast.success('Successful');
    } else {
      yield put({
        type: types.RE_REGISTER_USER_FAILED,
        error: response.response.data
      });
      toast.error(response.response.data.message);
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.RE_REGISTER_USER_FAILED, error });
  }
}

function* getTempcode(action) {
  let response = null;
  const userId = action.params.userId.toString();
  try {
    response = yield call(
      requestAPI,
      API.API_USER + `/generate-temp-code/${userId}`,
      API.POST,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.TEMP_CODE_SUCCEEDED,
        data: {
          ...response.data
        }
      });
    } else {
      yield put({
        type: types.TEMP_CODE_FAILED,
        error: response.response.data
      });
      toast.error(response.response.data.message);
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.TEMP_CODE_FAILED, error });
  }
}

function* copyUser(action) {
  let response = null;
  try {
    response = yield call(
      requestAPI,
      API.API_USER + `/copy`,
      API.POST,
      action.params
    );
    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.COPY_USER_SUCCEEDED,
        data: {
          ...response.data
        }
      });
      toast.success('Successful');
    } else {
      yield put({
        type: types.COPY_USER_FAILED,
        error: response.response.data
      });
      toast.error(response.response.data.message);
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.COPY_USER_FAILED, error });
  }
}

function* uploadUserRankCsv(action) {
  let response = null;
  try {
    response = yield call(
        requestAPI,
        API.API_USER_RANK + `/import-csv`,
        API.POST,
        action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.UPLOAD_CSV_USER_RANK_SUCCEEDED,
        data: {
          ...response.data
        }
      });
      toast.success(response.data.message);
    } else {
      yield put({
        type: types.UPLOAD_CSV_USER_RANK_FAILED,
        error: response
      });
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.UPLOAD_CSV_USER_RANK_FAILED, error });
  }
}

export function* watchListUsers() {
  yield takeLatest(types.LIST_USERS_REQUEST, listUsers);
}

export function* watchFakeUser() {
  yield takeLatest(types.FAKE_USER_REQUESTED, fakeUser);
}

export function* watchIssueCoupon() {
  yield takeLatest(types.ISSUE_COUPON_REQUESTED, issueCoupon);
}

export function* watchChangeBetaVer() {
  yield takeLatest(types.CHANGE_BETA_VER_REQUESTED, changeBetaVer);
}

export function* watchUpdateStatusUser() {
  yield takeLatest(types.UPDATE_STATUS_USER_REQUEST, updateUserStatus);
}

export function* watchUpdateUserProfile() {
  yield takeLatest(types.UPDATE_USER_PROFILE_REQUEST, updateUserProfile);
}

export function* watchGetUpdateUserProfileData() {
  yield takeLatest(types.GET_UPDATE_USER_PROFILE_DATA_REQUEST, getUpdateUserProfileData);
}

export function* watchUploadBulkCsv() {
  yield takeLatest(types.UPLOAD_BULK_CSV_REQUEST, uploadBulkCsv);
}

export function* watchUploadIssueCouponCsv() {
  yield takeLatest(
    types.UPLOAD_CSV_ISSUE_COUPON_REQUESTED,
    uploadIssueCouponCsv
  );
}

export function* watchUploadBetaVerCsv() {
  yield takeLatest(types.UPLOAD_BETA_VER_CSV_REQUESTED, uploadBetaVerCsv);
}

export function* watchImportBulkPairCsv() {
  yield takeLatest(types.IMPORT_BULK_PAIR_REQUESTED, importBulkPairCsv);
}

export function* watchDeleteUser() {
  yield takeLatest(types.DELETE_USER_REQUESTED, deleteUser);
}

export function* watchBanUser() {
  yield takeLatest(types.BAN_USER_REQUESTED, banUser);
}

export function* watchReApproveUser() {
  yield takeLatest(types.RE_APPROVE_USER_REQUEST, reApproveUser);
}

export function* watchReRegisterUser() {
  yield takeLatest(types.RE_REGISTER_USER_REQUEST, reRegisterUser);
}

export function* watchGetTempcode() {
  yield takeLatest(types.TEMP_CODE_REQUESTED, getTempcode);
}

export function* watchCopyUser() {
  yield takeLatest(types.COPY_USER_REQUEST, copyUser);
}

export function* watchUploadUserRankCsv() {
  yield takeLatest(types.UPLOAD_CSV_USER_RANK_REQUESTED, uploadUserRankCsv);
}
