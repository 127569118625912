import * as types from '../actions/types';

const initialState = {
  isLoading: false,
  isError: false,
  data: {},
  optionsData: [],
  error: null
};

const feedbackReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SAVE_USER_FEEDBACK_REQUESTED:
    case types.GET_USER_FEEDBACK_DETAILS_REQUESTED:
      return { ...state, isLoading: true, error: null };
    case types.SAVE_USER_FEEDBACK_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        error: null
      };
    case types.GET_USER_FEEDBACK_DETAILS_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        error: null,
        userFeedbacks: action.data.data
      };
    case types.SAVE_USER_FEEDBACK_FAILED:
    case types.GET_USER_FEEDBACK_DETAILS_FAILED:
      return {
        ...state,
        isError: true,
        isLoading: false,
        error: action.error
      };
    case types.HIDE_ERROR:
      return {
        ...state,
        isError: false
      };
    case types.GET_FEEDBACK_FLEX_QUESTION_CONTROL_DATA_REQUESTED:
      return {
        ...state,
        isLoading: true
      };
    case types.GET_FEEDBACK_FLEX_QUESTION_CONTROL_DATA_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        optionsData: action.data,
        error: null
      };
    case types.GET_FEEDBACK_FLEX_QUESTION_CONTROL_DATA_FAILED:
      return {
        ...state,
        isLoading: false,
        optionsData: [],
        isError: true,
        error: action.error
      };
    case types.LIST_FEEDBACK_FLEX_QUESTIONS_REQUESTED:
      return {
        ...state,
        isLoading: true
      };
    case types.LIST_FEEDBACK_FLEX_QUESTIONS_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        data: action.data,
        error: null
      };
    case types.LIST_FEEDBACK_FLEX_QUESTIONS_FAILED:
      return {
        ...state,
        isLoading: false,
        data: {},
        isError: true,
        error: action.error
      };
    case types.GET_FEEDBACK_FLEX_QUESTION_REQUESTED:
      return {
        ...state,
        isLoading: true
      };
    case types.GET_FEEDBACK_FLEX_QUESTION_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        data: action.data,
        error: null
      };
    case types.GET_FEEDBACK_FLEX_QUESTION_FAILED:
      return {
        ...state,
        isLoading: false,
        data: {},
        isError: true,
        error: action.error
      };
    case types.CREATE_FEEDBACK_FLEX_QUESTION_REQUESTED:
      return {
        ...state,
        isLoading: true
      };
    case types.CREATE_FEEDBACK_FLEX_QUESTION_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        data: action.data,
        error: null
      };
    case types.CREATE_FEEDBACK_FLEX_QUESTION_FAILED:
      return {
        ...state,
        isLoading: false,
        data: {},
        isError: true,
        error: action.error
      };
    case types.UPDATE_FEEDBACK_FLEX_QUESTION_REQUESTED:
      return {
        ...state,
        isLoading: true
      };
    case types.UPDATE_FEEDBACK_FLEX_QUESTION_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        data: action.data,
        error: null
      };
    case types.UPDATE_FEEDBACK_FLEX_QUESTION_FAILED:
      return {
        ...state,
        isLoading: false,
        data: {},
        isError: true,
        error: action.error
      };
    default:
      return state;
  }
};

export default feedbackReducer;
