import './App.css';
import asyncComponent from './hoc/asyncComponent';

import React from 'react';
import { Route, BrowserRouter, Switch } from 'react-router-dom';
import { LoadingSpinner } from './containers/Loading';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// minified version is also included

const AsyncPrefectures = asyncComponent(() => {
  return import('./containers/Prefectures/Loadable');
});
const AsyncAddEditPrefectures = asyncComponent(() => {
  return import('./containers/AddEditPrefecture/Loadable');
});
const AsyncArea = asyncComponent(() => {
  return import('./containers/ManageArea/Loadable');
});
const AsyncLoginPage = asyncComponent(() => {
  return import('./containers/LoginPage/Loadable');
});
const AsyncTrainStations = asyncComponent(() => {
  return import('./containers/TrainStations/Loadable');
});
const AsyncAddEditTrainStations = asyncComponent(() => {
  return import('./containers/AddEditTrainStation/Loadable');
});
const AsyncAddUser = asyncComponent(() => {
  return import('./containers/AddUser/Loadable');
});
const AsyncAddPair = asyncComponent(() => {
  return import('./containers/AddPair/Loadable');
});
const AsyncUsers = asyncComponent(() => {
  return import('./containers/Users/Loadable');
});
const AsyncMatchedUserPairList = asyncComponent(() => {
  return import('./containers/MatchedUserPairList/Loadable');
});
const AsyncJobPreferenceMapping = asyncComponent(() => {
  return import('./containers/JobPreferenceMapping/Loadable');
});
const AsyncAddJobPreferenceMapping = asyncComponent(() => {
  return import('./containers/AddJobPreferenceMapping/Loadable');
});
const AsyncAddFeedback = asyncComponent(() => {
  return import('./containers/AddFeedback/Loadable');
});
const AsyncMigrationPair = asyncComponent(() => {
  return import('./containers/MigrationPair/Loadable');
});
const AsyncStripePlan = asyncComponent(() => {
  return import('./containers/StripePlan/Loadable');
});
const AsyncFeedback = asyncComponent(() => {
  return import('./containers/Feedback/Loadable');
});
const AsyncUserList = asyncComponent(() => {
  return import('./containers/UserParticipationList/Loadable');
});
const AsyncNotifications = asyncComponent(() => {
  return import('./containers/Notifications/Loadable');
});
const AsyncDashboard = asyncComponent(() => {
  return import('./containers/Dashboard/Loadable');
});
const AsyncManageCafe = asyncComponent(() => {
  return import('./containers/ManageCafe/Loadable');
});
const AsyncAddNewCafe = asyncComponent(() => {
  return import('./containers/AddNewCafe/Loadable');
});
const AsyncEditCafe = asyncComponent(() => {
  return import('./containers/AddNewCafe/Loadable');
});
const AsyncSendBulkEmailNotification = asyncComponent(() => {
  return import('./containers/SendBulkEmailNotification/Loadable');
});
const AsyncAddNewArea = asyncComponent(() => {
  return import('./containers/AddNewArea/Loadable');
});
const AsyncAreaLocations = asyncComponent(() => {
  return import('./containers/AreaLocations/Loadable');
});
const AsyncParticipantAfterMainMatching = asyncComponent(() => {
  return import('./containers/ParticipantAfterMainMatching/Loadable');
});
const AsyncMigrationLog = asyncComponent(() => {
  return import('./containers/MigrationLog/Loadable');
});
const AsyncManageUsers = asyncComponent(() => {
  return import('./containers/ManageUsers/Loadable');
});
const AsyncEditUser = asyncComponent(() => {
  return import('./containers/EditUser/Loadable');
});
const AsyncViewProfile = asyncComponent(() => {
  return import('./containers/ViewProfile/Loadable');
});
const AsyncEditNotification = asyncComponent(() => {
  return import('./containers/EditNotification/Loadable');
});
const AsyncSMSNotifications = asyncComponent(() => {
  return import('./containers/SMSNotifications/Loadable');
});
const AsyncEducationGroup = asyncComponent(() => {
  return import('./containers/EducationGroup/Loadable');
});
const AsyncMatchInfoSettings = asyncComponent(() => {
  return import('./containers/MatchInfoSettings/Loadable');
});

const AsyncMatchInfoSettingsAddNew = asyncComponent(() => {
  return import('./containers/MatchInfoSettingsAddNew/Loadable');
});

const AsyncChatHistory = asyncComponent(() => {
  return import('./containers/ChatHistory/Loadable');
});
const AsyncDatingDaySettings = asyncComponent(() => {
  return import('./containers/DatingDaySettings/Loadable');
});

const AsyncCountParticipant = asyncComponent(() => {
  return import('./containers/CountParticipant/Loadable');
});
const AsyncCountParticipantBlack = asyncComponent(() => {
  return import('./containers/CountParticipantBlack/Loadable');
});
const AsyncAdminManagement = asyncComponent(() => {
  return import('./containers/AdminManagement/Loadable');
});
const AsyncPushNotification = asyncComponent(() => {
  return import('./containers/PushNotifications/Loadable');
});
const AsyncSecondPushNotification = asyncComponent(() => {
  return import('./containers/SecondPushNotifications/Loadable');
});

const AsyncSkipNotificationsForUsers = asyncComponent(() => {
  return import('./containers/SkipNotificationsForUsers/Loadable');
});

const AsyncSkipSMSNotificationsForUsers = asyncComponent(() => {
  return import('./containers/SkipSMSNotificationsForUsers/Loadable');
});

const AsyncSkipPushNotificationsForUsers = asyncComponent(() => {
  return import('./containers/SkipPushNotificationsForUsers/Loadable');
});

const AsyncFeedbackFlexibleQuestions = asyncComponent(() => {
  return import('./containers/FeedbackFlexibleQuestions/Loadable');
});

const AsyncStoreFeedbackFlexibleQuestions = asyncComponent(() => {
  return import('./containers/StoreFeedbackFlexibleQuestions/Loadable');
});

const AsyncDownloadCsv = asyncComponent(() => {
  return import('./containers/DownloadCsv/Loadable');
});

const AsyncCertification = asyncComponent(() => {
  return import('./containers/Certification/Loadable');
});

const AsyncAutoCheckNotifications = asyncComponent(() => {
  return import('./containers/AutoCheckNotifications/Loadable');
});

const AsyncSendBulkPushNotification = asyncComponent(() => {
  return import('./containers/SendBulkPushNotification/Loadable');
});

const AsyncPrefectureSettings = asyncComponent(() => {
  return import('./containers/PrefectureSettings/Loadable');
});

const AsyncCommonVariables = asyncComponent(() => {
  return import('./containers/CommonVariables/Loadable');
});

const AsyncAddEditCommonVariable = asyncComponent(() => {
  return import('./containers/AddEditCommonVariable/Loadable');
});
const AsyncExtendParticipation = asyncComponent(() => {
  return import('./containers/ExtendParticipation/Loadable');
});

const AsyncManageBanner = asyncComponent(() => {
  return import('./containers/ManageBanner/Loadable');
});

const AsyncAddEditBanner = asyncComponent(() => {
  return import('./containers/AddEditBanner/Loadable');
});

let routes = (
  <Switch>
    <Route exact path="/login" component={AsyncLoginPage} />
    <Route exact path="/" component={AsyncDashboard} />
    <Route exact path="/dashboard" component={AsyncDashboard} />
    <Route exact path="/add-user" component={AsyncAddUser} />
    <Route exact path="/manage-users" component={AsyncManageUsers} />
    {/*<Route exact path="/user-dropdown" component={UserDropdown} />*/}
    <Route exact path="/count-participant" component={AsyncCountParticipant} />
    <Route exact path="/count-participant-black" component={AsyncCountParticipantBlack} />
    <Route exact path="/extend-participation" component={AsyncExtendParticipation} />

    <Route exact path="/matching-pair/create" component={AsyncAddPair} />
    <Route exact path="/matching-pair/edit" component={AsyncAddPair} />
    <Route exact path="/matching-job-preference-mapping/create" component={AsyncAddJobPreferenceMapping} />
    <Route exact path="/matching-job-preference-mapping/edit" component={AsyncAddJobPreferenceMapping} />
    <Route exact path="/users" component={AsyncUsers} />
    <Route exact path="/user/update/:id" component={AsyncEditUser} />
    <Route exact path="/add-feedback" component={AsyncAddFeedback} />
    <Route exact path="/feedback" component={AsyncFeedback} />
    <Route
      exact
      path="/matching/matching-pair-users"
      component={AsyncMatchedUserPairList}
    />
    <Route
      exact
      path="/matching/participate-user-list"
      component={AsyncUserList}
    />
    <Route
      exact
      path="/matching/job-preference-mapping"
      component={AsyncJobPreferenceMapping}
    />
    <Route exact path="/migrations/pair" component={AsyncMigrationPair} />
    <Route exact path="/plan" component={AsyncStripePlan} />
    <Route exact path="/notifications" component={AsyncNotifications} />
    <Route exact path="/sms-notifications" component={AsyncSMSNotifications} />
    <Route exact path="/edit-notification" component={AsyncEditNotification} />
    <Route
      exact
      path="/edit-sms-notification"
      component={AsyncEditNotification}
    />
    <Route exact path="/manage-cafe" component={AsyncManageCafe} />
    <Route exact path="/cafe/create" component={AsyncAddNewCafe} />
    <Route exact path="/cafe/update/:id" component={AsyncEditCafe} />
    <Route exact path="/train-stations" component={AsyncTrainStations} />
    <Route
      exact
      path="/train-stations/:id"
      component={AsyncAddEditTrainStations}
    />
    <Route
      exact
      path="/send-bulk-email-notifications"
      component={AsyncSendBulkEmailNotification}
    />
    <Route exact path="/prefectures" component={AsyncPrefectures} />
    <Route exact path="/prefectures/:id" component={AsyncAddEditPrefectures} />
    <Route exact path="/areas" component={AsyncArea} />
    <Route exact path="/area/create" component={AsyncAddNewArea} />
    <Route exact path="/area-location/:id" component={AsyncAreaLocations} />
    <Route
      exact
      path="/after-main-matching"
      component={AsyncParticipantAfterMainMatching}
    />
    <Route exact path="/migrations/log" component={AsyncMigrationLog} />
    <Route exact path="/user/view-profile/:id" component={AsyncViewProfile} />
    <Route exact path="/education-group" component={AsyncEducationGroup} />
    <Route
      exact
      path="/match-info-settings"
      component={AsyncMatchInfoSettings}
    />
    <Route
      exact
      path="/match-info-settings/create"
      component={AsyncMatchInfoSettingsAddNew}
    />
    <Route exact path="/chat-history" component={AsyncChatHistory} />
    <Route
      exact
      path="/dating-day-settings"
      component={AsyncDatingDaySettings}
    />
    <Route exact path="/admin-management" component={AsyncAdminManagement} />
    <Route exact path="/push-notifications" component={AsyncPushNotification} />
    <Route
      exact
      path="/second-push-notifications"
      component={AsyncSecondPushNotification}
    />
    <Route
      exact
      path="/skip-notifications-for-users"
      component={AsyncSkipNotificationsForUsers}
    />
    <Route
      exact
      path="/skip-sms-notifications-for-users"
      component={AsyncSkipSMSNotificationsForUsers}
    />
    <Route
      exact
      path="/skip-push-notifications-for-users"
      component={AsyncSkipPushNotificationsForUsers}
    />
    <Route
      exact
      path="/feedback-flexible-questions"
      component={AsyncFeedbackFlexibleQuestions}
    />
    <Route
      exact
      path="/feedback-flexible-questions/:id"
      component={AsyncStoreFeedbackFlexibleQuestions}
    />
    <Route exact path="/download-csv" component={AsyncDownloadCsv} />
    <Route exact path="/certification" component={AsyncCertification} />
    <Route
      exact
      path="/auto-check-settings"
      component={AsyncAutoCheckNotifications}
    />
    <Route
      exact
      path="/send-bulk-push-notifications"
      component={AsyncSendBulkPushNotification}
    />
    <Route
      exact
      path="/prefecture-settings"
      component={AsyncPrefectureSettings}
    />
    <Route exact path="/common-variables" component={AsyncCommonVariables} />
    <Route
      exact
      path="/common-variable/:id"
      component={AsyncAddEditCommonVariable}
    />
    <Route exact path="/manage-banner" component={AsyncManageBanner} />
    <Route exact path="/manage-banner/:id" component={AsyncAddEditBanner} />
  </Switch>
);

function App() {
  return (
    <BrowserRouter>
      {routes}
      <ToastContainer />
      {/*<Menu/>*/}
      <LoadingSpinner />
      {/*<ErrorPopup/>*/}
    </BrowserRouter>
  );
}

export default App;
