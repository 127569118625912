import * as types from '../actions/types';

const initialState = {
  isLoading: false
};

const downloadCsvReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.DOWNLOAD_SUBSCRIPTION_CSV_REQUESTED:
      return { ...state, isLoading: true, error: null };
    case types.DOWNLOAD_SUBSCRIPTION_CSV_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        error: null
      };
    case types.DOWNLOAD_SUBSCRIPTION_CSV_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.error
      };
    case types.DOWNLOAD_USER_ID_CSV_REQUESTED:
      return { ...state, isLoading: true, error: null };
    case types.DOWNLOAD_USER_ID_CSV_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        error: null
      };
    case types.DOWNLOAD_USER_ID_CSV_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.error
      };
    default:
      return state;
  }
};

export default downloadCsvReducer;
