//AUTHENTICATION
export const LOGIN_REQUESTED = 'LOGIN_REQUESTED';
export const LOGIN_FAILED = 'LOGIN_FAILED';
export const LOGIN_SUCCEEDED = 'LOGIN_SUCCEEDED';
export const UPDATE_EMAIL = 'UPDATE_EMAIL';
export const LOGOUT_REQUESTED = 'LOGOUT_REQUESTED';
export const LOGOUT_FAILED = 'LOGOUT_FAILED';
export const LOGOUT_SUCCEEDED = 'LOGOUT_SUCCEEDED';

export const HOME_SCREEN_REQUESTED = 'HOME_SCREEN_REQUESTED';

//temp code
export const TEMP_CODE_REQUESTED = 'TEMP_CODE_REQUESTED';
export const TEMP_CODE_SUCCEEDED = 'TEMP_CODE_SUCCEEDED';
export const TEMP_CODE_FAILED = 'TEMP_CODE_FAILED';

//Prefecture
export const GET_PREFECTURE_REQUESTED = 'GET_PREFECTURE_REQUESTED';
export const GET_PREFECTURE_SUCCEEDED = 'GET_PREFECTURE_SUCCEEDED';
export const GET_PREFECTURE_FAILED = 'GET_PREFECTURE_FAILED';
export const CREATE_PREFECTURE_REQUESTED = 'CREATE_PREFECTURE_REQUESTED';
export const CREATE_PREFECTURE_SUCCEEDED = 'CREATE_PREFECTURE_SUCCEEDED';
export const CREATE_PREFECTURE_FAILED = 'CREATE_PREFECTURE_FAILED';
export const UPDATE_PREFECTURE_REQUESTED = 'UPDATE_PREFECTURE_REQUESTED';
export const UPDATE_PREFECTURE_SUCCEEDED = 'UPDATE_PREFECTURE_SUCCEEDED';
export const UPDATE_PREFECTURE_FAILED = 'UPDATE_PREFECTURE_FAILED';
export const DELETE_PREFECTURE_REQUESTED = 'DELETE_PREFECTURE_REQUESTED';
export const DELETE_PREFECTURE_SUCCEEDED = 'DELETE_PREFECTURE_SUCCEEDED';
export const DELETE_PREFECTURE_FAILED = 'DELETE_PREFECTURE_FAILED';
export const UPDATE_STATUS_PREFECTURE_REQUESTED =
  'UPDATE_STATUS_PREFECTURE_REQUESTED';
export const UPDATE_STATUS_PREFECTURE_SUCCEEDED =
  'UPDATE_STATUS_PREFECTURE_SUCCEEDED';
export const UPDATE_STATUS_PREFECTURE_FAILED =
  'UPDATE_STATUS_PREFECTURE_FAILED';

//Area
export const GET_AREA_REQUESTED = 'GET_AREA_REQUESTED';
export const GET_AREA_SUCCEEDED = 'GET_AREA_SUCCEEDED';
export const GET_AREA_FAILED = 'GET_AREA_FAILED';
export const CREATE_AREA_REQUESTED = 'CREATE_AREA_REQUESTED';
export const CREATE_AREA_SUCCEEDED = 'CREATE_AREA_SUCCEEDED';
export const CREATE_AREA_FAILED = 'CREATE_AREA_FAILED';
export const UPDATE_AREA_REQUESTED = 'UPDATE_AREA_REQUESTED';
export const UPDATE_AREA_SUCCEEDED = 'UPDATE_AREA_SUCCEEDED';
export const UPDATE_AREA_FAILED = 'UPDATE_AREA_FAILED';
export const UPDATE_STATUS_AREA_REQUESTED = 'UPDATE_STATUS_AREA_REQUESTED';
export const UPDATE_STATUS_AREA_SUCCEEDED = 'UPDATE_STATUS_AREA_SUCCEEDED';
export const UPDATE_STATUS_AREA_FAILED = 'UPDATE_STATUS_AREA_REQUESTED';
export const DELETE_AREA_REQUESTED = 'DELETE_AREA_REQUESTED';
export const DELETE_AREA_SUCCEEDED = 'DELETE_AREA_SUCCEEDED';
export const DELETE_AREA_FAILED = 'DELETE_AREA_FAILED';

//Rematching
export const IMPORT_REMATCHING_REQUESTED = 'IMPORT_REMATCHING_REQUESTED';
export const IMPORT_REMATCHING_SUCCEEDED = 'IMPORT_REMATCHING_SUCCEEDED';
export const IMPORT_REMATCHING_FAILED = 'IMPORT_REMATCHING_FAILED';

//Chat
export const GET_ROOMS_REQUESTED = 'GET_ROOMS_REQUESTED';
export const GET_ROOMS_SUCCEEDED = 'GET_ROOMS_SUCCEEDED';
export const GET_ROOMS_FAILED = 'GET_ROOMS_FAILED';

export const SEARCH_ROOMS_REQUESTED = 'SEARCH_ROOMS_REQUESTED';
export const SEARCH_ROOMS_SUCCEEDED = 'SEARCH_ROOMS_SUCCEEDED';
export const SEARCH_ROOMS_FAILED = 'SEARCH_ROOMS_FAILED';

export const MIGRATE_MESSAGES_REQUESTED = 'MIGRATE_MESSAGES_REQUESTED';
export const MIGRATE_MESSAGES_SUCCEEDED = 'MIGRATE_MESSAGES_SUCCEEDED';
export const MIGRATE_MESSAGES_FAILED = 'MIGRATE_MESSAGES_FAILED';

export const GET_MESSAGES_REQUESTED = 'GET_MESSAGES_REQUESTED';
export const GET_MESSAGES_SUCCEEDED = 'GET_MESSAGES_SUCCEEDED';
export const GET_MESSAGES_FAILED = 'GET_MESSAGES_FAILED';

export const SEARCH_MESSAGES_REQUESTED = 'SEARCH_MESSAGES_REQUESTED';
export const SEARCH_MESSAGES_SUCCEEDED = 'SEARCH_MESSAGES_SUCCEEDED';
export const SEARCH_MESSAGES_FAILED = 'SEARCH_MESSAGES_FAILED';

export const GET_ROOMS_DETAIL_REQUESTED = 'GET_ROOMS_DETAIL_REQUESTED';
export const GET_ROOMS_DETAIL_SUCCEEDED = 'GET_ROOMS_DETAIL_SUCCEEDED';
export const GET_ROOMS_DETAIL_FAILED = 'GET_ROOMS_DETAIL_FAILED';
export const CLEAR_ROOMS_DETAIL = 'CLEAR_ROOMS_DETAIL';

//Dating Place
export const GET_CAFE_REQUESTED = 'GET_CAFE_REQUESTED';
export const GET_CAFE_SUCCEEDED = 'GET_CAFE_SUCCEEDED';
export const GET_CAFE_FAILED = 'GET_CAFE_FAILED';
export const CREATE_CAFE_REQUESTED = 'CREATE_CAFE_REQUESTED';
export const CREATE_CAFE_SUCCEEDED = 'CREATE_CAFE_SUCCEEDED';
export const CREATE_CAFE_FAILED = 'CREATE_CAFE_FAILED';
export const UPDATE_CAFE_REQUESTED = 'UPDATE_CAFE_REQUESTED';
export const UPDATE_CAFE_SUCCEEDED = 'UPDATE_CAFE_SUCCEEDED';
export const UPDATE_CAFE_FAILED = 'UPDATE_CAFE_FAILED';
export const DELETE_CAFE_REQUESTED = 'DELETE_CAFE_REQUESTED';
export const DELETE_CAFE_SUCCEEDED = 'DELETE_CAFE_SUCCEEDED';
export const DELETE_CAFE_FAILED = 'DELETE_CAFE_FAILED';
export const CHANGE_APPROVE_CAFE_REQUESTED = 'CHANGE_APPROVE_CAFE_REQUESTED';
export const CHANGE_APPROVE_CAFE_SUCCEEDED = 'CHANGE_APPROVE_CAFE_SUCCEEDED';
export const CHANGE_APPROVE_CAFE_FAILED = 'CHANGE_APPROVE_CAFE_FAILED';
export const CHANGE_BLACK_ACTIVE_CAFE_REQUESTED = 'CHANGE_BLACK_ACTIVE_CAFE_REQUESTED';
export const CHANGE_BLACK_ACTIVE_CAFE_SUCCEEDED = 'CHANGE_BLACK_ACTIVE_CAFE_SUCCEEDED';
export const CHANGE_BLACK_ACTIVE_CAFE_FAILED = 'CHANGE_BLACK_ACTIVE_CAFE_FAILED';
export const IMPORT_CAFE_DATING_CSV_REQUESTED =
  'IMPORT_CAFE_DATING_CSV_REQUESTED';

export const IMPORT_CAFE_DATING_CSV_SUCCEEDED =
  'IMPORT_CAFE_DATING_CSV_SUCCEEDED';
export const IMPORT_CAFE_DATING_CSV_FAILED = 'IMPORT_CAFE_DATING_CSV_FAILED';
export const GET_CAFE_MAP_INFO_REQUESTED = 'GET_CAFE_MAP_INFO_REQUESTED';
export const GET_CAFE_MAP_INFO_SUCCEEDED = 'GET_CAFE_MAP_INFO_SUCCEEDED';
export const GET_CAFE_MAP_INFO_FAILED = 'GET_CAFE_MAP_INFO_FAILED';

//Notification
export const GET_NOTIFICATION_REQUESTED = 'GET_NOTIFICATION_REQUESTED';
export const GET_NOTIFICATION_SUCCEEDED = 'GET_NOTIFICATION_SUCCEEDED';
export const GET_NOTIFICATION_FAILED = 'GET_NOTIFICATION_FAILED';
export const GET_USER_NOTIFICATION_REQUESTED =
  'GET_USER_NOTIFICATION_REQUESTED';
export const GET_USER_NOTIFICATION_SUCCEEDED =
  'GET_USER_NOTIFICATION_SUCCEEDED';
export const GET_USER_NOTIFICATION_FAILED = 'GET_USER_NOTIFICATION_FAILED';
export const CREATE_NOTIFICATION_REQUESTED = 'CREATE_NOTIFICATION_REQUESTED';
export const CREATE_NOTIFICATION_SUCCEEDED = 'CREATE_NOTIFICATION_SUCCEEDED';
export const CREATE_NOTIFICATION_FAILED = 'CREATE_NOTIFICATION_FAILED';
export const UPDATE_NOTIFICATION_REQUESTED = 'UPDATE_NOTIFICATION_REQUESTED';
export const UPDATE_NOTIFICATION_SUCCEEDED = 'UPDATE_NOTIFICATION_SUCCEEDED';
export const UPDATE_NOTIFICATION_FAILED = 'UPDATE_NOTIFICATION_FAILED';
export const DELETE_NOTIFICATION_REQUESTED = 'DELETE_NOTIFICATION_REQUESTED';
export const DELETE_NOTIFICATION_SUCCEEDED = 'DELETE_NOTIFICATION_SUCCEEDED';
export const DELETE_NOTIFICATION_FAILED = 'DELETE_NOTIFICATION_FAILED';
export const SEND_NOTIFICATION_REQUESTED = 'SEND_NOTIFICATION_REQUESTED';
export const SEND_NOTIFICATION_SUCCEEDED = 'SEND_NOTIFICATION_SUCCEEDED';
export const SEND_NOTIFICATION_FAILED = 'SEND_NOTIFICATION_FAILED';
export const READ_NOTIFICATION_REQUESTED = 'READ_NOTIFICATION_REQUESTED';
export const READ_NOTIFICATION_SUCCEEDED = 'READ_NOTIFICATION_SUCCEEDED';
export const READ_NOTIFICATION_FAILED = 'READ_NOTIFICATION_FAILED';

export const SEND_BULK_MESSAGE_REQUESTED = 'SEND_BULK_MESSAGE_REQUESTED';
export const SEND_BULK_MESSAGE_SUCCEEDED = 'SEND_BULK_MESSAGE_SUCCEEDED';
export const SEND_BULK_MESSAGE_FAILED = 'SEND_BULK_MESSAGE_FAILED';

export const IMPORT_SKIP_NOTIFICATIONS_FOR_USERS_REQUESTED =
  'IMPORT_SKIP_NOTIFICATIONS_FOR_USERS_REQUESTED';
export const IMPORT_SKIP_NOTIFICATIONS_FOR_USERS_SUCCEEDED =
  'IMPORT_SKIP_NOTIFICATIONS_FOR_USERS_SUCCEEDED';
export const IMPORT_SKIP_NOTIFICATIONS_FOR_USERS_FAILED =
  'IMPORT_SKIP_NOTIFICATIONS_FOR_USERS_FAILED';

//UPLOAD IMAGE
export const STORE_IMAGE_REQUESTED = 'STORE_IMAGE_REQUESTED';
export const STORE_IMAGE_SUCCEDDED = 'STORE_IMAGE_SUCCEDDED';
export const STORE_IMAGE_FAILED = 'STORE_IMAGE_FAILED';

//SETTINGS
export const GET_SETTINGS_REQUESTED = 'GET_SETTINGS_REQUESTED';
export const GET_SETTINGS_SUCCEEDED = 'GET_SETTINGS_SUCCEEDED';
export const GET_SETTINGS_FAILED = 'GET_SETTINGS_FAILED';
export const CREATE_SETTINGS_REQUESTED = 'CREATE_SETTINGS_REQUESTED';
export const CREATE_SETTINGS_SUCCEEDED = 'CREATE_SETTINGS_SUCCEEDED';
export const CREATE_SETTINGS_FAILED = 'CREATE_SETTINGS_FAILED';
export const UPDATE_SETTINGS_REQUESTED = 'UPDATE_SETTINGS_REQUESTED';
export const UPDATE_SETTINGS_SUCCEEDED = 'UPDATE_SETTINGS_SUCCEEDED';
export const UPDATE_SETTINGS_FAILED = 'UPDATE_SETTINGS_FAILED';
export const DELETE_SETTINGS_REQUESTED = 'DELETE_SETTINGS_REQUESTED';
export const DELETE_SETTINGS_SUCCEEDED = 'DELETE_SETTINGS_SUCCEEDED';
export const DELETE_SETTINGS_FAILED = 'DELETE_SETTINGS_FAILED';

//Time settings
export const GET_TIMING_REQUESTED = 'GET_TIMING_REQUESTED';
export const GET_TIMING_SUCCEEDED = 'GET_TIMING_SUCCEEDED';
export const GET_TIMING_FAILED = 'GET_TIMING_FAILED';
export const UPDATE_TIMING_REQUESTED = 'UPDATE_TIMING_REQUESTED';
export const UPDATE_TIMING_SUCCEEDED = 'UPDATE_TIMING_SUCCEEDED';
export const UPDATE_TIMING_FAILED = 'UPDATE_TIMING_FAILED';

//PARTICIPANTS
export const GET_PARTICIPANTS_REQUESTED = 'GET_PARTICIPANTS_REQUESTED';
export const GET_PARTICIPANTS_SUCCEEDED = 'GET_PARTICIPANTS_SUCCEEDED';
export const GET_PARTICIPANTS_FAILED = 'GET_PARTICIPANTS_FAILED';
export const GET_PARTICIPANTS_USER_HISTORY_REQUESTED =
  'GET_PARTICIPANTS_USER_HISTORY_REQUESTED';
export const GET_PARTICIPANTS_USER_HISTORY_SUCCEEDED =
  'GET_PARTICIPANTS_USER_HISTORY_SUCCEEDED';
export const GET_PARTICIPANTS_USER_HISTORY_FAILED =
  'GET_PARTICIPANTS_USER_HISTORY_FAILED';
export const MIGRATE_REQUESTED = 'MIGRATE_REQUESTED';
export const MIGRATE_SUCCEEDED = 'MIGRATE_SUCCEEDED';
export const MIGRATE_FAILED = 'MIGRATE_FAILED';
export const RESET_LAST_WEEK_REQUESTED = 'RESET_LAST_WEEK_REQUESTED';
export const RESET_LAST_WEEK_SUCCEEDED = 'RESET_LAST_WEEK_SUCCEEDED';
export const RESET_LAST_WEEK_FAILED = 'RESET_LAST_WEEK_SUCCEEDED';
export const GET_PARTICIPANTS_MAIN_MATCH_AND_REMATCH_REQUESTED =
  'GET_PARTICIPANTS_MAIN_MATCH_AND_REMATCH_REQUESTED';
export const GET_PARTICIPANTS_MAIN_MATCH_AND_REMATCH_SUCCEEDED =
  'GET_PARTICIPANTS_MAIN_MATCH_AND_REMATCH_SUCCEEDED';
export const GET_PARTICIPANTS_MAIN_MATCH_AND_REMATCH_FAILED =
  'GET_PARTICIPANTS_MAIN_MATCH_AND_REMATCH_FAILED';
export const CANCEL_AWAITING_PARTICIPANT_REQUESTED =
  'CANCEL_AWAITING_PARTICIPANT_REQUESTED';
export const CANCEL_AWAITING_PARTICIPANT_SUCCEEDED =
  'CANCEL_AWAITING_PARTICIPANT_SUCCEEDED';
export const CANCEL_AWAITING_PARTICIPANT_FAILED =
  'CANCEL_AWAITING_PARTICIPANT_FAILED';
export const DELETE_MAIN_MATCH_PARTICIPANT_REQUESTED =
  'DELETE_MAIN_MATCH_PARTICIPANT_REQUESTED';
export const DELETE_MAIN_MATCH_PARTICIPANT_SUCCEEDED =
  'DELETE_MAIN_MATCH_PARTICIPANT_SUCCEEDED';
export const DELETE_MAIN_MATCH_PARTICIPANT_FAILED =
  'DELETE_MAIN_MATCH_PARTICIPANT_FAILED';
export const IMPORT_MAIN_MATCH_PARTICIPANT_CSV_REQUESTED =
  'IMPORT_MAIN_MATCH_PARTICIPANT_CSV_REQUESTED';
export const IMPORT_MAIN_MATCH_PARTICIPANT_CSV_SUCCEEDED =
  'IMPORT_MAIN_MATCH_PARTICIPANT_CSV_SUCCEEDED';
export const IMPORT_MAIN_MATCH_PARTICIPANT_CSV_FAILED =
  'IMPORT_MAIN_MATCH_PARTICIPANT_CSV_FAILED';

export const GET_USERS_AVAILABLE_REQUESTED = 'GET_USERS_AVAILABLE_REQUESTED';
export const GET_USERS_AVAILABLE_SUCCEEDED = 'GET_USERS_AVAILABLE_SUCCEEDED';
export const GET_USERS_AVAILABLE_FAILED = 'GET_USERS_AVAILABLE_FAILED';

export const HOME_SCREEN_SUCCEEDED = 'HOME_SCREEN_SUCCEEDED';
export const HOME_SCREEN_FAILED = 'HOME_SCREEN_FAILED';
export const CLEAR_HOMESCREEN = 'CLEAR_HOMESCREEN';

export const CURRENT_USER_RESET_MATCH_DATA = 'CURRENT_USER_RESET_MATCH_DATA';
export const CURRENT_USER_GET_MATCH_DATA_REQUESTED =
  'CURRENT_USER_GET_MATCH_DATA_REQUESTED';
export const CURRENT_USER_GET_MATCH_DATA_SUCCEEDED =
  'CURRENT_USER_GET_MATCH_DATA_SUCCEEDED';
export const CURRENT_USER_GET_MATCH_DATA_FAILED =
  'CURRENT_USER_GET_MATCH_DATA_FAILED';
export const PARTNER_REFLECTED_COUPON_POPUP_SHOWN =
  'PARTNER_REFLECTED_COUPON_POPUP_SHOWN';
export const UPDATE_USER_PARTICIPATION_DATES =
  'UPDATE_USER_PARTICIPATION_DATES';

// job preference mapping
export const GET_JOB_PREFERENCE_MAPPING_REQUESTED =
  'GET_JOB_PREFERENCE_MAPPING_REQUESTED';
export const GET_JOB_PREFERENCE_MAPPING_SUCCEEDED =
  'GET_JOB_PREFERENCE_MAPPING_SUCCEEDED';
export const GET_JOB_PREFERENCE_MAPPING_FAILED =
  'GET_JOB_PREFERENCE_MAPPING_FAILED';
export const GET_JOB_PREFERENCE_MAPPING_OPTIONS_REQUESTED =
  'GET_JOB_PREFERENCE_MAPPING_OPTIONS_REQUESTED';
export const GET_JOB_PREFERENCE_MAPPING_OPTIONS_SUCCEEDED =
  'GET_JOB_PREFERENCE_MAPPING_OPTIONS_SUCCEEDED';
export const GET_JOB_PREFERENCE_MAPPING_OPTIONS_FAILED =
  'GET_JOB_PREFERENCE_MAPPING_OPTIONS_FAILED';
export const CREATE_JOB_PREFERENCE_MAPPING_REQUESTED =
  'CREATE_JOB_PREFERENCE_MAPPING_REQUESTED';
export const CREATE_JOB_PREFERENCE_MAPPING_SUCCEEDED =
  'CREATE_JOB_PREFERENCE_MAPPING_SUCCEEDED';
export const CREATE_JOB_PREFERENCE_MAPPING_FAILED =
  'CREATE_JOB_PREFERENCE_MAPPING_FAILED';
export const UPDATE_JOB_PREFERENCE_MAPPING_REQUESTED =
  'UPDATE_JOB_PREFERENCE_MAPPING_REQUESTED';
export const UPDATE_JOB_PREFERENCE_MAPPING_SUCCEEDED =
  'UPDATE_JOB_PREFERENCE_MAPPING_SUCCEEDED';
export const UPDATE_JOB_PREFERENCE_MAPPING_FAILED =
  'UPDATE_JOB_PREFERENCE_MAPPING_FAILED';
export const DELETE_JOB_PREFERENCE_MAPPING_REQUESTED =
  'DELETE_JOB_PREFERENCE_MAPPING_REQUESTED';
export const DELETE_JOB_PREFERENCE_MAPPING_SUCCEEDED =
  'DELETE_JOB_PREFERENCE_MAPPING_SUCCEEDED';
export const DELETE_JOB_PREFERENCE_MAPPING_FAILED =
  'DELETE_JOB_PREFERENCE_MAPPING_FAILED';



//match user pair list
export const GET_MATCH_USE_PAIR_LIST_REQUESTED =
  'GET_MATCH_USE_PAIR_LIST_REQUESTED';
export const GET_MATCH_USE_PAIR_LIST_SUCCEEDED =
  'GET_MATCH_USE_PAIR_LIST_SUCCEEDED';
export const GET_MATCH_USE_PAIR_LIST_FAILED = 'GET_MATCH_USE_PAIR_LIST_FAILED';
export const CREATE_MATCH_USE_PAIR_LIST_REQUESTED =
  'CREATE_MATCH_USE_PAIR_LIST_REQUESTED';
export const CREATE_MATCH_USE_PAIR_LIST_SUCCEEDED =
  'CREATE_MATCH_USE_PAIR_LIST_SUCCEEDED';
export const CREATE_MATCH_USE_PAIR_LIST_FAILED =
  'CREATE_MATCH_USE_PAIR_LIST_FAILED';
export const GET_UPDATE_MATCH_USE_PAIR_LIST_REQUESTED =
  'GET_UPDATE_MATCH_USE_PAIR_LIST_REQUESTED';
export const GET_UPDATE_MATCH_USE_PAIR_LIST_SUCCEEDED =
  'GET_UPDATE_MATCH_USE_PAIR_LIST_SUCCEEDED';
export const GET_UPDATE_MATCH_USE_PAIR_LIST_FAILED =
  'GET_UPDATE_MATCH_USE_PAIR_LIST_FAILED';
export const UPDATE_MATCH_USE_PAIR_LIST_REQUESTED =
  'UPDATE_MATCH_USE_PAIR_LIST_REQUESTED';
export const UPDATE_MATCH_USE_PAIR_LIST_SUCCEEDED =
  'UPDATE_MATCH_USE_PAIR_LIST_SUCCEEDED';
export const UPDATE_MATCH_USE_PAIR_LIST_FAILED =
  'UPDATE_MATCH_USE_PAIR_LIST_FAILED';
export const DELETE_MATCH_USE_PAIR_LIST_REQUESTED =
  'DELETE_MATCH_USE_PAIR_LIST_REQUESTED';
export const DELETE_MATCH_USE_PAIR_LIST_SUCCEEDED =
  'DELETE_MATCH_USE_PAIR_LIST_SUCCEEDED';
export const DELETE_MATCH_USE_PAIR_LIST_FAILED =
  'DELETE_MATCH_USE_PAIR_LIST_FAILED';

export const USER_PROFILE_REQUESTED = 'USER_PROFILE_REQUESTED';
export const USER_PROFILE_SUCCEEDED = 'USER_PROFILE_SUCCEEDED';
export const USER_PROFILE_FAILED = 'USER_PROFILE_FAILED';

export const USER_DETAIL_REQUESTED = 'USER_DETAIL_REQUESTED';
export const USER_DETAIL_SUCCEEDED = 'USER_DETAIL_SUCCEEDED';
export const USER_DETAIL_FAILED = 'USER_DETAIL_FAILED';

export const GET_USER_COUPON_REQUESTED = 'GET_USER_COUPON_REQUESTED';
export const GET_USER_COUPON_SUCCEEDED = 'GET_USER_COUPON_SUCCEEDED';
export const GET_USER_COUPON_FAILED = 'GET_USER_COUPON_FAILED';
export const DISCARD_USER_COUPON_REQUESTED = 'DISCARD_USER_COUPON_REQUESTED';
export const DISCARD_USER_COUPON_SUCCEEDED = 'DISCARD_USER_COUPON_SUCCEEDED';
export const DISCARD_USER_COUPON_FAILED = 'DISCARD_USER_COUPON_FAILED';

export const GET_USER_TRIAL_REQUESTED = 'GET_USER_TRIAL_REQUESTED';
export const GET_USER_TRIAL_SUCCEEDED = 'GET_USER_TRIAL_SUCCEEDED';
export const GET_USER_TRIAL_FAILED = 'GET_USER_TRIAL_FAILED';

export const GET_USER_DATING_HISTORY_REQUESTED =
  'GET_USER_DATING_HISTORY_REQUESTED';
export const GET_USER_DATING_HISTORY_SUCCEEDED =
  'GET_USER_DATING_HISTORY_SUCCEEDED';
export const GET_USER_DATING_HISTORY_FAILED = 'GET_USER_DATING_HISTORY_FAILED';

export const GET_USER_DATING_REPORT_REQUESTED =
  'GET_USER_DATING_REPORT_REQUESTED';
export const GET_USER_DATING_REPORT_SUCCEEDED =
  'GET_USER_DATING_REPORT_SUCCEEDED';
export const GET_USER_DATING_REPORT_FAILED = 'GET_USER_DATING_REPORT_FAILED';

export const GET_USER_ACTIVITY_LOG_REQUESTED =
  'GET_USER_ACTIVITY_LOG_REQUESTED';
export const GET_USER_ACTIVITY_LOG_SUCCEEDED =
  'GET_USER_ACTIVITY_LOG_SUCCEEDED';
export const GET_USER_ACTIVITY_LOG_FAILED = 'GET_USER_ACTIVITY_LOG_FAILED';

export const USE_COUPON_REQUESTED = 'USE_COUPON_REQUESTED';
export const USE_COUPON_SUCCEEDED = 'USE_COUPON_SUCCEEDED';
export const USE_COUPON_FAILED = 'USE_COUPON_FAILED';

export const CANCEL_DATE_REQUESTED = 'CANCEL_DATE_REQUESTED';
export const CANCEL_DATE_SUCCEEDED = 'CANCEL_DATE_SUCCEEDED';
export const CANCEL_DATE_FAILED = 'CANCEL_DATE_FAILED';

export const CANCEL_DATE_BY_PARTNER_REQUESTED =
  'CANCEL_DATE_BY_PARTNER_REQUESTED';
export const CANCEL_DATE_BY_PARTNER_SUCCEEDED =
  'CANCEL_DATE_BY_PARTNER_SUCCEEDED';
export const CANCEL_DATE_BY_PARTNER_FAILED = 'CANCEL_DATE_BY_PARTNER_FAILED';

export const POPUPS_REQUESTED = 'POPUPS_REQUESTED';
export const POPUPS_REQUEST_SUCCEEDED = 'POPUPS_REQUEST_SUCCEEDED';
export const POPUPS_REQUEST_FAILED = 'POPUPS_REQUEST_FAILED';

export const DELETE_POPUP_REQUESTED = 'DELETE_POPUP_REQUESTED';
export const DELETE_POPUP_REQUEST_SUCCEEDED = 'DELETE_POPUP_REQUEST_SUCCEEDED';
export const DELETE_POPUP_REQUEST_FAILED = 'DELETE_POPUP_REQUEST_FAILED';

export const SHOW_LOADING = 'SHOW_LOADING';
export const HIDE_LOADING = 'HIDE_LOADING';

export const SHOW_ERROR = 'SHOW_ERROR';
export const HIDE_ERROR = 'HIDE_ERROR';

export const CHAT_ROOM_REQUESTED = 'CHAT_ROOM_REQUESTED';
export const CHAT_ROOM_REQUEST_SUCCEEDED = 'CHAT_ROOM_REQUEST_SUCCEEDED';
export const CHAT_ROOM_REQUEST_FAILED = 'CHAT_ROOM_REQUEST_FAILED';

export const CHAT_HISTORY_REQUESTED = 'CHAT_HISTORY_REQUESTED';
export const CHAT_HISTORY_REQUEST_SUCCEEDED = 'CHAT_HISTORY_REQUEST_SUCCEEDED';
export const CHAT_HISTORY_REQUEST_FAILED = 'CHAT_HISTORY_REQUEST_FAILED';

export const GET_CHAT_MESSAGES_REQUESTED = 'GET_CHAT_MESSAGES_REQUESTED';
export const GET_CHAT_MESSAGES_REQUEST_SUCCEEDED =
  'GET_CHAT_MESSAGES_REQUEST_SUCCEEDED';
export const GET_CHAT_MESSAGES_REQUEST_FAILED =
  'GET_CHAT_MESSAGES_REQUEST_FAILED';

export const GET_TWILIO_TOKEN_REQUESTED = 'GET_TWILIO_TOKEN_REQUESTED';
export const GET_TWILIO_TOKEN_SUCCEEDED = 'GET_TWILIO_TOKEN_SUCCEEDED';
export const GET_TWILIO_TOKEN_FAILED = 'GET_TWILIO_TOKEN_FAILED';

export const SET_PARTICIPATION_REQUESTED = 'SET_PARTICIPATION_REQUESTED';
export const SET_PARTICIPATION_SUCCEEDED = 'SET_PARTICIPATION_SUCCEEDED';
export const SET_PARTICIPATION_FAILED = 'SET_PARTICIPATION_FAILED';
export const SET_PARTICIPATION_FAILED_NEED_ACTION =
  'SET_PARTICIPATION_FAILED_NEED_ACTION';

export const SHOW_MAINTAIN = 'SHOW_MAINTAIN';

export const CURRENT_USER_CHANGE_COST_PLAN_REQUESTED =
  'CURRENT_USER_CHANGE_COST_PLAN_REQUESTED';
export const CURRENT_USER_CHANGE_COST_PLAN_SUCCEEDED =
  'CURRENT_USER_CHANGE_COST_PLAN_SUCCEEDED';
export const CURRENT_USER_CHANGE_COST_PLAN_FAILED =
  'CURRENT_USER_CHANGE_COST_PLAN_FAILED';
export const CURRENT_USER_CANCEL_COST_PLAN_REQUESTED =
  'CURRENT_USER_CANCEL_COST_PLAN_REQUESTED';

export const SHOW_TOOLTIPS = 'SHOW_TOOLTIPS';
export const HIDE_TOOLTIPS = 'HIDE_TOOLTIPS';

export const DEACTIVE_PROFILE_REQUESTED = 'DEACTIVE_PROFILE_REQUESTED';
export const DEACTIVE_PROFILE_SUCCEEDED = 'DEACTIVE_PROFILE_SUCCEEDED';
export const DEACTIVE_PROFILE_FAILED = 'DEACTIVE_PROFILE_FAILED';

export const OPEN_DRAWER = 'OPEN_DRAWER';
export const CLOSE_DRAWER = 'CLOSE_DRAWER';

export const CANCEL_PROFILE_REQUESTED = 'CANCEL_PROFILE_REQUESTED';
export const CANCEL_PROFILE_SUCCEEDED = 'CANCEL_PROFILE_SUCCEEDED';
export const CANCEL_PROFILE_FAILED = 'CANCEL_PROFILE_FAILED';

export const ADD_REQUEST_TIME_CHANGE_REQUESTED =
  'ADD_REQUEST_TIME_CHANGE_REQUESTED';
export const ADD_REQUEST_TIME_CHANGE_SUCCEEDED =
  'ADD_REQUEST_TIME_CHANGE_SUCCEEDED';
export const ADD_REQUEST_TIME_CHANGE_FAILED = 'ADD_REQUEST_TIME_CHANGE_FAILED';

export const UPDATE_REQUEST_TIME_CHANGE_REQUESTED =
  'UPDATE_REQUEST_TIME_CHANGE_REQUESTED';
export const UPDATE_REQUEST_TIME_CHANGE_SUCCEEDED =
  'UPDATE_REQUEST_TIME_CHANGE_SUCCEEDED';
export const UPDATE_REQUEST_TIME_CHANGE_FAILED =
  'UPDATE_REQUEST_TIME_CHANGE_FAILED';

export const SAVE_USER_FEEDBACK_REQUESTED = 'SAVE_USER_FEEDBACK_REQUESTED';
export const SAVE_USER_FEEDBACK_SUCCEEDED = 'SAVE_USER_FEEDBACK_SUCCEEDED';
export const SAVE_USER_FEEDBACK_FAILED = 'SAVE_USER_FEEDBACK_FAILED';

export const GET_USER_FEEDBACK_DETAILS_REQUESTED =
  'GET_USER_FEEDBACK_DETAILS_REQUESTED';
export const GET_USER_FEEDBACK_DETAILS_SUCCEEDED =
  'GET_USER_FEEDBACK_DETAILS_SUCCEEDED';
export const GET_USER_FEEDBACK_DETAILS_FAILED =
  'GET_USER_FEEDBACK_DETAILS_FAILED';

export const INVITE_FRIENDS_DATA_REQUESTED = 'INVITE_FRIENDS_DATA_REQUESTED';
export const INVITE_FRIENDS_DATA_SUCCEEDED = 'INVITE_FRIENDS_DATA_SUCCEEDED';
export const INVITE_FRIENDS_DATA_FAILED = 'INVITE_FRIENDS_DATA_FAILED';
export const COMPLETE_INVITATION_FRIENDS_DATA_REQUESTED =
  'COMPLETE_INVITATION_FRIENDS_DATA_REQUESTED';
export const MATCH_PROFILE_INFO_REQUEST = 'MATCH_PROFILE_INFO_REQUEST';
export const MATCH_PROFILE_INFO_SUCCEEDED = 'MATCH_PROFILE_INFO_SUCCEEDED';
export const MATCH_PROFILE_INFO_FAILED = 'MATCH_PROFILE_INFO_FAILED';

export const EXCHANGE_COUPON_REQUEST = 'EXCHANGE_COUPON_REQUEST';
export const EXCHANGE_COUPON_SUCCEDDED = 'EXCHANGE_COUPON_SUCCEDDED';
export const EXCHANGE_COUPON_FAILED = 'EXCHANGE_COUPON_FAILED';
export const COUPON_LIST_REQUEST = 'COUPON_LIST_REQUEST';
export const COUPON_LIST_SUCCEEDED = 'COUPON_LIST_SUCCEEDED';
export const COUPON_LIST_FAILED = 'COUPON_LIST_FAILED';
export const COUPON_LIST_RESET = 'COUPON_LIST_RESET';
export const COUPON_LIST_DECREMENT = 'COUPON_LIST_DECREMENT';

export const EXTEND_TRIAL_BY_SUGGESTION_REQUESTED =
  'EXTEND_TRIAL_BY_SUGGESTION_REQUESTED';
export const EXTEND_TRIAL_BY_SUGGESTION_SUCCEEDED =
  'EXTEND_TRIAL_BY_SUGGESTION_SUCCEEDED';
export const EXTEND_TRIAL_BY_SUGGESTION_FAILED =
  'EXTEND_TRIAL_BY_SUGGESTION_FAILED';
export const REMATCH_REQUESTED = 'REMATCH_REQUESTED';
export const REMATCH_SUCCEEDED = 'REMATCH_SUCCEEDED';
export const REMATCH_FAILED = 'REMATCH_FAILED';

export const EVALUATION_INFO_REQUEST = 'EVALUATION_INFO_REQUEST';
export const EVALUATION_INFO_SUCCEEDED = 'EVALUATION_INFO_SUCCEEDED';
export const EVALUATION_INFO_FAILED = 'EVALUATION_INFO_FAILED';

export const EVALUATION_LIST_REQUEST = 'EVALUATION_LIST_REQUEST';
export const EVALUATION_LIST_SUCCEEDED = 'EVALUATION_LIST_SUCCEEDED';
export const EVALUATION_LIST_FAILED = 'EVALUATION_LIST_FAILED';

//Matching
export const RECOMMENDATION_TYPES_REQUEST = 'RECOMMENDATION_TYPES_REQUEST';
export const RECOMMENDATION_TYPES_SUCCEEDED = 'RECOMMENDATION_TYPES_SUCCEEDED';
export const RECOMMENDATION_TYPES_FAILED = 'RECOMMENDATION_TYPES_FAILED';

export const RECOMMENDATION_LIST_REQUEST = 'RECOMMENDATION_LIST_REQUEST';
export const RECOMMENDATION_LIST_SUCCEEDED = 'RECOMMENDATION_LIST_SUCCEEDED';
export const RECOMMENDATION_LIST_FAILED = 'RECOMMENDATION_LIST_FAILED';

export const SET_RECOMMENDATION_REQUEST = 'SET_RECOMMENDATION_REQUEST';
export const SET_RECOMMENDATION_SUCCEEDED = 'SET_RECOMMENDATION_SUCCEEDED';
export const SET_RECOMMENDATION_FAILED = 'SET_RECOMMENDATION_FAILED';

export const UPDATE_MATCHING_DATE_REQUEST = 'UPDATE_MATCHING_DATE_REQUEST';
export const UPDATE_MATCHING_DATE_SUCCEEDED = 'UPDATE_MATCHING_DATE_SUCCEEDED';
export const UPDATE_MATCHING_DATE_FAILED = 'UPDATE_MATCHING_DATE_FAILED';

export const LIST_MATCHING_DATE_REQUEST = 'LIST_MATCHING_DATE_REQUEST';
export const LIST_MATCHING_DATE_SUCCEEDED = 'LIST_MATCHING_DATE_SUCCEEDED';
export const LIST_MATCHING_DATE_FAILED = 'LIST_MATCHING_DATE_FAILED';

export const LIST_USER_STATUS_REQUEST = 'LIST_USER_STATUS_REQUEST';
export const LIST_USER_STATUS_SUCCEEDED = 'LIST_USER_STATUS_SUCCEEDED';
export const LIST_USER_STATUS_FAILED = 'LIST_USER_STATUS_FAILED';

//Users Management
export const LIST_USERS_REQUEST = 'LIST_USERS_REQUEST';
export const LIST_USERS_SUCCEEDED = 'LIST_USERS_SUCCEEDED';
export const LIST_USERS_FAILED = 'LIST_USERS_FAILED';

export const UPDATE_STATUS_USER_REQUEST = 'UPDATE_STATUS_USER_REQUEST';
export const UPDATE_STATUS_USER_SUCCEEDED = 'UPDATE_STATUS_USER_SUCCEEDED';
export const UPDATE_STATUS_USER_FAILED = 'UPDATE_STATUS_USER_FAILED';

export const UPDATE_USER_PROFILE_REQUEST = 'UPDATE_USER_PROFILE_REQUEST';
export const UPDATE_USER_PROFILE_SUCCEEDED = 'UPDATE_USER_PROFILE_SUCCEEDED';
export const UPDATE_USER_PROFILE_FAILED = 'UPDATE_USER_PROFILE_FAILED';

export const GET_UPDATE_USER_PROFILE_DATA_REQUEST = 'GET_UPDATE_USER_PROFILE_DATA_REQUEST';
export const GET_UPDATE_USER_PROFILE_DATA_SUCCEEDED = 'GET_UPDATE_USER_PROFILE_DATA_SUCCEEDED';
export const GET_UPDATE_USER_PROFILE_DATA_FAILED = 'GET_UPDATE_USER_PROFILE_DATA_FAILED';

export const FAKE_USER_REQUESTED = 'FAKE_USER_REQUESTED';
export const FAKE_USER_SUCCEEDED = 'FAKE_USER_SUCCEEDED';
export const FAKE_USER_FAILED = 'FAKE_USER_FAILED';

export const DELETE_USER_REQUESTED = 'DELETE_USER_REQUESTED';
export const DELETE_USER_SUCCEEDED = 'DELETE_USER_SUCCEEDED';
export const DELETE_USER_FAILED = 'DELETE_USER_FAILED';

export const BAN_USER_REQUESTED = 'BAN_USER_REQUESTED';
export const BAN_USER_SUCCEEDED = 'BAN_USER_SUCCEEDED';
export const BAN_USER_FAILED = 'BAN_USER_FAILED';

export const UPLOAD_BULK_CSV_REQUEST = 'UPLOAD_BULK_CSV_REQUEST';
export const UPLOAD_BULK_CSV_SUCCEEDED = 'UPLOAD_BULK_CSV_SUCCEEDED';
export const UPLOAD_BULK_CSV_FAILED = 'UPLOAD_BULK_CSV_FAILED';

//Issue Coupon
export const ISSUE_COUPON_REQUESTED = 'ISSUE_COUPON_REQUESTED';
export const ISSUE_COUPON_SUCCEEDED = 'ISSUE_COUPON_SUCCEEDED';
export const ISSUE_COUPON_FAILED = 'ISSUE_COUPON_FAILED';

export const UPLOAD_CSV_ISSUE_COUPON_REQUESTED =
  'UPLOAD_CSV_ISSUE_COUPON_REQUESTED';
export const UPLOAD_CSV_ISSUE_COUPON_FAILED = 'UPLOAD_CSV_ISSUE_COUPON_FAILED';
export const UPLOAD_CSV_ISSUE_COUPON_SUCCEEDED =
  'UPLOAD_CSV_ISSUE_COUPON_SUCCEEDED';

//Beta Version
export const CHANGE_BETA_VER_REQUESTED = 'CHANGE_BETA_VER_REQUESTED';
export const CHANGE_BETA_VER_SUCCEEDED = 'CHANGE_BETA_VER_SUCCEEDED';
export const CHANGE_BETA_VER_FAILED = 'CHANGE_BETA_VER_FAILED';

export const UPLOAD_BETA_VER_CSV_REQUESTED = 'UPLOAD_BETA_VER_CSV_REQUESTED';
export const UPLOAD_BETA_VER_CSV_FAILED = 'UPLOAD_BETA_VER_CSV_FAILED';
export const UPLOAD_BETA_VER_CSV_SUCCEEDED = 'UPLOAD_BETA_VER_CSV_SUCCEEDED';

//Matching bulk pair csv
export const IMPORT_BULK_PAIR_REQUESTED = 'IMPORT_BULK_PAIR_REQUESTED';
export const IMPORT_BULK_PAIR_SUCCEEDED = 'IMPORT_BULK_PAIR_SUCCEEDED';
export const IMPORT_BULK_PAIR_FAILED = 'IMPORT_BULK_PAIR_FAILED';

//School
export const GET_SCHOOL_REQUESTED = 'GET_SCHOOL_REQUESTED';
export const GET_SCHOOL_SUCCEEDED = 'GET_SCHOOL_SUCCEEDED';
export const GET_SCHOOL_FAILED = 'GET_SCHOOL_FAILED';

export const UPDATE_SCHOOL_REQUESTED = 'UPDATE_SCHOOL_REQUESTED';
export const UPDATE_SCHOOL_SUCCEEDED = 'UPDATE_SCHOOL_SUCCEEDED';
export const UPDATE_SCHOOL_FAILED = 'UPDATE_SCHOOL_FAILED';

export const CREATE_SCHOOL_REQUESTED = 'CREATE_SCHOOL_REQUESTED';
export const CREATE_SCHOOL_SUCCEEDED = 'CREATE_SCHOOL_SUCCEEDED';
export const CREATE_SCHOOL_FAILED = 'CREATE_SCHOOL_FAILED';

//Matching profile settings
export const GET_MATCHING_INFO_REQUESTED = 'GET_MATCHING_INFO_REQUESTED';
export const GET_MATCHING_INFO_SUCCEEDED = 'GET_MATCHING_INFO_SUCCEEDED';
export const GET_MATCHING_INFO_FAILED = 'GET_MATCHING_INFO_FAILED';

export const DELETE_MATCHING_INFO_REQUESTED = 'DELETE_MATCHING_INFO_REQUESTED';
export const DELETE_MATCHING_INFO_SUCCEEDED = 'DELETE_MATCHING_INFO_SUCCEEDED';
export const DELETE_MATCHING_INFO_FAILED = 'DELETE_MATCHING_INFO_FAILED';

export const UPDATE_MATCHING_INFO_REQUESTED = 'UPDATE_MATCHING_INFO_REQUESTED';
export const UPDATE_MATCHING_INFO_SUCCEEDED = 'UPDATE_MATCHING_INFO_SUCCEEDED';
export const UPDATE_MATCHING_INFO_FAILED = 'UPDATE_MATCHING_INFO_FAILED';

export const CREATE_MATCHING_INFO_REQUESTED = 'CREATE_MATCHING_INFO_REQUESTED';
export const CREATE_MATCHING_INFO_SUCCEEDED = 'CREATE_MATCHING_INFO_SUCCEEDED';
export const CREATE_MATCHING_INFO_FAILED = 'CREATE_MATCHING_INFO_FAILED';

//Dating day settings
export const GET_DATING_DAY_OF_WEEK_SETTING_REQUESTED =
  'GET_DATING_DAY_OF_WEEK_SETTING_REQUESTED';
export const GET_DATING_DAY_OF_WEEK_SETTING_SUCCEEDED =
  'GET_DATING_DAY_OF_WEEK_SETTING_SUCCEEDED';
export const GET_DATING_DAY_OF_WEEK_SETTING_FAILED =
  'GET_DATING_DAY_OF_WEEK_SETTING_FAILED';

export const MIGRATE_DATING_DAY_OF_WEEK_SETTING_REQUESTED =
  'MIGRATE_DATING_DAY_OF_WEEK_SETTING_REQUESTED';
export const MIGRATE_DATING_DAY_OF_WEEK_SETTING_SUCCEEDED =
  'MIGRATE_DATING_DAY_OF_WEEK_SETTING_SUCCEEDED';
export const MIGRATE_DATING_DAY_OF_WEEK_SETTING_FAILED =
  'MIGRATE_DATING_DAY_OF_WEEK_SETTING_FAILED';

//Participant Count
export const GET_PARTICIPANT_COUNT_REQUESTED =
  'GET_PARTICIPANT_COUNT_REQUESTED';
export const GET_PARTICIPANT_COUNT_SUCCEEDED =
  'GET_PARTICIPANT_COUNT_SUCCEEDED';
export const GET_PARTICIPANT_COUNT_FAILED = 'GET_PARTICIPANT_COUNT_FAILED';

//ExtendParticipation
export const GET_EXTEND_DATING_DAY_DISPLAY_REQUESTED =
  'GET_EXTEND_DATING_DAY_DISPLAY_REQUESTED';
export const UPDATE_EXTEND_DATING_DAY_DISPLAY_REQUESTED =
  'UPDATE_EXTEND_DATING_DAY_DISPLAY_REQUESTED';
export const GET_EXTEND_DATING_DAY_DISPLAY_REQUESTED_SUCCEEDED =
  'GET_GENDER_RATIO_CONTROL_DATA_SUCCEEDED';
export const GET_EXTEND_DATING_DAY_DISPLAY_REQUESTED_FAILED =
  'GET_GENDER_RATIO_CONTROL_DATA_FAILED';
export const UPDATE_EXTEND_DATING_DAY_DISPLAY_REQUESTED_SUCCEEDED =
  'UPDATE_GENDER_RATIO_CONTROL_DATA_SUCCEEDED';
export const UPDATE_EXTEND_DATING_DAY_DISPLAY_REQUESTED_FAILED =
  'UPDATE_GENDER_RATIO_CONTROL_DATA_FAILED';

export const GET_CLASSIFICATION_PARTICIPANT_REQUESTED =
  'GET_CLASSIFICATION_PARTICIPANT_REQUESTED';
export const GET_CLASSIFICATION_PARTICIPANT_SUCCEEDED =
  'GET_CLASSIFICATION_PARTICIPANT_SUCCEEDED';
export const GET_CLASSIFICATION_PARTICIPANT_FAILED =
  'GET_CLASSIFICATION_PARTICIPANT_FAILED';

export const UPDATE_PARTICIPANT_COUNT_REQUESTED =
  'UPDATE_PARTICIPANT_COUNT_REQUESTED';
export const UPDATE_PARTICIPANT_COUNT_SUCCEEDED =
  'UPDATE_PARTICIPANT_COUNT_SUCCEEDED';
export const UPDATE_PARTICIPANT_COUNT_FAILED =
  'UPDATE_PARTICIPANT_COUNT_FAILED';

export const GET_DATING_DAY_DISPLAY_REQUESTED =
  'GET_DATING_DAY_DISPLAY_REQUESTED';
export const GET_DATING_DAY_DISPLAY_SUCCEEDED =
  'GET_DATING_DAY_DISPLAY_SUCCEEDED';
export const GET_DATING_DAY_DISPLAY_FAILED = 'GET_DATING_DAY_DISPLAY_FAILED';

export const UPDATE_DATING_DAY_DISPLAY_REQUESTED =
  'UPDATE_DATING_DAY_DISPLAY_REQUESTED';
export const UPDATE_DATING_DAY_DISPLAY_SUCCEEDED =
  'UPDATE_DATING_DAY_DISPLAY_SUCCEEDED';
export const UPDATE_DATING_DAY_DISPLAY_FAILED =
  'UPDATE_DATING_DAY_DISPLAY_FAILED';

export const GET_DATING_DAY_DISPLAY_BLACK_REQUESTED =
  'GET_DATING_DAY_DISPLAY_BLACK_REQUESTED';
export const GET_DATING_DAY_DISPLAY_BLACK_SUCCEEDED =
  'GET_DATING_DAY_DISPLAY_BLACK_SUCCEEDED';
export const GET_DATING_DAY_DISPLAY_BLACK_FAILED = 'GET_DATING_DAY_DISPLAY_BLACK_FAILED';

export const UPDATE_DATING_DAY_DISPLAY_BLACK_REQUESTED =
  'UPDATE_DATING_DAY_DISPLAY_BLACK_REQUESTED';
export const UPDATE_DATING_DAY_DISPLAY_BLACK_SUCCEEDED =
  'UPDATE_DATING_DAY_DISPLAY_BLACK_SUCCEEDED';
export const UPDATE_DATING_DAY_DISPLAY_BLACK_FAILED =
  'UPDATE_DATING_DAY_DISPLAY_BLACK_FAILED';

//System Log
export const GET_SYSTEM_LOG_REQUESTED = 'GET_SYSTEM_LOG_REQUESTED';
export const GET_SYSTEM_LOG_SUCCEEDED = 'GET_SYSTEM_LOG_SUCCEEDED';
export const GET_SYSTEM_LOG_FAILED = 'GET_SYSTEM_LOG_FAILED';

export const GET_LOG_DATES_REQUESTED = 'GET_LOG_DATES_REQUESTED';
export const GET_LOG_DATES_SUCCEEDED = 'GET_LOG_DATES_SUCCEEDED';
export const GET_LOG_DATES_FAILED = 'GET_LOG_DATES_FAILED';

//Train station
export const GET_TRAIN_STATION_REQUESTED = 'GET_TRAIN_STATION_REQUESTED';
export const GET_TRAIN_STATION_SUCCEEDED = 'GET_TRAIN_STATION_SUCCEEDED';
export const GET_TRAIN_STATION_FAILED = 'GET_TRAIN_STATION_FAILED';
export const CREATE_TRAIN_STATION_REQUESTED = 'CREATE_TRAIN_STATION_REQUESTED';
export const CREATE_TRAIN_STATION_SUCCEEDED = 'CREATE_TRAIN_STATION_SUCCEEDED';
export const CREATE_TRAIN_STATION_FAILED = 'CREATE_TRAIN_STATION_FAILED';
export const UPDATE_TRAIN_STATION_REQUESTED = 'UPDATE_TRAIN_STATION_REQUESTED';
export const UPDATE_TRAIN_STATION_SUCCEEDED = 'UPDATE_TRAIN_STATION_SUCCEEDED';
export const UPDATE_TRAIN_STATION_FAILED = 'UPDATE_TRAIN_STATION_FAILED';
export const GET_MAP_INFO_REQUESTED = 'GET_MAP_INFO_REQUESTED';
export const GET_MAP_INFO_SUCCEEDED = 'GET_MAP_INFO_SUCCEEDED';
export const GET_MAP_INFO_FAILED = 'GET_MAP_INFO_FAILED';

//Plan
export const GET_PLAN_REQUESTED = 'GET_PLAN_REQUESTED';
export const GET_PLAN_SUCCEEDED = 'GET_PLAN_SUCCEEDED';
export const GET_PLAN_FAILED = 'GET_PLAN_FAILED';

export const GET_COST_PLAN_REQUESTED = 'GET_COST_PLAN_REQUESTED';
export const GET_COST_PLAN_SUCCEEDED = 'GET_COST_PLAN_SUCCEEDED';
export const GET_COST_PLAN_FAILED = 'GET_COST_PLAN_FAILED';

export const GET_USER_PLAN_REQUESTED = 'GET_USER_PLAN_REQUESTED';
export const GET_USER_PLAN_SUCCEEDED = 'GET_USER_PLAN_SUCCEEDED';
export const GET_USER_PLAN_FAILED = 'GET_USER_PLAN_FAILED';

// Admin
export const GET_ADMINS_REQUEST = 'GET_ADMINS_REQUEST';
export const GET_ADMINS_SUCCEEDED = 'GET_ADMINS_SUCCEEDED';
export const GET_ADMINS_FAILED = 'GET_ADMINS_FAILED';

// Current Admin
export const SET_CURRENT_ADMIN_REQUEST = 'SET_CURRENT_ADMIN_REQUEST';
export const SET_CURRENT_ADMIN_SUCCEEDED = 'SET_CURRENT_ADMIN_SUCCEEDED';
export const SET_CURRENT_ADMIN_FAILED = 'SET_CURRENT_ADMIN_FAILED';
export const CLEAR_CURRENT_ADMIN = 'CLEAR_CURRENT_ADMIN';

// Create Admin Account
export const CREATE_ADMIN_ACCOUNT_REQUEST = 'CREATE_ADMIN_ACCOUNT_REQUEST';
export const CREATE_ADMIN_ACCOUNT_SUCCEEDED = 'CREATE_ADMIN_ACCOUNT_SUCCEEDED';
export const CREATE_ADMIN_ACCOUNT_FAILED = 'CREATE_ADMIN_ACCOUNT_FAILED';

// Delete Admin Account
export const DELETE_ADMIN_ACCOUNT_REQUEST = 'DELETE_ADMIN_ACCOUNT_REQUEST';
export const DELETE_ADMIN_ACCOUNT_SUCCEEDED = 'DELETE_ADMIN_ACCOUNT_SUCCEEDED';
export const DELETE_ADMIN_ACCOUNT_FAILED = 'DELETE_ADMIN_ACCOUNT_FAILED';

// Edit Admin Account
export const EDIT_ADMIN_ACCOUNT_REQUEST = 'EDIT_ADMIN_ACCOUNT_REQUEST';
export const EDIT_ADMIN_ACCOUNT_SUCCEEDED = 'EDIT_ADMIN_ACCOUNT_SUCCEEDED';
export const EDIT_ADMIN_ACCOUNT_FAILED = 'EDIT_ADMIN_ACCOUNT_FAILED';

// Delete Admin Account
export const RE_APPROVE_USER_REQUEST = 'RE_APPROVE_USER_REQUEST';
export const RE_APPROVE_USER_SUCCEEDED = 'RE_APPROVE_USER_SUCCEEDED';
export const RE_APPROVE_USER_FAILED = 'RE_APPROVE_USER_FAILED';

export const RE_REGISTER_USER_REQUEST = 'RE_REGISTER_USER_REQUEST';
export const RE_REGISTER_USER_SUCCEEDED = 'RE_REGISTER_USER_SUCCEEDED';
export const RE_REGISTER_USER_FAILED = 'RE_REGISTER_USER_FAILED';

//Copy User
export const COPY_USER_REQUEST = 'COPY_USER_REQUEST';
export const COPY_USER_SUCCEEDED = 'COPY_USER_SUCCEEDED';
export const COPY_USER_FAILED = 'COPY_USER_FAILED';

export const SHOW_IMPORTING = 'SHOW_IMPORTING';
export const HIDE_IMPORTING = 'HIDE_IMPORTING';

export const GET_PROCESS_BATCH_QUEUE_REQUESTED =
  'GET_PROCESS_BATCH_QUEUE_REQUESTED';
export const GET_PROCESS_BATCH_QUEUE_SUCCEEDED =
  'GET_PROCESS_BATCH_QUEUE_SUCCEEDED';
export const GET_PROCESS_BATCH_QUEUE_FAILED = 'GET_PROCESS_BATCH_QUEUE_FAILED';

export const GET_BATCH_QUEUE_SUCCESS_DETAIL_DATA_REQUESTED =
  'GET_BATCH_QUEUE_SUCCESS_DETAIL_DATA_REQUESTED';
export const GET_BATCH_QUEUE_SUCCESS_DETAIL_DATA_SUCCEEDED =
  'GET_BATCH_QUEUE_SUCCESS_DETAIL_DATA_SUCCEEDED';
export const GET_BATCH_QUEUE_SUCCESS_DETAIL_DATA_FAILED =
  'GET_BATCH_QUEUE_SUCCESS_DETAIL_DATA_FAILED';

export const GET_BATCH_QUEUE_FAIL_DETAIL_DATA_REQUESTED =
  'GET_BATCH_QUEUE_FAIL_DETAIL_DATA_REQUESTED';
export const GET_BATCH_QUEUE_FAIL_DETAIL_DATA_SUCCEEDED =
  'GET_BATCH_QUEUE_FAIL_DETAIL_DATA_SUCCEEDED';
export const GET_BATCH_QUEUE_FAIL_DETAIL_DATA_FAILED =
  'GET_BATCH_QUEUE_FAIL_DETAIL_DATA_FAILED';

export const SET_BULK_MESSAGE_IMPORT_SUCCESS_DATA =
  'SET_BULK_MESSAGE_IMPORT_SUCCESS_DATA';
export const SET_BULK_MESSAGE_IMPORT_FAIL_DATA =
  'SET_BULK_MESSAGE_IMPORT_FAIL_DATA';

//Skip sms notifications
export const IMPORT_SKIP_SMS_NOTIFICATIONS_FOR_USERS_REQUESTED =
  'IMPORT_SKIP_SMS_NOTIFICATIONS_FOR_USERS_REQUESTED';
export const IMPORT_SKIP_SMS_NOTIFICATIONS_FOR_USERS_SUCCEEDED =
  'IMPORT_SKIP_SMS_NOTIFICATIONS_FOR_USERS_SUCCEEDED';
export const IMPORT_SKIP_SMS_NOTIFICATIONS_FOR_USERS_FAILED =
  'IMPORT_SKIP_SMS_NOTIFICATIONS_FOR_USERS_FAILED';

//Feedback flex question
export const GET_FEEDBACK_FLEX_QUESTION_CONTROL_DATA_REQUESTED =
  'GET_FEEDBACK_FLEX_QUESTION_CONTROL_DATA_REQUESTED';
export const GET_FEEDBACK_FLEX_QUESTION_CONTROL_DATA_SUCCEEDED =
  'GET_FEEDBACK_FLEX_QUESTION_CONTROL_DATA_SUCCEEDED';
export const GET_FEEDBACK_FLEX_QUESTION_CONTROL_DATA_FAILED =
  'GET_FEEDBACK_FLEX_QUESTION_CONTROL_DATA_FAILED';
export const LIST_FEEDBACK_FLEX_QUESTIONS_REQUESTED =
  'LIST_FEEDBACK_FLEX_QUESTIONS_REQUESTED';
export const LIST_FEEDBACK_FLEX_QUESTIONS_SUCCEEDED =
  'LIST_FEEDBACK_FLEX_QUESTIONS_SUCCEEDED';
export const LIST_FEEDBACK_FLEX_QUESTIONS_FAILED =
  'LIST_FEEDBACK_FLEX_QUESTIONS_FAILED';
export const GET_FEEDBACK_FLEX_QUESTION_REQUESTED =
  'GET_FEEDBACK_FLEX_QUESTION_REQUESTED';
export const GET_FEEDBACK_FLEX_QUESTION_SUCCEEDED =
  'GET_FEEDBACK_FLEX_QUESTION_SUCCEEDED';
export const GET_FEEDBACK_FLEX_QUESTION_FAILED =
  'GET_FEEDBACK_FLEX_QUESTION_FAILED';
export const CREATE_FEEDBACK_FLEX_QUESTION_REQUESTED =
  'CREATE_FEEDBACK_FLEX_QUESTION_REQUESTED';
export const CREATE_FEEDBACK_FLEX_QUESTION_SUCCEEDED =
  'CREATE_FEEDBACK_FLEX_QUESTION_SUCCEEDED';
export const CREATE_FEEDBACK_FLEX_QUESTION_FAILED =
  'CREATE_FEEDBACK_FLEX_QUESTION_FAILED';
export const UPDATE_FEEDBACK_FLEX_QUESTION_REQUESTED =
  'UPDATE_FEEDBACK_FLEX_QUESTION_REQUESTED';
export const UPDATE_FEEDBACK_FLEX_QUESTION_SUCCEEDED =
  'UPDATE_FEEDBACK_FLEX_QUESTION_SUCCEEDED';
export const UPDATE_FEEDBACK_FLEX_QUESTION_FAILED =
  'UPDATE_FEEDBACK_FLEX_QUESTION_FAILED';

//Download CSV management
export const DOWNLOAD_SUBSCRIPTION_CSV_REQUESTED =
  'DOWNLOAD_SUBSCRIPTION_CSV_REQUESTED';
export const DOWNLOAD_SUBSCRIPTION_CSV_SUCCEEDED =
  'DOWNLOAD_SUBSCRIPTION_CSV_SUCCEEDED';
export const DOWNLOAD_SUBSCRIPTION_CSV_FAILED =
  'DOWNLOAD_SUBSCRIPTION_CSV_FAILED';
  export const DOWNLOAD_USER_ID_CSV_REQUESTED = 'DOWNLOAD_USER_ID_CSV_REQUESTED';
  export const DOWNLOAD_USER_ID_CSV_SUCCEEDED = 'DOWNLOAD_USER_ID_CSV_SUCCEEDED';
  export const DOWNLOAD_USER_ID_CSV_FAILED = 'DOWNLOAD_USER_ID_CSV_FAILED';
// subcription
export const GET_USER_SUBSCRIPTION_REQUESTED =
  'GET_USER_SUBSCRIPTION_REQUESTED';
export const GET_USER_SUBSCRIPTION_SUCCEEDED =
  'GET_USER_SUBSCRIPTION_SUCCEEDED';
export const GET_USER_SUBSCRIPTION_FAILED = 'GET_USER_SUBSCRIPTION_FAILED';

// invoice
export const GET_USER_INVOICE_REQUESTED = 'GET_USER_INVOICE_REQUESTED';
export const GET_USER_INVOICE_SUCCEEDED = 'GET_USER_INVOICE_SUCCEEDED';
export const GET_USER_INVOICE_FAILED = 'GET_USER_INVOICE_FAILED';

export const REFUND_INVOICE_REQUESTED = 'REFUND_INVOICE_REQUESTED';
export const REFUND_INVOICE_SUCCEEDED = 'REFUND_INVOICE_SUCCEEDED';
export const REFUND_INVOICE_FAILED = 'REFUND_INVOICE_FAILED';

//Certification management
export const LIST_CERTIFICATIONS_REQUESTED = 'LIST_CERTIFICATIONS_REQUESTED';
export const LIST_CERTIFICATIONS_SUCCEEDED = 'LIST_CERTIFICATIONS_SUCCEEDED';
export const LIST_CERTIFICATIONS_FAILED = 'LIST_CERTIFICATIONS_FAILED';

export const APPROVE_CERTIFICATION_REQUESTED =
  'APPROVE_CERTIFICATION_REQUESTED';
export const APPROVE_CERTIFICATION_SUCCEEDED =
  'APPROVE_CERTIFICATION_SUCCEEDED';
export const APPROVE_CERTIFICATION_FAILED = 'APPROVE_CERTIFICATION_FAILED';

export const REJECT_CERTIFICATION_REQUESTED = 'REJECT_CERTIFICATION_REQUESTED';
export const REJECT_CERTIFICATION_SUCCEEDED = 'REJECT_CERTIFICATION_SUCCEEDED';
export const REJECT_CERTIFICATION_FAILED = 'REJECT_CERTIFICATION_FAILED';

//Auto check notifications
export const GET_AUTO_CHECK_NOTIFICATION_REQUESTED =
  'GET_AUTO_CHECK_NOTIFICATION_REQUESTED';
export const GET_AUTO_CHECK_NOTIFICATION_SUCCEEDED =
  'GET_AUTO_CHECK_NOTIFICATION_SUCCEEDED';
export const GET_AUTO_CHECK_NOTIFICATION_FAILED =
  'GET_AUTO_CHECK_NOTIFICATION_FAILED';
export const CREATE_AUTO_CHECK_NOTIFICATION_REQUESTED =
  'CREATE_AUTO_CHECK_NOTIFICATION_REQUESTED';
export const CREATE_AUTO_CHECK_NOTIFICATION_SUCCEEDED =
  'CREATE_AUTO_CHECK_NOTIFICATION_SUCCEEDED';
export const CREATE_AUTO_CHECK_NOTIFICATION_FAILED =
  'CREATE_AUTO_CHECK_NOTIFICATION_FAILED';
export const DELETE_AUTO_CHECK_NOTIFICATION_REQUESTED =
  'DELETE_AUTO_CHECK_NOTIFICATION_REQUESTED';
export const DELETE_AUTO_CHECK_NOTIFICATION_SUCCEEDED =
  'DELETE_AUTO_CHECK_NOTIFICATION_SUCCEEDED';
export const DELETE_AUTO_CHECK_NOTIFICATION_FAILED =
  'DELETE_AUTO_CHECK_NOTIFICATION_FAILED';

export const GET_DATING_TIMESLOT_PREFECTURE_SETTINGS_REQUESTED =
  'GET_DATING_TIMESLOT_PREFECTURE_SETTINGS_REQUESTED';
export const GET_DATING_TIMESLOT_PREFECTURE_SETTINGS_SUCCEEDED =
  'GET_DATING_TIMESLOT_PREFECTURE_SETTINGS_SUCCEEDED';
export const GET_DATING_TIMESLOT_PREFECTURE_SETTINGS_FAILED =
  'GET_DATING_TIMESLOT_PREFECTURE_SETTINGS_FAILED';
export const UPDATE_DATING_TIMESLOT_PREFECTURE_SETTINGS_REQUESTED =
  'UPDATE_DATING_TIMESLOT_PREFECTURE_SETTINGS_REQUESTED';
export const UPDATE_DATING_TIMESLOT_PREFECTURE_SETTINGS_SUCCEEDED =
  'UPDATE_DATING_TIMESLOT_PREFECTURE_SETTINGS_SUCCEEDED';
export const UPDATE_DATING_TIMESLOT_PREFECTURE_SETTINGS_FAILED =
  'UPDATE_DATING_TIMESLOT_PREFECTURE_SETTINGS_FAILED';

//Common Variables
export const LIST_COMMON_VARIABLES_REQUEST = 'LIST_COMMON_VARIABLES_REQUEST';
export const LIST_COMMON_VARIABLES_SUCCEEDED =
  'LIST_COMMON_VARIABLES_SUCCEEDED';
export const LIST_COMMON_VARIABLES_FAILED = 'LIST_COMMON_VARIABLES_FAILED';

export const CREATE_COMMON_VARIABLE_REQUEST = 'CREATE_COMMON_VARIABLE_REQUEST';
export const CREATE_COMMON_VARIABLE_SUCCEEDED =
  'CREATE_COMMON_VARIABLE_SUCCEEDED';
export const CREATE_COMMON_VARIABLE_FAILED = 'CREATE_COMMON_VARIABLE_FAILED';

export const UPDATE_COMMON_VARIABLE_REQUEST = 'UPDATE_COMMON_VARIABLE_REQUEST';
export const UPDATE_COMMON_VARIABLE_SUCCEEDED =
  'UPDATE_COMMON_VARIABLE_SUCCEEDED';
export const UPDATE_COMMON_VARIABLE_FAILED = 'UPDATE_COMMON_VARIABLE_FAILED';

export const DELETE_COMMON_VARIABLE_REQUEST = 'DELETE_COMMON_VARIABLE_REQUEST';
export const DELETE_COMMON_VARIABLE_SUCCEEDED =
  'DELETE_COMMON_VARIABLE_SUCCEEDED';
export const DELETE_COMMON_VARIABLE_FAILED = 'DELETE_COMMON_VARIABLE_FAILED';

export const UPDATE_STATUS_COMMON_VARIABLE_REQUEST =
  'UPDATE_STATUS_COMMON_VARIABLE_REQUEST';
export const UPDATE_STATUS_COMMON_VARIABLE_SUCCEEDED =
  'UPDATE_STATUS_COMMON_VARIABLE_SUCCEEDED';
export const UPDATE_STATUS_COMMON_VARIABLE_FAILED =
  'UPDATE_STATUS_COMMON_VARIABLE_FAILED';

export const GET_GENDER_RATIO_CONTROL_DATA_REQUESTED =
  'GET_GENDER_RATIO_CONTROL_DATA_REQUESTED';
export const GET_GENDER_RATIO_CONTROL_DATA_SUCCEEDED =
  'GET_GENDER_RATIO_CONTROL_DATA_SUCCEEDED';
export const GET_GENDER_RATIO_CONTROL_DATA_FAILED =
  'GET_GENDER_RATIO_CONTROL_DATA_FAILED';

// User rank
export const UPLOAD_CSV_USER_RANK_REQUESTED = 'UPLOAD_CSV_USER_RANK_REQUESTED';
export const UPLOAD_CSV_USER_RANK_FAILED = 'UPLOAD_CSV_USER_RANK_FAILED';
export const UPLOAD_CSV_USER_RANK_SUCCEEDED = 'UPLOAD_CSV_USER_RANK_SUCCEEDED';

//Manage banner
export const GET_BANNER_TABLE_DATA = 'GET_BANNER_TABLE_DATA';
export const GET_BANNER_TABLE_DATA_SUCCEEDED =
  'GET_BANNER_TABLE_DATA_SUCCEEDED';
export const GET_BANNER_TABLE_DATA_FAILED = 'GET_BANNER_TABLE_DATA_FAILED';
export const UPDATE_BANNER_STATUS = 'UPDATE_BANNER_STATUS';
export const UPDATE_BANNER_STATUS_SUCCEEDED = 'UPDATE_BANNER_STATUS_SUCCEEDED';
export const UPDATE_BANNER_STATUS_FAILED = 'UPDATE_BANNER_STATUS_FAILED';
export const DELETE_BANNER = 'DELETE_BANNER';
export const DELETE_BANNER_SUCCEEDED = 'DELETE_BANNER_SUCCEEDED';
export const DELETE_BANNER_FAILED = 'DELETE_BANNER_FAILED';
export const CREATE_BANNER = 'CREATE_BANNER';
export const CREATE_BANNER_SUCCEEDED = 'CREATE_BANNER_SUCCEEDED';
export const CREATE_BANNER_FAILED = 'CREATE_BANNER_FAILED';
export const UPLOAD_BANNER_IMAGE = 'UPLOAD_BANNER_IMAGE';
export const UPLOAD_BANNER_IMAGE_SUCCEEDED = 'UPLOAD_BANNER_IMAGE_SUCCEEDED';
export const UPLOAD_BANNER_IMAGE_FAILED = 'UPLOAD_BANNER_IMAGE_FAILED';
export const GET_EDIT_BANNER_DATA = 'GET_EDIT_BANNER_DATA';
export const GET_EDIT_BANNER_DATA_SUCCEEDED = 'GET_EDIT_BANNER_DATA_SUCCEEDED';
export const GET_EDIT_BANNER_DATA_FAILED = 'GET_EDIT_BANNER_DATA_FAILED';
export const UPDATE_BANNER = 'UPDATE_BANNER';
export const UPDATE_BANNER_SUCCEEDED = 'UPDATE_BANNER_SUCCEEDED';
export const UPDATE_BANNER_FAILED = 'UPDATE_BANNER_FAILED';
