import * as types from '../actions/types';

const INITIAL = {
  isLoading: false,
  importingPercent: 0,
  successData: [],
  failData: [],
  importingPages: [],
  finishedAt: ''
};

const batchQueueReducer = (state = INITIAL, action) => {
  switch (action.type) {
    case types.GET_PROCESS_BATCH_QUEUE_REQUESTED:
      return {
        ...state,
        importingPages: [...state.importingPages, window.location.pathname]
      };
    case types.GET_PROCESS_BATCH_QUEUE_SUCCEEDED:
      return {
        ...state,
        importingPercent: action.data.data.progress,
        importingPages:
          action.data.data.progress == 100 || action.data.data.finishedAt
            ? state.importingPages.filter(function(el) {
                return el !== window.location.pathname;
              })
            : [...state.importingPages]
      };
    case types.GET_PROCESS_BATCH_QUEUE_FAILED:
      return {
        ...state,
        isError: true,
        importingPercent: 0,
        error: action.error,
        importingPages: state.importingPages.filter(function(el) {
          return el !== window.location.pathname;
        })
      };
    case types.GET_BATCH_QUEUE_SUCCESS_DETAIL_DATA_REQUESTED:
      return {
        ...state,
        isLoading: true
      };
    case types.GET_BATCH_QUEUE_SUCCESS_DETAIL_DATA_SUCCEEDED:
      return {
        ...state,
        successData: action.data,
        isLoading: false
      };
    case types.GET_BATCH_QUEUE_SUCCESS_DETAIL_DATA_FAILED:
      return {
        ...state,
        isError: true,
        isLoading: false,
        error: action.error
      };
    case types.GET_BATCH_QUEUE_FAIL_DETAIL_DATA_REQUESTED:
      return {
        ...state,
        isLoading: true
      };
    case types.GET_BATCH_QUEUE_FAIL_DETAIL_DATA_SUCCEEDED:
      return {
        ...state,
        failData: action.data,
        isLoading: false
      };
    case types.GET_BATCH_QUEUE_FAIL_DETAIL_DATA_FAILED:
      return {
        ...state,
        isError: true,
        isLoading: false,
        error: action.error
      };
    case types.SET_BULK_MESSAGE_IMPORT_SUCCESS_DATA:
      return {
        ...state,
        successData: action.data
      };
    case types.SET_BULK_MESSAGE_IMPORT_FAIL_DATA:
      return {
        ...state,
        failData: action.data
      };
    default:
      return state;
  }
};

export default batchQueueReducer;
