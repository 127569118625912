import * as types from '../actions/types';

const initialState = {
  isLoading: false,
  isError: false,
  chatRoomData: {},
  isRefresh: false
};

const chatRoomReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_TWILIO_TOKEN_REQUESTED:
    case types.CHAT_ROOM_REQUESTED:
      return { ...state, isLoading: true, error: null };
    case types.CHAT_ROOM_REQUEST_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        chatRoomData: action.data || {},
        error: null
      };
    case types.GET_TWILIO_TOKEN_SUCCEEDED: {
      let { token, identity } = action.data;
      return {
        ...state,
        isLoading: false,
        chatRoomData: {
          ...state.chatRoomData,
          token: token,
          user_id: identity
        },
        error: null
      };
    }
    case types.GET_TWILIO_TOKEN_FAILED:
      return {
        ...state,
        isError: true,
        isLoading: false,
        chatRoomData: { ...state.chatRoomData },
        error: action.error
      };
    case types.CHAT_ROOM_REQUEST_FAILED:
      return {
        ...state,
        isError: true,
        isLoading: false,
        chatRoomData: {},
        error: action.error
      };
    case types.HIDE_ERROR:
      return {
        ...state,
        isError: false
      };
    default:
      return state;
  }
};

export default chatRoomReducer;
