import * as types from '../actions/types';
import { enableInitializing } from 'initializable-reducer';

const INITIAL = {
  first: false, // true no sync await
  loggedIn: false,
  userData: {},
  accessToken: '',
  isLoading: false,
  isError: false,
  error: '',
  email: ''
};

const authReducer = enableInitializing((state = INITIAL, action) => {
  switch (action.type) {
    case types.LOGIN_REQUESTED:
      return {
        ...state,
        isLoading: true
      };
    case types.LOGIN_SUCCEEDED:
      return {
        ...state,
        loggedIn: true,
        userData: action.data,
        isLoading: false,
        error: ''
      };
    case types.UPDATE_EMAIL: {
      return {
        ...state,
        email: action.data
      };
    }
    case types.LOGIN_FAILED:
      return {
        ...state,
        isError: true,
        loggedIn: false,
        userData: {},
        error: action.error,
        isLoading: false
      };
    case types.HIDE_ERROR:
      return {
        ...state,
        isError: false
      };
    case types.LOGOUT_REQUESTED:
      return {
        ...state,
        isLoading: true
      };
    case types.LOGOUT_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        userData: {},
        // accessToken: '',
        loggedIn: false,
        error: null,
        isError: false
      };
    case types.LOGOUT_FAILED:
      return {
        ...state,
        isError: true,
        loggedIn: false,
        userData: {},
        // accessToken: '',
        error: action.error,
        isLoading: false
      };
    // case 'persist/REHYDRATE':
    //   return {
    //     ...state,
    //     ...pathOr({}, ['payload', 'auth'], action),
    //     userData: {
    //       ...pathOr({}, ['payload', 'auth', 'userData'], action),
    //       should_render_fb_pixel_first_login_woman_face_height: false,
    //       should_render_fb_pixel_first_login_men_annual_income_gte_10million: false,
    //       should_render_fb_pixel_first_paid_male: false
    //     },
    //     isLoading: false
    //   };
    default:
      return state;
  }
});

export default authReducer;
