import { call, put, takeLatest } from 'redux-saga/effects';
import * as types from '../actions/types';
import { requestAPI } from '../services/request';
import { API } from '../services/api';
import { ResponseStatus } from '../../constants/constant';
import { toast } from 'react-toastify';

function* getJobPreferenceMapping(action) {
  let response = null;
  try {
    response = yield call(
      requestAPI,
      API.API_GET_JOB_PREFERENCE_MAPPING,
      API.GET,
      action.params,
      300000
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.GET_JOB_PREFERENCE_MAPPING_SUCCEEDED,
        data: {
          ...response.data
        }
      });
    } else {
      yield put({
        type: types.GET_JOB_PREFERENCE_MAPPING_FAILED,
        error: response.response.data
      });
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.GET_JOB_PREFERENCE_MAPPING_FAILED, error });
  }
}

function* getJobPreferenceMappingOptions(action) {
  let response = null;
  try {
    response = yield call(
      requestAPI,
      API.API_GET_JOB_PREFERENCE_MAPPING_OPTIONS,
      API.GET,
      action.params,
      300000
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.GET_JOB_PREFERENCE_MAPPING_OPTIONS_SUCCEEDED,
        data: {
          ...response.data
        }
      });
    } else {
      yield put({
        type: types.GET_JOB_PREFERENCE_MAPPING_OPTIONS_FAILED,
        error: response.response.data
      });
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.GET_JOB_PREFERENCE_MAPPING_OPTIONS_FAILED, error });
  }
}

function* createJobPreferenceMapping(action) {
  let response = null;
  try {
    response = yield call(
      requestAPI,
      API.API_CREATE_JOB_PREFERENCE_MAPPING,
      API.POST,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.CREATE_JOB_PREFERENCE_MAPPING_SUCCEEDED,
        data: {
          ...response.data
        }
      });
      toast.success('create job mapping successful');
    } else {
      if (response.response.status === 512) {
        yield put({
          type: types.CREATE_JOB_PREFERENCE_MAPPING_FAILED,
          error: response.response.data
        });
        toast.error(response.response.data.message);
      } else {
        yield put({
          type: types.CREATE_JOB_PREFERENCE_MAPPING_FAILED,
          error: response.response.data
        });
        toast.error('Please try again!');
      }
    }

    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.CREATE_JOB_PREFERENCE_MAPPING_FAILED, error });
    toast.error('Please try again!');
  }
}

function* updateJobPreferenceMapping(action) {
  let response = null;
  try {
    const url = action.params.ids ? `/${action.params.ids}` : false;
    response = yield call(
      requestAPI,
      API.API_UPDATE_JOB_PREFERENCE_MAPPING + url,
      API.POST,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.UPDATE_JOB_PREFERENCE_MAPPING_SUCCEEDED,
        data: {
          ...response.data
        }
      });
      toast.success('update job mapping successful');
    } else {
      if (response.response.status === 512) {
        yield put({
          type: types.UPDATE_JOB_PREFERENCE_MAPPING_FAILED,
          error: response.response.data
        });
        toast.error(response.response.data.message);
      } else {
        yield put({
          type: types.UPDATE_JOB_PREFERENCE_MAPPING_FAILED,
          error: response.response.data
        });
      }
    }

    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.UPDATE_JOB_PREFERENCE_MAPPING_FAILED, error });
  }
}

function* deleteJobPreferenceMapping(action) {
  let response = null;
  try {
    let url = action.params.cancelId ? `/${action.params.cancelId}` : false;
    if (action.params.user_id) {
      url = url + action.params.user_id;
    }
    response = yield call(
      requestAPI,
      API.API_DELETE_JOB_PREFERENCE_MAPPING + url,
      API.POST,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.DELETE_JOB_PREFERENCE_MAPPING_SUCCEEDED,
        data: {
          ...response.data
        }
      });
      toast.success('delete job mapping successful');
    } else {
      yield put({
        type: types.DELETE_JOB_PREFERENCE_MAPPING_FAILED,
        error: response.response.data
      });
    }

    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.DELETE_JOB_PREFERENCE_MAPPING_FAILED, error });
  }
}

export function* watchGetJobPreferenceMapping() {
  yield takeLatest(types.GET_JOB_PREFERENCE_MAPPING_REQUESTED, getJobPreferenceMapping);
}
export function* watchGetJobPreferenceMappingOptions() {
  yield takeLatest(types.GET_JOB_PREFERENCE_MAPPING_OPTIONS_REQUESTED, getJobPreferenceMappingOptions);
}

export function* watchCreateJobPreferenceMapping() {
  yield takeLatest(
    types.CREATE_JOB_PREFERENCE_MAPPING_REQUESTED,
    createJobPreferenceMapping
  );
}

export function* watchUpdateJobPreferenceMapping() {
  yield takeLatest(
    types.UPDATE_JOB_PREFERENCE_MAPPING_REQUESTED,
    updateJobPreferenceMapping
  );
}

export function* watchDeleteJobPreferenceMapping() {
  yield takeLatest(
    types.DELETE_JOB_PREFERENCE_MAPPING_REQUESTED,
    deleteJobPreferenceMapping
  );
}