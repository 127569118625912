import * as types from '../actions/types';
import { pathOr } from 'ramda';

const initialState = {
  isLoading: false,
  isError: false,
  data: {}
};

const evaluationInfoReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.EVALUATION_INFO_REQUEST:
      return { ...state, isLoading: true, error: null };
    case types.EVALUATION_INFO_SUCCEEDED:
      return {
        ...state,
        data: action.data,
        isLoading: false,
        error: null
      };
    case types.EVALUATION_INFO_FAILED:
      return {
        ...state,
        isError: true,
        isLoading: false,
        error: action.error
      };
    case types.HIDE_ERROR:
      return {
        ...state,
        isError: false
      };
    case 'persist/REHYDRATE':
      return {
        ...state,
        data: pathOr({}, ['payload', 'evaluationInfo', 'data'], action),
        isLoading: false,
        isError: false,
        error: null
      };
    default:
      return state;
  }
};

export default evaluationInfoReducer;
