import * as types from '../actions/types';

const initialState = {
  isLoading: false,
  data: [],
  isError: false
};

const timeSettingReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_TIMING_REQUESTED:
      return {
        ...state,
        isLoading:true,
        data: [],
      };
    case types.GET_TIMING_SUCCEEDED:
      return {
        ...state,
        isLoading:false,
        data: action.data,
      };
    case types.GET_TIMING_FAILED:
      return {
        ...state,
        isLoading:false,
        data: [],
      };
    case types.UPDATE_TIMING_REQUESTED:
      return {
        ...state,
        isLoading:true,
        data: [],
      };
    case types.UPDATE_TIMING_SUCCEEDED:
      return {
        ...state,
        isLoading:false,
        data: action.data,
      };
    case types.UPDATE_TIMING_FAILED:
      return {
        ...state,
        isLoading:false,
        data: [],
      };
    default:
      return state;
  }
};

export default timeSettingReducer;
