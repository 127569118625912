import * as types from '../actions/types';
import { pathOr } from 'ramda';

const initialState = {
  isLoading: false,
  dataMatch: {},
  isError: false,
  isRefresh: false,
  expiredAt: null
};

const matchReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CURRENT_USER_RESET_MATCH_DATA:
      return {
        ...state,
        expiredAt: null,
        dataMatch: {}
      };
    case types.ADD_REQUEST_TIME_CHANGE_REQUESTED:
    case types.UPDATE_REQUEST_TIME_CHANGE_REQUESTED:
    case types.CURRENT_USER_GET_MATCH_DATA_REQUESTED:
      return {
        ...state,
        isLoading: true,
        isError: false,
        error: null
      };
    case types.CURRENT_USER_GET_MATCH_DATA_SUCCEEDED: {
      const expiredTime = new Date();
      expiredTime.setMinutes(expiredTime.getMinutes() + 10); // Set Expired time match data in 10 mins

      return {
        ...state,
        isLoading: false,
        isError: false,
        dataMatch: action.data || {},
        error: null,
        expiredAt: expiredTime.toString()
      };
    }
    case types.ADD_REQUEST_TIME_CHANGE_SUCCEEDED:
    case types.UPDATE_REQUEST_TIME_CHANGE_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        isError: false,
        error: null
      };
    case types.ADD_REQUEST_TIME_CHANGE_FAILED:
    case types.UPDATE_REQUEST_TIME_CHANGE_FAILED:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.error
      };
    case types.CURRENT_USER_GET_MATCH_DATA_FAILED:
      return {
        ...state,
        isLoading: false,
        dataMatch: {},
        isError: true,
        error: action.error
      };
    case types.CANCEL_DATE_BY_PARTNER_REQUESTED:
      return { ...state, isLoading: true, error: null };
    case types.CANCEL_DATE_BY_PARTNER_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        error: null
      };
    case types.CANCEL_DATE_BY_PARTNER_FAILED:
      return {
        ...state,
        isError: true,
        isLoading: false,
        error: action.error
      };
    case types.HIDE_ERROR:
      return {
        ...state,
        isError: false
      };
    case 'persist/REHYDRATE':
      return {
        ...state,
        dataMatch: pathOr({}, ['payload', 'match', 'dataMatch'], action),
        isLoading: false,
        isError: false,
        error: null
      };
    default:
      return state;
  }
};

export default matchReducer;
