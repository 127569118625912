import { call, put, takeLatest } from 'redux-saga/effects';
import * as types from '../actions/types';
import { requestAPI } from '../services/request';
import { API } from '../services/api';
import { ResponseStatus } from '../../constants/constant';
import { toast } from 'react-toastify';

function* fetchUserActivityLog(action) {
    let response = null;
    try {
        response = yield call(
            requestAPI,
            `${API.USER_ACTIVITY_LOG}/${action.params.id}`,
            API.GET
        );
        if (response.status === ResponseStatus.SUCCESS) {
            yield put({
                type: types.GET_USER_ACTIVITY_LOG_SUCCEEDED,
                data: {
                ...response.data
                }
            });
        } else {
                yield put({
                type: types.GET_USER_ACTIVITY_LOG_FAILED,
                error: response.response.data
                });
                toast.error(response.response.data.message);
        }
        if (action.onComplete) {
                action.onComplete(response);
        }
    } catch (error) {
        yield put({ type: types.GET_USER_ACTIVITY_LOG_FAILED, error });
    }
}

export function* watchFetchUserActivityLog() {
  yield takeLatest(types.GET_USER_ACTIVITY_LOG_REQUESTED, fetchUserActivityLog);
}
