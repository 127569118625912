import * as types from '../actions/types';

const initialState = {
  isLoading: false,
  data: null,
  isError: false,
  isRefresh: false
};

const usersReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LIST_USERS_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case types.LIST_USERS_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        data: action.data,
        error: null
      };
    case types.LIST_USERS_FAILED:
      return {
        ...state,
        isLoading: false,
        data: null,
        isError: true,
        error: action.error
      };
    case types.UPDATE_STATUS_USER_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case types.UPDATE_STATUS_USER_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        data: action.data,
        error: null
      };
    case types.UPDATE_STATUS_USER_FAILED:
      return {
        ...state,
        isLoading: false,
        data: null,
        isError: true,
        error: action.error
      };
    case types.UPDATE_USER_PROFILE_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case types.UPDATE_USER_PROFILE_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        data: action.data,
        error: null
      };
    case types.UPDATE_USER_PROFILE_FAILED:
      return {
        ...state,
        isLoading: false,
        data: null,
        isError: true,
        error: action.error
      };
    case types.FAKE_USER_REQUESTED:
      return {
        ...state,
        isLoading: true
      };
    case types.FAKE_USER_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        data: action.data,
        error: null
      };
    case types.FAKE_USER_FAILED:
      return {
        ...state,
        isLoading: false,
        data: null,
        isError: true,
        error: action.error
      };
    case types.DELETE_USER_REQUESTED:
      return {
        ...state,
        isLoading: true
      };
    case types.DELETE_USER_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        error: null
      };
    case types.DELETE_USER_FAILED:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.error
      };
    case types.BAN_USER_REQUESTED:
      return {
        ...state,
        isLoading: true
      };
    case types.BAN_USER_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        error: null
      };
    case types.BAN_USER_FAILED:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.error
      };
    case types.ISSUE_COUPON_REQUESTED:
      return {
        ...state,
        isLoading: true
      };
    case types.ISSUE_COUPON_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        data: action.data,
        error: null
      };
    case types.ISSUE_COUPON_FAILED:
      return {
        ...state,
        isLoading: false,
        data: null,
        isError: true,
        error: action.error
      };
    case types.UPLOAD_BULK_CSV_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case types.UPLOAD_BULK_CSV_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        data: action.data,
        error: null
      };
    case types.UPLOAD_BULK_CSV_FAILED:
      return {
        ...state,
        isLoading: false,
        data: null,
        isError: true,
        error: action.error
      };
    case types.RE_APPROVE_USER_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case types.RE_REGISTER_USER_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case types.RE_APPROVE_USER_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        data: action.data,
        error: null
      };
    case types.RE_REGISTER_USER_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        data: action.data,
        error: null
      };
    case types.RE_APPROVE_USER_FAILED:
      return {
        ...state,
        isLoading: false,
        data: null,
        isError: true,
        error: action.error
      };
    case types.RE_REGISTER_USER_FAILED:
      return {
        ...state,
        isLoading: false,
        data: null,
        isError: true,
        error: action.error
      };

    case types.TEMP_CODE_REQUESTED:
      return {
        ...state,
        isLoading: true
      };
    case types.UPLOAD_CSV_ISSUE_COUPON_REQUESTED:
      return {
        ...state,
        isLoading: true
      };
    case types.TEMP_CODE_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        data: action.data,
        error: null
      };
    case types.UPLOAD_CSV_ISSUE_COUPON_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        data: action.data,
        error: null
      };
    case types.TEMP_CODE_FAILED:
      return {
        ...state,
        isLoading: false,
        data: null,
        isError: true,
        error: action.error
      };
    case types.UPLOAD_CSV_ISSUE_COUPON_FAILED:
      return {
        ...state,
        isLoading: false,
        data: null,
        isError: true,
        error: action.error
      };
    case types.UPLOAD_CSV_USER_RANK_REQUESTED:
      return {
        ...state,
        isLoading: true
      };
    case types.UPLOAD_CSV_USER_RANK_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        data: action.data,
        error: null
      };
    case types.UPLOAD_CSV_USER_RANK_FAILED:
      return {
        ...state,
        isLoading: false,
        data: null,
        isError: true,
        error: action.error
      };
    default:
      return state;
  }
};

export default usersReducer;
