import { call, put, takeLatest } from 'redux-saga/effects';
import * as types from '../actions/types';
import { requestAPI } from '../services/request';
import { API } from '../services/api';
import { ResponseStatus } from '../../constants/constant';
import { toast } from 'react-toastify';

function* getBannerTableData(action) {
  let response = null;
  try {
    response = yield call(
      requestAPI,
      API.API_MANAGE_BANNER + '/list',
      API.GET,
      {
        query: action.params
      }
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.GET_BANNER_TABLE_DATA_SUCCEEDED,
        data: {
          ...response.data
        }
      });
    } else {
      yield put({
        type: types.GET_BANNER_TABLE_DATA_FAILED,
        error: response.response.data
      });
      toast.error(response.response.data.message);
    }

    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.GET_BANNER_TABLE_DATA_FAILED, error });
  }
}

function* updateBannerStatus(action) {
  let response = null;
  try {
    response = yield call(
      requestAPI,
      API.API_MANAGE_BANNER + `/update-status/${action.params.id}`,
      API.POST,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.UPDATE_BANNER_STATUS_SUCCEEDED,
        data: {
          ...response.data
        }
      });
      toast.success('Successful');
    } else {
      yield put({
        type: types.UPDATE_BANNER_STATUS_FAILED,
        error: response.response.data
      });
      toast.error(response.response.data.message);
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.UPDATE_BANNER_STATUS_FAILED, error });
  }
}

function* deleteBanner(action) {
  let response = null;
  try {
    response = yield call(
      requestAPI,
      API.API_MANAGE_BANNER + `/delete/${action.params.id}`,
      API.POST,
      action.params
    );
    if (action.onComplete) {
      action.onComplete(response);
    }
    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.DELETE_BANNER_SUCCEEDED,
        data: {
          ...response.data
        }
      });
      toast.success('Successful');
    } else {
      yield put({
        type: types.DELETE_BANNER_FAILED,
        error: response.data.data
      });
      toast.error(response.response.data.message);
    }
  } catch (error) {
    yield put({ type: types.DELETE_BANNER_FAILED, error });
  }
}

function* createBanner(action) {
  let response = null;
  try {
    response = yield call(
      requestAPI,
      API.API_MANAGE_BANNER + `/store`,
      API.POST,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.CREATE_BANNER_SUCCEEDED,
        data: {
          ...response.data
        }
      });
      toast.success('Successful');
    } else {
      yield put({
        type: types.CREATE_BANNER_FAILED,
        error: response.response.data
      });
      toast.error(response.response.data.message);
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.CREATE_BANNER_FAILED, error });
  }
}

function* uploadBannerImage(action) {
  let response = null;
  try {
    response = yield call(
      requestAPI,
      API.API_MANAGE_BANNER + `/store-image`,
      API.POST,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.UPLOAD_BANNER_IMAGE_SUCCEEDED,
        data: {
          ...response.data
        }
      });
    } else {
      yield put({
        type: types.UPLOAD_BANNER_IMAGE_FAILED,
        error: response.response.data
      });
      toast.error(response.response.data.message);
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.UPLOAD_BANNER_IMAGE_FAILED, error });
  }
}

function* getBannerDetail(action) {
  let response = null;
  try {
    response = yield call(
      requestAPI,
      API.API_MANAGE_BANNER + '/edit/' + action.params.id,
      API.GET,
      {}
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.GET_EDIT_BANNER_DATA_SUCCEEDED,
        data: {
          ...response.data
        }
      });
    } else {
      yield put({
        type: types.GET_EDIT_BANNER_DATA_FAILED,
        error: response.response.data
      });
      toast.error(response.response.data.message);
    }

    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.GET_EDIT_BANNER_DATA_FAILED, error });
  }
}

function* updateBanner(action) {
  let response = null;
  try {
    response = yield call(
      requestAPI,
      API.API_MANAGE_BANNER + `/update/${action.params.body.get('id')}`,
      API.POST,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.UPDATE_BANNER_SUCCEEDED,
        data: {
          ...response.data
        }
      });
      toast.success('Successful');
    } else {
      yield put({
        type: types.UPDATE_BANNER_FAILED,
        error: response.response.data
      });
      toast.error(response.response.data.message);
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.UPDATE_BANNER_FAILED, error });
  }
}

export function* watchGetBannerTableData() {
  yield takeLatest(types.GET_BANNER_TABLE_DATA, getBannerTableData);
}

export function* watchUpdateBannerStatus() {
  yield takeLatest(types.UPDATE_BANNER_STATUS, updateBannerStatus);
}

export function* watchDeleteBanner() {
  yield takeLatest(types.DELETE_BANNER, deleteBanner);
}

export function* watchCreateBanner() {
  yield takeLatest(types.CREATE_BANNER, createBanner);
}

export function* watchUploadBannerImage() {
  yield takeLatest(types.UPLOAD_BANNER_IMAGE, uploadBannerImage);
}

export function* watchGetEditBannerData() {
  yield takeLatest(types.GET_EDIT_BANNER_DATA, getBannerDetail);
}

export function* watchUpdateBanner() {
  yield takeLatest(types.UPDATE_BANNER, updateBanner);
}
