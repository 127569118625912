import { call, put, takeLatest } from 'redux-saga/effects';
import * as types from '../actions/types';
import { requestAPI } from '../services/request';
import { API } from '../services/api';
import { ResponseStatus } from '../../constants/constant';
import { toast } from 'react-toastify';

function* createNotification(action) {
  let response = null;
  try {
    response = yield call(
      requestAPI,
      `${API.API_NOTIFICATION}/create`,
      API.POST,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.CREATE_NOTIFICATION_SUCCEEDED,
        data: {
          ...response.data
        }
      });
    } else {
      yield put({
        type: types.CREATE_NOTIFICATION_FAILED,
        error: response
      });
    }

    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.CREATE_NOTIFICATION_FAILED, error });
  }
}

function* updateNotification(action) {
  let response = null;
  try {
    response = yield call(
      requestAPI,
      `${API.API_NOTIFICATION}/update/${action.params.id}`,
      API.POST,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      toast.success('Update Notification successful');
      yield put({
        type: types.UPDATE_NOTIFICATION_SUCCEEDED,
        data: {
          ...response.data
        }
      });
    } else {
      toast.error('Please try again!');
      yield put({
        type: types.UPDATE_NOTIFICATION_FAILED,
        error: response
      });
    }

    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.UPDATE_NOTIFICATION_FAILED, error });
  }
}

function* getUserNotifications(action) { 
  let response = null;
  try {
    response = yield call(
      requestAPI,
      `${API.API_NOTIFICATION}/user/detail/${action.params.id}`,
      API.GET, {
        query: action.params
      });

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.GET_USER_NOTIFICATION_SUCCEEDED,
        data: response.data
      });
    } else {
      yield put({
        type: types.GET_USER_NOTIFICATION_FAILED,
        error: response
      });
    }

    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.GET_USER_NOTIFICATION_FAILED, error });
  }
}

function* getNotifications(action) {
  let response = null;
  try {
    response = yield call(requestAPI, API.API_NOTIFICATION, API.GET, {
      query: action.params
    });

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.GET_NOTIFICATION_SUCCEEDED,
        data: response.data
      });
    } else {
      yield put({
        type: types.GET_NOTIFICATION_FAILED,
        error: response
      });
    }

    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.GET_NOTIFICATION_FAILED, error });
  }
}

function* deleteNotification(action) {
  let response = null;
  try {
    response = yield call(
      requestAPI,
      `${API.API_NOTIFICATION}/delete/${action.id}`,
      API.DELETE,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.DELETE_NOTIFICATION_SUCCEEDED,
        data: response.data
      });
    } else {
      yield put({
        type: types.DELETE_NOTIFICATION_FAILED,
        error: response
      });
    }

    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.DELETE_NOTIFICATION_FAILED, error });
  }
}

function* sendNotification(action) {
  let response = null;
  try {
    response = yield call(
      requestAPI,
      `${API.API_NOTIFICATION}/send/${action.id}`,
      API.POST,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.SEND_NOTIFICATION_SUCCEEDED,
        data: response.data
      });
    } else {
      yield put({
        type: types.SEND_NOTIFICATION_FAILED,
        error: response
      });
    }

    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.SEND_NOTIFICATION_FAILED, error });
  }
}

function* sendBulkMessage(action) {
  let response = null;
  try {
    response = yield call(
      requestAPI,
      `${API.API_NOTIFICATION}/send-bulk-message`,
      API.POST,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.SEND_BULK_MESSAGE_SUCCEEDED,
        data: response.data
      });
    } else if (response.status === 512) {
      yield put({
        type: types.SEND_BULK_MESSAGE_FAILED,
        data: response.data
      });
    } else {
      yield put({
        type: types.SEND_BULK_MESSAGE_FAILED,
        error: response
      });
      toast.error('Send bulk message fail');
    }

    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.SEND_BULK_MESSAGE_FAILED, error });
    toast.error('Send bulk message fail');
  }
}

function* readNotification(action) {
  let response = null;
  try {
    response = yield call(
      requestAPI,
      `${API.API_NOTIFICATION}/read/${action.id}`,
      API.DELETE,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.READ_NOTIFICATION_SUCCEEDED,
        data: response.data
      });
    } else {
      yield put({
        type: types.READ_NOTIFICATION_FAILED,
        error: response
      });
    }

    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.READ_NOTIFICATION_FAILED, error });
  }
}

function* importSkipNotificationsForUsersCsv(action) {
  let response = null;

  try {
    response = yield call(
      requestAPI,
      `${API.API_NOTIFICATION}/user-notification-exclusion-csv`,
      API.POST,
      action.params
    );
    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.IMPORT_SKIP_NOTIFICATIONS_FOR_USERS_SUCCEEDED
      });
    } else {
      yield put({
        type: types.IMPORT_SKIP_NOTIFICATIONS_FOR_USERS_FAILED,
        error: response
      });
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({
      type: types.IMPORT_SKIP_NOTIFICATIONS_FOR_USERS_FAILED,
      error
    });
  }
}

function* getAutoCheckNotification(action) {
  let response = null;

  try {
    response = yield call(
      requestAPI,
      `${API.API_NOTIFICATION}/auto-check`,
      API.GET,
      {
        query: action.params
      }
    );
    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.GET_AUTO_CHECK_NOTIFICATION_SUCCEEDED
      });
    } else {
      yield put({
        type: types.GET_AUTO_CHECK_NOTIFICATION_FAILED,
        error: response
      });
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({
      type: types.GET_AUTO_CHECK_NOTIFICATION_FAILED,
      error
    });
  }
}

function* createAutoCheckNotification(action) {
  let response = null;

  try {
    response = yield call(
      requestAPI,
      `${API.API_NOTIFICATION}/auto-check/create`,
      API.POST,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.CREATE_AUTO_CHECK_NOTIFICATION_SUCCEEDED
      });
      toast.success(response.data.message);
    } else {
      yield put({
        type: types.CREATE_AUTO_CHECK_NOTIFICATION_FAILED,
        error: response
      });
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({
      type: types.CREATE_AUTO_CHECK_NOTIFICATION_FAILED,
      error
    });
  }
}

function* deleteAutoCheckNotification(action) {
  let response = null;

  try {
    response = yield call(
      requestAPI,
      `${API.API_NOTIFICATION}/auto-check/delete/` + action.params.id,
      API.POST,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.DELETE_AUTO_CHECK_NOTIFICATION_SUCCEEDED
      });
      toast.success(response.data.message);
    } else {
      yield put({
        type: types.DELETE_AUTO_CHECK_NOTIFICATION_FAILED,
        error: response
      });
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({
      type: types.DELETE_AUTO_CHECK_NOTIFICATION_FAILED,
      error
    });
  }
}

export function* watchGetNotification() {
  yield takeLatest(types.GET_NOTIFICATION_REQUESTED, getNotifications);
}

export function* watchGetUserNotification() {
  yield takeLatest(types.GET_USER_NOTIFICATION_REQUESTED, getUserNotifications);
}

export function* watchUpdateNotification() {
  yield takeLatest(types.UPDATE_NOTIFICATION_REQUESTED, updateNotification);
}
export function* watchDeleteNotification() {
  yield takeLatest(types.DELETE_NOTIFICATION_REQUESTED, deleteNotification);
}

export function* watchCreateNotification() {
  yield takeLatest(types.CREATE_NOTIFICATION_REQUESTED, createNotification);
}

export function* watchSendNotification() {
  yield takeLatest(types.SEND_NOTIFICATION_REQUESTED, sendNotification);
}

export function* watchSendBulkMessage() {
  yield takeLatest(types.SEND_BULK_MESSAGE_REQUESTED, sendBulkMessage);
}

export function* watchReadNotification() {
  yield takeLatest(types.READ_NOTIFICATION_REQUESTED, readNotification);
}

export function* watchImportSkipNotificationsForUsersCsv() {
  yield takeLatest(
    types.IMPORT_SKIP_NOTIFICATIONS_FOR_USERS_REQUESTED,
    importSkipNotificationsForUsersCsv
  );
}

export function* watchGetAutoCheckNotification() {
  yield takeLatest(
    types.GET_AUTO_CHECK_NOTIFICATION_REQUESTED,
    getAutoCheckNotification
  );
}

export function* watchCreateAutoCheckNotification() {
  yield takeLatest(
    types.CREATE_AUTO_CHECK_NOTIFICATION_REQUESTED,
    createAutoCheckNotification
  );
}

export function* watchDeleteAutoCheckNotification() {
  yield takeLatest(
    types.DELETE_AUTO_CHECK_NOTIFICATION_REQUESTED,
    deleteAutoCheckNotification
  );
}
