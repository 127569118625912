import * as types from '../actions/types';

const initialState = {
  isLoading: false,
  data: null,
  isError: false,
  isRefresh: false
};

const educationGroupReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_SCHOOL_REQUESTED:
      return {
        ...state,
        isLoading: true,
        error: null
      };
    case types.GET_SCHOOL_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        data: action.data,
        error: null
      };
    case types.GET_SCHOOL_FAILED:
      return {
        ...state,
        isLoading: false,
        isError: true,
        data: null,
        error: action.error
      };
    case types.CREATE_SCHOOL_REQUESTED:
      return {
        ...state,
        isLoading: true,
        error: null
      };
    case types.CREATE_SCHOOL_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        data: action.data,
        error: null
      };
    case types.CREATE_SCHOOL_FAILED:
      return {
        ...state,
        isLoading: false,
        isError: true,
        data: null,
        error: action.error
      };
    case types.UPDATE_SCHOOL_REQUESTED:
      return {
        ...state,
        isLoading: true,
        error: null
      };
    case types.UPDATE_SCHOOL_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        data: action.data,
        error: null
      };
    case types.UPDATE_SCHOOL_FAILED:
      return {
        ...state,
        isLoading: false,
        isError: true,
        data: null,
        error: action.error
      };
    default:
      return state;
  }
};

export default educationGroupReducer;
