import { call, put, takeLatest } from 'redux-saga/effects';
import * as types from '../actions/types';
import { requestAPI } from '../services/request';
import { API } from '../services/api';
import { ResponseStatus } from '../../constants/constant';
import { toast } from 'react-toastify';

function* login(action) {
  let response = null;
  try {
    response = yield call(requestAPI, API.API_LOGIN, API.POST, action.params);

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.LOGIN_SUCCEEDED,
        data: {
          ...response.data
        }
      });
    } else {
      yield put({
        type: types.LOGIN_FAILED,
        error: response.response.data
      });
      toast.error(response.response.data.message);
    }

    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.LOGIN_FAILED, error });
  }
}

function* logout() {
  yield put({
    type: types.LOGOUT_SUCCEEDED,
    data: {}
  });
}

export function* watchLogout() {
  yield takeLatest(types.LOGOUT_REQUESTED, logout);
}

export function* watchLogin() {
  yield takeLatest(types.LOGIN_REQUESTED, login);
}
